const useStyles = {
  button: {
    border: "1px solid #002880",
    padding: "12px, 24px, 12px, 24px",
    borderRadius: "8px",
    color: "#0032A0",
    background: "white",
    marginTop: "36px",
    width: { xs: "100%" },
    marginBottom: "64px",
    gap: "4px",
  },
}

export default useStyles
