import React from "react"

export function DocumentIcon({ ...props }) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 0.666504H2.66667C2.26667 0.666504 2 0.933171 2 1.33317V14.6665C2 15.0665 2.26667 15.3332 2.66667 15.3332H13.3333C13.7333 15.3332 14 15.0665 14 14.6665V5.33317L9.33333 0.666504ZM9.33333 2.53304L12.1333 5.33304H9.33333V2.53304ZM3.33333 13.9998H12.6667V6.6665H8V1.99984H3.33333V13.9998ZM11.3333 11.3332V12.6665H4.66667V11.3332H11.3333ZM11.3333 9.99984V8.6665H4.66667V9.99984H11.3333Z"
      />
      <mask
        id="mask0_4550_7256"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="12"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33333 0.666504H2.66667C2.26667 0.666504 2 0.933171 2 1.33317V14.6665C2 15.0665 2.26667 15.3332 2.66667 15.3332H13.3333C13.7333 15.3332 14 15.0665 14 14.6665V5.33317L9.33333 0.666504ZM9.33333 2.53304L12.1333 5.33304H9.33333V2.53304ZM3.33333 13.9998H12.6667V6.6665H8V1.99984H3.33333V13.9998ZM11.3333 11.3332V12.6665H4.66667V11.3332H11.3333ZM11.3333 9.99984V8.6665H4.66667V9.99984H11.3333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4550_7256)" />
    </svg>
  )
}
