/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import PropTypes from "prop-types"
import { SvgIcon, useMediaQuery } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import theme from "@bit/vibenitez.copa-components-library.theme"
import { Hyperlink } from "../Hyperlink/Hyperlink"
import useStyles from "./styles"
import { ReactComponent as CopaLogo } from "../../assets/images/copaairlines-logo.svg"
import { ReactComponent as Logo } from "../../assets/images/logo.svg"

export function HeaderBlue({ title, urlCopa }) {
  const styles = useStyles()
  const isMedium = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <header id="header-container" className={styles.header}>
      {isMedium ? (
        <SvgIcon
          sx={{
            width: "40px",
            height: "32px",
            margin: {
              md: "24px 43px 24px 32px",
              sm: "16px 27px 16px 24px",
              xs: "16px 73px 16px 16px",
            },
          }}
        >
          <Logo />
        </SvgIcon>
      ) : (
        <SvgIcon
          viewBox="0 0 177 30"
          sx={{
            width: "177px",
            height: "30px",
            margin: "24px 43px 24px 32px",
          }}
        >
          <CopaLogo />
        </SvgIcon>
      )}
      <Hyperlink
        linkUrl={urlCopa}
        className={styles.headerBackButton}
        linkText={
          <>
            <ArrowBackIcon id="back-icon" className={styles.arrowBackIcon} />
            {title}
          </>
        }
      />
    </header>
  )
}

HeaderBlue.propTypes = {
  title: PropTypes.string.isRequired,
  urlCopa: PropTypes.string.isRequired,
}
