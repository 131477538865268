import { sendGACustomEvent } from "../../utils/functions"

const NON_ELIGIBLE_REQUEST = "/non-eligible-request"
const DEFAULT_ERROR = "/error"

const redirectErrorPage = (
  errorResponse,
  navigate,
  isAgency = false,
  analyticsParams = {}
) => {
  const RESERVATION_NOT_FOUND = isAgency
    ? "/agency/reservation-not-found"
    : "/reservation-not-found"
  const errorPages = {
    12004: RESERVATION_NOT_FOUND,
    12005: RESERVATION_NOT_FOUND,
    12451: NON_ELIGIBLE_REQUEST,
    12452: NON_ELIGIBLE_REQUEST,
    12453: NON_ELIGIBLE_REQUEST,
    12454: NON_ELIGIBLE_REQUEST,
    12455: NON_ELIGIBLE_REQUEST,
    12456: NON_ELIGIBLE_REQUEST,
    12457: NON_ELIGIBLE_REQUEST,
    12458: NON_ELIGIBLE_REQUEST,
    12459: NON_ELIGIBLE_REQUEST,
    12460: NON_ELIGIBLE_REQUEST,
  }
  const sendAnalytics = (code) => {
    if (errorPages[code] == RESERVATION_NOT_FOUND || code == null) {
      sendGACustomEvent("Request not found", {
        flow: "automatic",
        ...analyticsParams,
      })
    } else if (errorPages[code] == NON_ELIGIBLE_REQUEST) {
      sendGACustomEvent("Non elegible request")
    }
  }

  if (!errorResponse) return

  const errorCode = errorResponse.response?.data?.originalError
  sendAnalytics(errorCode)

  if (errorCode !== null) {
    navigate(errorPages[errorCode] || DEFAULT_ERROR)
  } else {
    navigate(RESERVATION_NOT_FOUND)
  }
}

export default redirectErrorPage
