/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Grid } from "@mui/material"
import { Controller } from "react-hook-form"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import theme from "@bit/vibenitez.copa-components-library.theme"
import REGEX from "../../../../../utils/regex"
import { createErrorsMessages } from "../../../../../utils/errorsMessages"

export function FormOfPaymentCA({ control, formErrors }) {
  const { formatMessage } = useIntl()

  const errorsMessages = createErrorsMessages(formatMessage)

  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginTop: "20px", marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.name"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ALPHANUMERIC_SPACE_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.name && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderBankName",
                })}
                label={formatMessage({
                  id: "formOfPayment.bankName",
                })}
                helperText={
                  formErrors.bank?.name &&
                  errorsMessages[formErrors.bank?.name?.type]
                }
                required
                value={value}
                hasErrors={formErrors.bank?.name}
                id="bankName"
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.owner"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ONLY_LETTERS_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.owner && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountHolder",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountHolder",
                })}
                helperText={
                  formErrors.bank?.owner &&
                  errorsMessages[formErrors.bank?.owner?.type]
                }
                required
                id="bankOwner"
                value={value}
                hasErrors={formErrors.bank?.owner}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.code"
            rules={{
              required: true,
              maxLength: 3,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 3,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.code && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderBankCode",
                })}
                label={formatMessage({
                  id: "formOfPayment.bankCode",
                })}
                helperText={
                  formErrors.bank?.code &&
                  errorsMessages[formErrors.bank?.code?.type]
                }
                required
                id="bankCode"
                value={value}
                hasErrors={formErrors.bank?.code}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.accountNumber"
            rules={{
              required: true,
              maxLength: 20,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 20,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.accountNumber && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountNumber",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountNumber",
                })}
                helperText={
                  formErrors.bank?.accountNumber &&
                  errorsMessages[formErrors.bank?.accountNumber?.type]
                }
                required
                id="bankAccountNumber"
                value={value}
                hasErrors={formErrors.bank?.accountNumber}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.transitNumber"
            rules={{
              required: true,
              maxLength: 5,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 5,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.transitNumber && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderTransit",
                })}
                label={formatMessage({
                  id: "formOfPayment.transit",
                })}
                helperText={
                  formErrors.bank?.transitNumber &&
                  errorsMessages[formErrors.bank?.transitNumber?.type]
                }
                required
                id="BankTransitNumber"
                value={value}
                hasErrors={formErrors.bank?.transitNumber}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}

FormOfPaymentCA.propTypes = {
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
}
