import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
    height: "80px",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
    boxShadow: "0px 1px 2px 0px #00000030",
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("md")]: {
      height: "64px",
    },
  },
  headerBackButton: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    fontFamily: "SuisseIntl",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "none",
    marginRight: "26px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  arrowBackIcon: {
    marginRight: "4.83px",
  },
}))

export default useStyles
