import React from "react"
import { TicketIcon } from "../../assets/Icons/Ticket"
import { BusinessSeatIcon } from "../../assets/Icons/BusinessSeat"
import RefundOption from "./components/RefundOption"

export function getRefundOptions({
  formatMessage,
  ticketRefundCTA,
  paidServiceRefundCTA,
}) {
  const refundOptions = [
    {
      icon: <TicketIcon />,
      title: formatMessage({ id: "createRequestPage.refundTicket.title" }),
      description: formatMessage({
        id: "createRequestPage.refundTicket.description",
      }),
      ctaText: formatMessage({ id: "createRequestPage.refundTicket.cta" }),
      onCtaClick: ticketRefundCTA,
      wcag: formatMessage({
        id: "createRequestPage.wcag.refundRequestBtn",
      }),
    },
    {
      icon: <BusinessSeatIcon />,
      title: formatMessage({
        id: "createRequestPage.refundPaidServices.title",
      }),
      description: formatMessage({
        id: "createRequestPage.refundPaidServices.description",
      }),
      ctaText: formatMessage({
        id: "createRequestPage.refundPaidServices.cta",
      }),
      onCtaClick: paidServiceRefundCTA,
      wcag: formatMessage({
        id: "createRequestPage.wcag.refundPaidServiceBtn",
      }),
    },
  ]

  return refundOptions.map((ro) => (
    <RefundOption
      onCtaClick={ro.onCtaClick}
      ctaText={ro.ctaText}
      icon={ro.icon}
      description={ro.description}
      title={ro.title}
      wcag={ro.wcag}
    />
  ))
}
