import React from "react"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import { Box, Grid } from "@mui/material"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import CheckCircle from "@mui/icons-material/CheckCircle"
import theme from "@bit/vibenitez.copa-components-library.theme"
import Alert from "@bit/vibenitez.copa-components-library.alert"

// eslint-disable-next-line no-restricted-imports
import Pill from "@bit/vibenitez.copa-components-library.pill/dist/pill"
import useStyle from "./style"
import { useAppSelectGeneralInfo } from "../../../../hooks/store-hooks"

export function CaseStatus({ passengers, refundCase, confirmation }) {
  const { formatMessage } = useIntl()
  const { language } = useAppSelectGeneralInfo()
  const formatDate = (date) => {
    if (!date) return ""
    const dateObj = new Date(date)
    const dayAndMonth = dateObj.toLocaleString(String(language).toLowerCase(), {
      day: "2-digit",
      month: "long",
    })

    return `${dayAndMonth}, ${dateObj.getFullYear()}`
  }
  const pillConfig = {
    NEW: {
      color: theme.palette.primary.main,
      text: "refundConfirmation.inProcess",
    },
    EVALUATION: {
      color: theme.palette.alternative.gold,
      text: "refundConfirmation.inReview",
    },
    ESCALATED: {
      color: theme.palette.alternative.gold,
      text: "refundConfirmation.inReview",
    },
    APPROVED: {
      color: theme.palette.success.main,
      text: "refundConfirmation.approved",
    },
    PAYMENT_PROCESSING: {
      color: theme.palette.success.main,
      text: "refundConfirmation.approved",
    },
    CLOSED: {
      color: theme.palette.error.main,
      text: "refundConfirmation.closed",
    },
    DENIED: {
      color: theme.palette.error.main,
      text: "refundConfirmation.closed",
    },
  }

  return (
    <>
      <Alert variant="info" show>
        {formatMessage({
          id: "refundConfirmation.passengerDisclaimer",
        })}
      </Alert>

      <Box sx={useStyle.title}>
        {confirmation ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
            }}
          >
            <CheckCircle
              sx={{
                fontSize: { xs: "20px", md: "24px" },
                marginRight: "8px",
                marginTop: { xs: "0px", sm: "4px", md: "0px" },
                color: theme.palette.success.main,
              }}
            />
            <Typography sx={useStyle.titleFont} variant="h2">
              {formatMessage({
                id: "refundConfirmation.title",
              })}
            </Typography>
          </Box>
        ) : (
          <Typography sx={useStyle.titleFont} variant="h2">
            {formatMessage({
              id: "refundStatus.title",
            })}
          </Typography>
        )}
      </Box>
      {!confirmation && (
        <Box sx={useStyle.description}>
          <Typography>
            {formatMessage({
              id: "refundStatus.description",
            })}
          </Typography>
        </Box>
      )}

      <Box sx={useStyle.caseStatus}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: "18px", lg: "20px" },
                lineHeight: { xs: "24px", lg: "32px" },
                fontFamily: "Gilroy, sans-serif",
              }}
            >
              {formatMessage({
                id: "refundConfirmation.case",
              })}
              {refundCase.caseNumber}
            </Typography>
          </Grid>
          <Grid item alignItems="center" display="flex">
            <Typography
              sx={{
                color: theme.palette.grey["800"],
                display: { xs: "none", md: "inline" },
                paddingRight: "20px",
              }}
            >
              {formatMessage({
                id: "refundConfirmation.caseStatus",
              })}
            </Typography>
            <Pill
              id="some-id"
              customColor={
                pillConfig[refundCase?.status?.name]?.color ||
                theme.palette.primary.main
              }
              label={
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "1.25rem",
                  }}
                >
                  {formatMessage({
                    id:
                      pillConfig[refundCase?.status?.name]?.text ||
                      "refundConfirmation.inProcess",
                  })}
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: theme.palette.grey["800"],
            display: { xs: "none", sm: "block" },
          }}
        >
          {formatMessage({
            id: "refundConfirmation.caseDateHeader",
          })}{" "}
          {formatDate(refundCase?.status?.date)}
        </Typography>

        <Box display={{ sm: "none" }} sx={{ color: theme.palette.grey["800"] }}>
          <Typography>
            {formatMessage({
              id: "refundConfirmation.caseDateHeader",
            })}
          </Typography>
          <Typography>{formatDate(refundCase?.status?.date)}</Typography>
        </Box>

        <Box sx={useStyle.passengersContainer}>
          <Box
            container
            sx={{
              display: "grid",
              gridTemplateColumns: {
                md: "repeat(4, 1fr)",
                sm: "repeat(3, 1fr)",
                xs: "repeat(1, 1fr)",
              },
              columnGap: "16px",
            }}
          >
            {passengers?.map((pass) => (
              <Grid
                key={`Passanger_Box${pass.number}`}
                item
                xs={6}
                sm={4}
                md={3}
                sx={useStyle.passengers}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.primary.main,
                    wordBreak: "break-word",
                  }}
                >
                  {`${pass?.firstName || pass?.givenName} ${pass?.lastName}`}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {pass.number ? `# ${pass.number}` : ""}
                  {pass.eTicket ? `# ${pass.eTicket}` : ""}
                </Typography>
              </Grid>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

CaseStatus.propTypes = {
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      birthday: PropTypes.string.isRequired,
      eTicket: PropTypes.string.isRequired,
      fareInfo: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      ),
    })
  ).isRequired,
  refundCase: PropTypes.shape({
    caseNumber: PropTypes.string.isRequired,
    status: PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  confirmation: PropTypes.bool,
}

CaseStatus.defaultProps = {
  confirmation: false,
}
