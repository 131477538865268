import { SvgIcon } from "@mui/material"
import React from "react"

export function ManageIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 65">
        <circle cx="32" cy="32.002" r="32" fill="#EFF5FF" />
        <path
          fill="#0E68FF"
          d="M37.179 24.6a5.3 5.3 0 0 1-5.3 5.3 5.299 5.299 0 0 1-5.301-5.3c0-2.93 2.372-5.301 5.3-5.301 2.929 0 5.3 2.372 5.3 5.3ZM21.277 37.85c0-3.525 7.063-5.3 10.601-5.3 3.538 0 10.601 1.776 10.601 5.3v1.325c0 .73-.596 1.326-1.325 1.326H22.602a1.329 1.329 0 0 1-1.325-1.326V37.85Z"
        />
        <path
          fill="#EFF5FF"
          d="M40.979 45.159a2.781 2.781 0 0 1-2.072.919H36.88a2.803 2.803 0 0 1-2.807-2.807v-2.026a2.8 2.8 0 0 1 .824-1.99l9.021-9.02.185-.185a3.137 3.137 0 0 1 4.437 0l1.56 1.56a3.137 3.137 0 0 1 0 4.438l-1.933 1.933-7.19 7.178Zm7.373-10.86c.26-.26.26-.68 0-.94l-1.56-1.56a.664.664 0 0 0-.94 0l-.184.184 2.5 2.5.184-.184Zm-11.705 6.706 6.237-6.237 2.499 2.5-6.243 6.236a.313.313 0 0 1-.233.1H36.88a.33.33 0 0 1-.334-.333v-2.026c0-.094.034-.174.1-.24Z"
        />
        <path
          fill="#0E68FF"
          d="M48.352 33.358c.26.26.26.68 0 .94l-1.22 1.22-2.5-2.5 1.22-1.22c.26-.26.68-.26.94 0l1.56 1.56ZM36.547 43.27v-2.026c0-.093.034-.173.1-.24l7.273-7.272 2.5 2.5-7.28 7.272a.312.312 0 0 1-.233.1H36.88a.33.33 0 0 1-.334-.334Z"
        />
      </svg>
    </SvgIcon>
  )
}
