import React from "react"

export function BpCheckedIcon({ ...prop }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...prop}
    >
      <rect width="24" height="24" rx="4" fill="#0032A0" />
      <path
        d="M9 18.0001L4 13.0001L5.4 11.6001L9 15.2001L18.6 5.6001L20 7.0001L9 18.0001Z"
        fill="white"
      />
    </svg>
  )
}
