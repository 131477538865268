import { makeStyles } from "@mui/styles"

function getDashedBorder(color = "") {
  return {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${color.replace(
      "#",
      "%23"
    )}' stroke-width='2' stroke-dasharray='4%2c 7' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e")`,
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    minHeight: "180px",
    ...getDashedBorder(theme.palette.grey["500"]),
    borderRadius: "4px",
    "&:hover": {
      ...getDashedBorder(theme.palette.primary.light),
    },
    display: "grid",
    padding: "32px 0",
    gridTemplateRows: "auto",
    gridTemplateColumns: "1fr",
    textAlign: "center",
    cursor: "pointer",
  },
  dragOver: {
    background: theme.palette.background.lightBlue,
    ...getDashedBorder(theme.palette.primary.light),
    padding: "52px 0",
  },
  hasErrorHelper: {
    padding: "20px 0",
    ...getDashedBorder(theme.palette.error.main),
  },
}))

export default useStyles
