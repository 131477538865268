/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Grid } from "@mui/material"
import { Controller } from "react-hook-form"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import SelectRegular from "@bit/vibenitez.copa-components-library.select-regular"
import OptionCo from "@bit/vibenitez.copa-components-library.option"
import theme from "@bit/vibenitez.copa-components-library.theme"
import REGEX from "../../../../../utils/regex"
import { createErrorsMessages } from "../../../../../utils/errorsMessages"

export function FormOfPaymentMX({ control, formErrors }) {
  const { formatMessage } = useIntl()

  const errorsMessages = createErrorsMessages(formatMessage)

  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginTop: "20px", marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.name"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ALPHANUMERIC_SPACE_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderBankName",
                })}
                label={formatMessage({
                  id: "formOfPayment.bankName",
                })}
                helperText={
                  formErrors.bank?.name &&
                  errorsMessages[formErrors.bank?.name?.type]
                }
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.name && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                required
                id="bankName"
                value={value}
                hasErrors={formErrors.bank?.name}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.owner"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ONLY_LETTERS_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.owner && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountHolder",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountHolder",
                })}
                helperText={
                  formErrors.bank?.owner &&
                  errorsMessages[formErrors.bank?.owner?.type]
                }
                required
                id="bankOwner"
                value={value}
                hasErrors={formErrors.bank?.owner}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.accountType"
            defaultValue=""
            rules={{ required: true, maxLength: 30 }}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectRegular
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountType",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountType",
                })}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                required
                labelClassName={{
                  fontSize: "19px",
                  color: "#333333",
                  fontWeight: 500,
                  lineHeight: "1.6rem",
                  fontFamily: "SuisseIntl,Gilroy,sans-serif",
                }}
                id="bankAccountType"
                hasErrors={!!formErrors.bank?.accountType}
                helperText={
                  formErrors.bank?.accountType
                    ? formatMessage({
                        id: "generalLayoutPage.required",
                      })
                    : ""
                }
                data-private
              >
                <OptionCo value={1} key="1">
                  {formatMessage({
                    id: "formOfPayment.savingsAccount",
                  })}
                </OptionCo>
                <OptionCo value={2} key="2">
                  {formatMessage({
                    id: "formOfPayment.checkingAccount",
                  })}
                </OptionCo>
              </SelectRegular>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.accountNumber"
            rules={{
              required: true,
              maxLength: 20,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 20,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.accountNumber && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountNumber",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountNumber",
                })}
                helperText={
                  formErrors.bank?.accountNumber &&
                  errorsMessages[formErrors.bank?.accountNumber?.type]
                }
                required
                id="bankAccountNumber"
                value={value}
                hasErrors={formErrors.bank?.accountNumber}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.code"
            rules={{
              required: true,
              maxLength: 3,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 3,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.code && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderBankCode",
                })}
                label={formatMessage({
                  id: "formOfPayment.bankCode",
                })}
                helperText={
                  formErrors.bank?.code &&
                  errorsMessages[formErrors.bank?.code?.type]
                }
                required
                id="bankCode"
                value={value}
                hasErrors={formErrors.bank?.code}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.bankKey"
            rules={{
              required: true,
              maxLength: 20,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 20,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.bankKey && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderInterbankKey",
                })}
                label={formatMessage({
                  id: "formOfPayment.interbankKey",
                })}
                helperText={
                  formErrors.bank?.bankKey &&
                  errorsMessages[formErrors.bank?.bankKey?.type]
                }
                required
                id="bankInterbankKey"
                value={value}
                hasErrors={formErrors.bank?.bankKey}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.rfc"
            rules={{
              required: true,
              maxLength: 30,
              pattern: REGEX.ALPHANUMERIC,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 30,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.rfc && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderRfc",
                })}
                label={formatMessage({
                  id: "formOfPayment.rfc",
                })}
                helperText={
                  formErrors.bank?.rfc &&
                  errorsMessages[formErrors.bank?.rfc?.type]
                }
                required
                id="bankRfc"
                value={value}
                hasErrors={formErrors.bank?.rfc}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.swiftcode"
            rules={{
              required: true,
              maxLength: 30,
              pattern: REGEX.ALPHANUMERIC,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 30,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.swiftcode && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderSwiftcode",
                })}
                label={formatMessage({
                  id: "formOfPayment.swiftcode",
                })}
                helperText={
                  formErrors.bank?.swiftcode &&
                  errorsMessages[formErrors.bank?.swiftcode?.type]
                }
                required
                value={value}
                hasErrors={formErrors.bank?.swiftcode}
                id="bankSwiftcode"
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}

FormOfPaymentMX.propTypes = {
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
}
