import React from "react"

export function ArrowRight({ ...props }) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6 1.41562L18.2 9.01562H0V11.0156H18.2L10.6 18.6156L12 20.0156L22 10.0156L12 0.015625L10.6 1.41562Z"
        fill="#121212"
      />
    </svg>
  )
}
