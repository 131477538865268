import theme from "@bit/vibenitez.copa-components-library.theme/index"

const styles = {
  pnrRetrieveContainer: {
    border: `1px solid`,
    borderColor: theme.palette.grey["200"],
    borderRadius: "20px",
    padding: {
      xs: "44px 32px",
      md: "54px 32px 41px 32px",
    },
  },
  header: {
    display: {
      xs: "none",
      sm: "none",
      md: "block",
      lg: "block",
    },
  },
  title: {
    fontFamily: "Gilroy",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  firstInput: {
    maxWidth: { xs: "100%", md: "381px" },
    marginBottom: {
      xs: "52px",
      md: "0px",
    },
  },
  secondInput: {
    maxWidth: { xs: "100%", md: "216px", lg: "253px" },
    marginBottom: {
      xs: "44px",
      md: "0px",
    },
  },
  inputRegularLabelCustomFont: {
    fontFamily: "Suisse Int'l",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  button: {
    height: "40px",
    width: "100%",
    borderRadius: "40px",
    marginBottom: "20px",
    // styleName: Label/ButtonLabel/Small,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "center",
  },
}

export default styles
