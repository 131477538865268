import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  renderCreditCardContainer: {
    marginBottom: "64px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "56px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "72px",
    },
  },
}))

export default useStyles
