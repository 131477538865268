/* eslint-disable react/no-unknown-property */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import Modal from "@bit/vibenitez.copa-components-library.modal"
import { useNavigate } from "react-router-dom"
import { useCreateRefund } from "../../../../hooks/useAPICall"
import modalStyles from "./styles"
import Interstitial from "../../../../components/Interstitial/interstitial"
import {
  useAppDispatch,
  useAppSelectGeneralInfo,
} from "../../../../hooks/store-hooks"
import { formatDate } from "../../../../utils/date"
import constants from "../../../../utils/constants"
import { sendGACustomEvent } from "../../../../utils/functions"
import useIsNRPayedMisc from "../../../../hooks/useIsNRPayedMisc"
import getCreateRefundRequestPassengers from "./getCreateRefundRequestPassengers"

function getFormOfPaymentDetails(flights = []) {
  const allFormOfPaymentDetails = flights
    .flatMap((flight) => flight.passengers || [])
    .flatMap((passenger) => passenger.fareInfo || [])
    .flatMap((item) => item.formOfPayment || [])
    .map((fop) => [
      fop.typeDescription,
      { code: fop.typeDescription, description: fop.description },
    ])
  return [...new Map(allFormOfPaymentDetails).values()]
}

export function RefundRequestModal({
  open,
  handleClose,
  isManualFlow,
  isAFOP,
  passengers,
  getValues,
  triphubData,
  documents,
  isAgency,
}) {
  const { formatMessage } = useIntl()
  const generalContext = useAppSelectGeneralInfo()
  const [isLoading, setIsLoading] = useState(false)
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isNRPWithMiscellaneous = useIsNRPayedMisc()
  const isNonRevenue = triphubData?.trip?.revenue?.isNonRevenue || false

  const {
    responseData: createdRefundRequest,
    error,
    request,
    isLoading: isCreateRefundLoading,
  } = useCreateRefund()

  const createRequest = () => {
    if (!isLoading) {
      setIsLoading(true)
      const flightsFromTriphub = triphubData?.trip?.flights || []

      /** Format bank data */
      let bank = getValues("bank")
      if (bank) {
        bank.fop = "CASH"
        bank.country = {
          code:
            triphubData?.trip?.paymentInformation?.countryWherePaid ||
            getValues("countryPurchase"),
          description: getValues("countryPurchase")
            ? formatMessage({
                id: `countries.${getValues("countryPurchase")}`,
              })
            : "",
        }
      }
      if (bank?.phone) {
        bank.address.phone = `${bank.phone.code}${bank.phone.number}`
        delete bank.phone
      }
      if (bank?.accountType) {
        bank.accountType =
          bank.accountType == 1
            ? formatMessage({
                id: "formOfPayment.savingsAccount",
              })
            : formatMessage({
                id: "formOfPayment.checkingAccount",
              })
      }

      /** Format form of payment data */
      let formOfPayment
      const fareInfoArray =
        triphubData?.trip?.flights?.[0]?.passengers?.[0]?.fareInfo || []
      const formOfPaymentDetails = getFormOfPaymentDetails(
        triphubData?.trip?.flights
      )

      if (triphubData?.trip?.refund?.cardDetails) {
        const cardDetails = triphubData?.trip?.refund?.cardDetails?.split("/")
        formOfPayment = {
          type: "CREDITCARD",
          details: [
            {
              code: "CC",
              description: "CREDITCARD",
              maskedValue: `**** **** **** ${cardDetails[1]}`,
              subType: cardDetails[0],
            },
          ],
          currency: fareInfoArray[0]?.totalFare?.currency || "",
        }
      } else if (triphubData?.trip?.refund?.anyCreditCard || isAFOP) {
        formOfPayment = {
          type: isAFOP ? "OTHERS" : "CREDITCARD",
          details: formOfPaymentDetails,
          currency: fareInfoArray[0]?.totalFare?.currency || "",
        }
      }

      if (isManualFlow) {
        const isCreditCardSelected =
          getValues("paymentMethod") ===
          formatMessage({
            id: "paymentMethod.CreditCard",
          })
        if (
          isCreditCardSelected ||
          getValues("paymentMethod") ===
            formatMessage({
              id: "paymentMethod.Other",
            })
        ) {
          bank = undefined
        }
        formOfPayment = {
          type: isCreditCardSelected ? "CREDITCARD" : "OTHERS",
        }
      }

      /** Format second phone data */
      let phone2
      if (getValues("userSecondPhoneCode") || getValues("userSecondPhone")) {
        phone2 = `${
          String(getValues("userSecondPhoneCode")).startsWith("+") ||
          !getValues("userSecondPhoneCode")
            ? ""
            : "+"
        }${getValues("userSecondPhoneCode") || ""}${
          getValues("userSecondPhone") || ""
        }`
      }

      /** Format flight segments */
      const flightSegmentsRemoved =
        flightsFromTriphub.length == 1 &&
        flightsFromTriphub[0].departureLocation == "-" &&
        flightsFromTriphub[0].arrivalLocation == "-"

      /** Format reservation data */
      let reservation
      if (generalContext.requestCode) {
        reservation = {
          pnr: generalContext.requestCode,
          createDate: triphubData?.trip?.createDateTime,
          issueDate: triphubData?.trip?.issueDateTime,
          ticketingStatus: triphubData?.trip?.ticketingStatus,
          flightSegmentsRemoved,
          flightSegments: !flightSegmentsRemoved
            ? flightsFromTriphub.length
            : undefined,
          interline: !flightSegmentsRemoved
            ? flightsFromTriphub.some(
                (flight) => flight.carrierAgreement == "Interline"
              )
            : undefined,
          scheduledChange: !flightSegmentsRemoved
            ? flightsFromTriphub.some((flight) => flight.statusCode == 51)
            : undefined,
          redemption: triphubData?.trip?.isRedemption || false,
          nonRevenue: isNonRevenue,
        }
      }

      /** Format flights data */
      let flights
      if (!flightSegmentsRemoved) {
        flights = flightsFromTriphub.map((flight) => ({
          operatingAirlineCode: flight.airlineCode || "",
          operatingFlightNumber: flight.flightNumber || "",
          marketingAirlineCode: flight.marketingAirlineCode,
          marketingFlightNumber: flight.marketingFlightNumber,
          status: flight.statusCode,
          departure: {
            date: `${flight.flightDate || ""}${flight.departureGMT || ""}`,
            airportCode: flight.departureCode || "",
            country: flight.departureCountry,
          },
          arrival: {
            date: `${flight.flightArrivalDate || ""}${flight.arrivalGMT || ""}`,
            airportCode: flight.arrivalCode || "",
            country: flight.arrivalCountry,
          },
        }))
      }

      /** Create request object */
      const requestBody = {
        channel: "WEB",
        type: isManualFlow ? "MANUAL" : "AUTOPOPULATED",
        callCenterRequest: isAgency,
        indirectChannel: triphubData?.trip?.channel?.isIndirectChannel,
        language: generalContext.language,
        reason: {
          code: getValues("reasonToRefund") || "",
          description: isNonRevenue
            ? ""
            : formatMessage({
                id: `additionalInfoPage.refundReason${getValues(
                  "reasonToRefund"
                )}`,
              }),
          details: getValues("otherTextarea"),
        },
        reservation,
        requester: {
          firstName: getValues("userName"),
          lastName: getValues("userLastname"),
          email: getValues("userEmail"),
          phone1: `${
            String(getValues("userFirstPhoneCode")).startsWith("+") ? "" : "+"
          }${getValues("userFirstPhoneCode")}${getValues("userFirstPhone")}`,
          phone2,
          relationShipWithPax:
            getValues("requester.relationShipWithPax") ===
            formatMessage({
              id: "additionalInfoPage.relationshipOption3",
            })
              ? getValues("userRelationshipOther")
              : getValues("requester.relationShipWithPax"),
        },
        bank,
        passengers: getCreateRefundRequestPassengers(passengers, triphubData),
        tickets: passengers.map((passenger) => ({
          number: passenger.eTicket || "",
          firstName: passenger.documents?.firstName || passenger.givenName,
          lastName: passenger.lastName,
          coupons: passenger.coupons?.map((coupon) => ({
            couponStatusCode: coupon.couponStatusCode,
            couponStatus: coupon.couponStatus,
            fromCityCode: coupon.fromCityCode,
            toCityCode: coupon.toCityCode,
            flightNumber: coupon.flightNumber
              ? String(coupon.flightNumber)
              : undefined,
            flightDate: coupon.flightDate,
            controllingCarrier: coupon.controllingCarrier,
            carrierCode: coupon.carrierCode,
          })),
        })),
        flights,
        formOfPayment,
        documents,
      }
      request(requestBody)
    }
  }

  useEffect(() => {
    if (createdRefundRequest) {
      dispatch({
        passengers,
        caseNumber: createdRefundRequest.value,
        status: {
          date: new Date(),
          name: "NEW",
        },
      })

      sendGACustomEvent("Create refund", {
        flow: generalContext.flow,
        source: generalContext.source,
      })
      sendGACustomEvent("Refund reason", {
        value: formatMessage({
          id: `additionalInfoPage.refundReason${getValues("reasonToRefund")}`,
        }),
      })

      navigate(
        `/request-confirmation?lastname=${generalContext.userLastName}&case_number=${createdRefundRequest.value}`
      )
    }
  }, [createdRefundRequest])

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      sendGACustomEvent("Error create refund", {
        flow: generalContext.flow,
        source: generalContext.source,
      })
      navigate("/error")
    }
  }, [error])

  const hasBirthdateColumn = passengers.some(
    (pass) => !!pass.documents?.birthday
  )

  if (isCreateRefundLoading) {
    return (
      <Interstitial
        loadingText={formatMessage({ id: "intersitial.loading" })}
      />
    )
  }

  const disclaimer = isNRPWithMiscellaneous
    ? "warningModalLayout.passengerDisclaimerNonRevenue"
    : "warningModalLayout.passengerDisclaimer"

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      aria-label={formatMessage({
        id: "warningModalWCAG.modal",
      })}
      primaryButtonProps={{
        text: formatMessage({
          id: "warningModalLayout.button2",
        }),
        id: "Modal_Button_Submit",
        disable: !isLoading,
        "aria-label": formatMessage({
          id: "warningModalWCAG.ctaConfirm",
        }),
        onClick: createRequest,
      }}
      secondaryButtonProps={{
        text: formatMessage({
          id: "warningModalLayout.button1",
        }),
        id: "Button_Modal_Back",
        "aria-label":
          formatMessage({
            id: "warningModalWCAG.ctaBack",
          }) || "",
      }}
      dismissButtonProps={{
        "aria-label": "Close",
      }}
      height="70vh"
    >
      <Box
        aria-label={formatMessage({
          id: "warningModalWCAG.container",
        })}
        sx={modalStyles.modalWrapper}
      >
        <header>
          <Typography variant="h2" sx={modalStyles.header}>
            {formatMessage({ id: "warningModalLayout.header" })}
          </Typography>
          <Box as="section" sx={modalStyles.headerDisclaimer}>
            <WarningIcon style={{ marginRight: "9px" }} color="warning" />
            <Typography>
              {formatMessage({
                id: disclaimer,
              })}
            </Typography>
          </Box>
        </header>
        <Box as="section" sx={modalStyles.contentWrapper}>
          <Typography variant="h4" style={{ marginBottom: "24px" }}>
            {formatMessage({
              id: "warningModalLayout.title",
            })}
          </Typography>
          <Box
            data-cy="Modal_Label_User_info"
            data-private
            as="section"
            sx={{
              ...modalStyles.requesterInfoWrapper,
              "& div:last-child": !isNonRevenue
                ? {
                    gridColumn: { sm: "1/4", xs: "1/2" },
                  }
                : "",
            }}
          >
            <div sx={modalStyles.requesterField}>
              <Typography variant="body2" sx={modalStyles.requesterFieldTitle}>
                {formatMessage({
                  id: "warningModalLayout.fullName",
                })}
              </Typography>
              <Typography
                id="Modal_User_Name"
                sx={modalStyles.requesterFieldContent}
              >{`${getValues("userName")} ${getValues(
                "userLastname"
              )}`}</Typography>
            </div>
            <div sx={modalStyles.requesterField}>
              <Typography variant="body2" sx={modalStyles.requesterFieldTitle}>
                {formatMessage({
                  id: "warningModalLayout.email",
                })}
              </Typography>
              <Typography
                id="Modal_User_Email"
                sx={modalStyles.requesterFieldContent}
              >
                {getValues("userEmail")}
              </Typography>
            </div>
            <div sx={modalStyles.requesterField}>
              <Typography variant="body2" sx={modalStyles.requesterFieldTitle}>
                {formatMessage({
                  id: "warningModalLayout.phone",
                })}
              </Typography>
              <Typography
                id="Modal_User_Phone"
                sx={modalStyles.requesterFieldContent}
              >{`${
                String(getValues("userFirstPhoneCode")).startsWith("+")
                  ? ""
                  : "+"
              }${getValues("userFirstPhoneCode")}${getValues(
                "userFirstPhone"
              )}`}</Typography>
            </div>
            {!isNonRevenue && (
              <div sx={modalStyles.requesterField}>
                <Typography
                  variant="body2"
                  sx={modalStyles.requesterFieldTitle}
                >
                  {formatMessage({
                    id: "warningModalLayout.reason",
                  })}
                </Typography>
                <Typography
                  id="Modal_Refund_Reason"
                  sx={modalStyles.requesterFieldContent}
                >
                  {getValues("reasonToRefund") == constants.REASON_OPTIONS.OTHER
                    ? getValues("otherTextarea")
                    : formatMessage({
                        id: `additionalInfoPage.refundReason${
                          getValues("reasonToRefund") ||
                          constants.REASON_OPTIONS.VOLUNTARY_CHANGE
                        }`,
                      })}
                </Typography>
              </div>
            )}
          </Box>
          <Box
            data-cy="Modal_Passenger_list"
            as="section"
            sx={modalStyles.passengersWrapper}
          >
            <Typography variant="h4">
              {formatMessage({
                id: "warningModalLayout.passengersTitle",
              })}
            </Typography>
            <Table
              sx={modalStyles.passengersTable}
              aria-label="passengers table"
            >
              <TableHead>
                {!isXSmall && (
                  <TableRow>
                    <TableCell
                      sx={modalStyles.passengersTableHeader}
                      style={{
                        padding: "0px",
                        width: hasBirthdateColumn ? "initial" : "65%",
                      }}
                    >
                      {formatMessage({
                        id: "warningModalLayout.passengeName",
                      })}
                    </TableCell>
                    {hasBirthdateColumn && (
                      <TableCell
                        sx={modalStyles.passengersTableHeader}
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatMessage({
                          id: "warningModalLayout.dateOfBirth",
                        })}
                      </TableCell>
                    )}
                    <TableCell
                      sx={modalStyles.passengersTableHeader}
                      style={{
                        textAlign: hasBirthdateColumn ? "right" : "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {formatMessage({
                        id: "warningModalLayout.eTicket",
                      })}
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {passengers.map((passenger) => (
                  <TableRow
                    key={`Table_Row_${
                      passenger.eTicket || passenger.givenName
                    }`}
                  >
                    {isXSmall ? (
                      <TableCell sx={{ padding: "16px 0" }} scope="row">
                        <Typography variant="h4" sx={{ color: "primary.dark" }}>
                          {`${
                            passenger.documents?.firstName ||
                            passenger.givenName
                          } ${passenger.lastName}`}
                        </Typography>
                        {passenger.documents?.birthday ? (
                          <>
                            {formatDate(passenger.documents?.birthday)}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {passenger.eTicket ? `# ${passenger.eTicket}` : "-"}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell sx={{ padding: "16px 0" }} scope="row">
                          <Typography
                            variant="h4"
                            sx={{ color: "primary.dark" }}
                          >
                            {`${
                              passenger.documents?.firstName ||
                              passenger.givenName
                            } ${passenger.lastName}`}
                          </Typography>
                        </TableCell>
                        {hasBirthdateColumn && (
                          <TableCell sx={{ textAlign: "center" }}>
                            {passenger.documents?.birthday
                              ? formatDate(passenger.documents?.birthday)
                              : "-"}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            textAlign: hasBirthdateColumn ? "right" : "left",
                            wordBreak: "break-all",
                          }}
                        >
                          {passenger.eTicket ? `# ${passenger.eTicket}` : "-"}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

RefundRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      birthday: PropTypes.string,
      eTicket: PropTypes.string,
      fareInfo: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      ),
    })
  ).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({ documentUID: PropTypes.string.isRequired })
  ),
  triphubData: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  isManualFlow: PropTypes.bool,
  isAgency: PropTypes.bool,
  isAFOP: PropTypes.bool,
}

RefundRequestModal.defaultProps = {
  isManualFlow: false,
  documents: undefined,
  isAgency: false,
  isAFOP: false,
  triphubData: {},
}
