import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import InfoIcon from "@mui/icons-material/Info"
import Typography from "@bit/vibenitez.copa-components-library.typography"

import { Box } from "@mui/material"
import useStyles from "./styles"
import { PassengerCard } from "../PassengerCard"
import constants from "../../../../utils/constants"
import { useAppSelectGeneralInfo } from "../../../../hooks/store-hooks"
import { HyperlinkWithGA } from "../../../../components/HyperlinkWithGA"

export function PassengersList({ passengersList }) {
  const { formatMessage } = useIntl()
  const { language } = useAppSelectGeneralInfo()

  const styles = useStyles()

  return (
    <section
      aria-label={formatMessage({ id: "additionalInfoPageWCAG.paxContainer" })}
    >
      <Typography variant="h3" sx={{ marginTop: "30px" }}>
        {formatMessage({ id: "additionalInfoPage.titlePassengerSection" })}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            md: "repeat(4, 1fr)",
          },
          marginBottom: "26px",
        }}
        data-cy="PAssangers_Cards"
      >
        {passengersList?.map((passenger) => (
          <PassengerCard
            key={passenger.eTicket || passenger.givenName}
            {...passenger}
          />
        ))}
      </Box>
      <section className={styles.passengerListDisclaimer}>
        <InfoIcon sx={{ marginRight: "10px" }} color="primary" />
        <Typography variant="h4">
          {formatMessage({ id: "additionalInfoPage.passengerDisclaimer1" })}
          <HyperlinkWithGA
            id="Link_Passenger_Disclaimer"
            linkUrl={constants.copa_url[language]}
            linkText={formatMessage({
              id: "additionalInfoPage.passengerDisclaimerURL",
            })}
            action="Reservation center click"
          />
          {formatMessage({ id: "additionalInfoPage.passengerDisclaimer2" })}
        </Typography>
      </section>
    </section>
  )
}

PassengersList.propTypes = {
  passengersList: PropTypes.arrayOf(PropTypes.shape).isRequired,
}
