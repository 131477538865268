import React from "react"

export function MasterCard() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#a)">
        <circle cx="17" cy="12.5" r="7" fill="#FFB031" />
        <circle cx="7" cy="12.5" r="7" fill="#F33733" />
        <path
          fill="#FB7D34"
          fillRule="evenodd"
          d="M12 17.399a6.977 6.977 0 0 0 2-4.899 6.977 6.977 0 0 0-2-4.899 6.977 6.977 0 0 0-2 4.9c0 1.906.763 3.636 2 4.898Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v14H0z" transform="translate(0 5.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
