import React from "react"

export function Uatp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <mask id="a" width="6" height="7" x="0" y="10" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 10.324h5.159V16.5H0v-6.176Z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#042C18"
          fillRule="evenodd"
          d="M0 10.324c.025.013.053.007.08.007.463 0 .926.001 1.39-.001.059 0 .071.017.071.072a1459.84 1459.84 0 0 0 0 3.47c0 .261.021.52.117.767.146.377.456.58.9.59a5.56 5.56 0 0 0 .998-.07c.054-.008.06-.029.06-.073-.003-.171-.001-.342-.001-.514 0-1.385 0-2.77-.002-4.157 0-.07.018-.086.088-.085.458.003.917.003 1.376 0 .07-.001.081.02.081.084-.001 1.896-.001 3.793 0 5.69.001.065-.018.09-.08.107a9.69 9.69 0 0 1-1.267.216c-.237.027-.475.05-.714.06-.01 0-.018.002-.022.013H2.27c-.007-.02-.023-.014-.037-.015a3.534 3.534 0 0 1-.766-.144c-.766-.244-1.254-.738-1.414-1.53-.02-.092-.017-.189-.053-.278v-4.209Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#042C18"
        fillRule="evenodd"
        d="M20.611 12.472v.788c0 .04 0 .069.054.071.244.01.486.027.73-.012.426-.07.676-.31.724-.728.05-.434-.125-.788-.577-.933-.292-.093-.591-.07-.89-.042-.048.004-.04.038-.04.069v.787Zm3.068.282c-.035.08-.031.167-.05.25-.155.714-.596 1.183-1.278 1.441a3.322 3.322 0 0 1-1.072.197c-.19.007-.38-.006-.57-.01-.098-.003-.098-.005-.098.094 0 .53-.001 1.06.002 1.59 0 .072-.02.085-.088.085a82.202 82.202 0 0 0-1.37 0c-.077 0-.088-.023-.088-.091.002-1.948.002-3.895 0-5.843 0-.074.026-.09.09-.099.554-.074 1.11-.12 1.668-.134.446-.012.887.009 1.317.134.495.144.913.397 1.2.832.188.284.282.6.322.933.002.015-.01.035.015.043v.578Z"
        clipRule="evenodd"
      />
      <mask id="b" width="9" height="2" x="14" y="9" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M14.865 9.5h7.246v.838h-7.246V9.5Z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#41B649"
          fillRule="evenodd"
          d="M14.865 10.33a9.743 9.743 0 0 1 2.093-.641c.49-.094.985-.153 1.485-.176.013 0 .03.003.038-.013h1.128c.081.028.166.019.25.024.63.044 1.253.134 1.868.28.109.026.215.06.325.083.063.014.064.053.055.096-.011.05-.053.022-.079.018a9.33 9.33 0 0 0-1.426-.115c-.771 0-1.53.1-2.277.287-.168.042-.333.091-.5.137-.023.006-.048.009-.065.028l-.103-.003h-2.713c-.026 0-.052-.004-.079-.006"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#042C18"
        fillRule="evenodd"
        d="M14.865 10.33c.027.001.053.005.08.005h2.712c.035 0 .069.002.103.003 0 .427 0 .853.003 1.28 0 .065-.036.054-.073.054-.564 0-1.128.001-1.691-.002-.07 0-.082.02-.082.084l.003 4.568c0 .063-.013.079-.078.078-.459-.003-.917-.003-1.376 0-.065 0-.084-.013-.084-.08.002-1.43.002-2.859.002-4.287 0-.03-.003-.058-.004-.087l.002-.18c0-.095 0-.095-.093-.095l-1.741-.002c0-.421.001-.842-.001-1.263 0-.06.013-.076.075-.076.719.002 1.437.001 2.156.001l.087-.002m-6.768 4.349c-.094.214-.166.435-.248.653a138.6 138.6 0 0 0-.376 1.014c-.013.034-.024.055-.07.055-.513-.002-1.028-.001-1.542-.002l-.035-.003.552-1.403.122-.315.528-1.334c.388-.984.775-1.969 1.161-2.953.018-.046.04-.06.09-.06.573.002 1.146.002 1.72.001.04 0 .07 0 .089.05.297.763.598 1.526.897 2.289-.004.028-.028.037-.049.049-.247.145-.492.293-.741.435-.164.093-.328.189-.501.265l-.56-1.558-.596 1.612c-.07.191-.15.38-.207.576-.033.042-.042.095-.06.143-.058.162-.129.32-.174.486"
        clipRule="evenodd"
      />
      <path
        fill="#41B649"
        fillRule="evenodd"
        d="M9.694 13.419c.174-.076.338-.172.502-.265.249-.142.494-.29.74-.435.021-.012.046-.021.05-.049.033.002.054-.02.078-.036.502-.31.99-.64 1.483-.965l1.742.002c.093 0 .093 0 .093.095l-.003.18c-.394.23-.788.461-1.185.687-.273.155-.552.3-.83.447a19.35 19.35 0 0 1-1.479.703c-.883.375-1.793.672-2.73.888-.018.004-.038.005-.057.007.045-.166.116-.324.174-.486.017-.048.027-.1.06-.143.009-.002.019-.003.027-.007a15.13 15.13 0 0 0 1.335-.623"
        clipRule="evenodd"
      />
      <path
        fill="#042C18"
        fillRule="evenodd"
        d="M12.442 16.399h-.069c-.512 0-1.025-.001-1.537.001-.057 0-.078-.017-.096-.068a573.87 573.87 0 0 0-.661-1.84c-.016-.044-.014-.062.037-.08a16.2 16.2 0 0 0 1.278-.529c.045-.02.064-.019.084.031.317.811.636 1.621.955 2.432.006.015.018.029.01.053"
        clipRule="evenodd"
      />
      <path
        fill="#41B649"
        fillRule="evenodd"
        d="m6.5 14.678-.12.314c-.078-.028-.154.003-.23.01-.249.023-.499.042-.749.06-.028.001-.052 0-.047-.035.005-.032-.025-.079.04-.09a8.33 8.33 0 0 0 .356-.077l.75-.182"
        clipRule="evenodd"
      />
      <path
        fill="#042C18"
        fillRule="evenodd"
        d="M22.813 10.025a.326.326 0 0 0 .328.34.33.33 0 0 0 .333-.338.33.33 0 0 0-.332-.332.323.323 0 0 0-.33.33m-.087.001c0-.226.182-.402.416-.403.235-.001.42.18.418.41a.41.41 0 0 1-.417.404.408.408 0 0 1-.417-.411"
        clipRule="evenodd"
      />
      <path
        fill="#042C18"
        fillRule="evenodd"
        d="M23.135 9.86c-.044-.003-.087-.011-.08.06.004.05.001.09.07.08.054-.008.125.01.127-.07.002-.079-.066-.067-.117-.07m.207.4c-.057.02-.09.002-.114-.047-.018-.036-.046-.069-.063-.105-.019-.04-.05-.053-.088-.042-.038.01-.017.044-.02.067a.681.681 0 0 0 0 .101c0 .025-.01.033-.035.033-.027 0-.04-.006-.04-.035.002-.135.001-.27 0-.405 0-.024.009-.036.035-.035.07.002.141-.006.211.008.06.012.096.043.102.105.006.06-.004.116-.066.138-.055.02-.037.04-.016.07.033.048.063.097.094.146"
        clipRule="evenodd"
      />
    </svg>
  )
}
