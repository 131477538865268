import React, { createContext, useReducer } from "react"
import PropTypes from "prop-types"

export const DispatchGeneralContext = createContext(null)
export const GeneralContext = createContext(null)

// General context is used to share global data across the components tree
/* generalContext = {
  language,
  country,
  request_code,
  last_name,
  passengers,
  status: {date, name},
  flow,
  source
  ....
} */

const generalContextReducer = (state, update = { action: "", payload: {} }) => {
  if (update.action === "set") {
    return { ...state, ...update.payload }
  }
  return state
}

export function GeneralContextProvider({ children }) {
  const [state, dispatch] = useReducer(generalContextReducer, {
    flow: "automatic",
  })

  return (
    <DispatchGeneralContext.Provider value={dispatch}>
      <GeneralContext.Provider value={state}>
        {children}
      </GeneralContext.Provider>
    </DispatchGeneralContext.Provider>
  )
}

GeneralContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
