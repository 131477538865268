import React from "react"
import { useIntl } from "react-intl"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import { Box } from "@mui/material"
import Button from "@bit/vibenitez.copa-components-library.button"
import ResponsiveContainer from "../../components/ResponsiveContainer"
import useStyles from "./style"
import AlertIcon from "../../assets/Icons/Alert"

export function ErrorPage() {
  const { formatMessage } = useIntl()
  const styles = useStyles()

  function handleRetry() {
    window.location.href = formatMessage({
      id: "refundConfirmation.buttonURL",
    })
  }

  return (
    <ResponsiveContainer className={styles.container}>
      <AlertIcon />
      <Typography variant="h2" className={styles.title} tabIndex="0">
        {formatMessage({ id: "errorPage.title" })}
      </Typography>
      <Box className={styles.subtitle} tabIndex="0">
        {formatMessage({ id: "errorPage.subtitle" })}
      </Box>
      <Box className={styles.ctaContainer}>
        <Button
          aria-label={formatMessage({ id: "errorPageWCAG.cta" })}
          onClick={handleRetry}
        >
          {formatMessage({ id: "errorPage.cta" })}
        </Button>
      </Box>
    </ResponsiveContainer>
  )
}
