/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Grid } from "@mui/material"
import { Controller } from "react-hook-form"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import SelectRegular from "@bit/vibenitez.copa-components-library.select-regular"
import OptionCo from "@bit/vibenitez.copa-components-library.option"
import theme from "@bit/vibenitez.copa-components-library.theme"
import REGEX from "../../../../../utils/regex"
import { createErrorsMessages } from "../../../../../utils/errorsMessages"

export function FormOfPaymentGeneric({ control, formErrors, country }) {
  const { formatMessage } = useIntl()

  const errorsMessages = createErrorsMessages(formatMessage)

  const getIdText = () => {
    switch (country) {
      case "EC":
        return "formOfPayment.passport"
      case "CL":
        return "formOfPayment.NIT"
      case "PA":
      case "BB":
      case "TT":
        return "formOfPayment.RUC"
      default:
        return "formOfPayment.IDNumber"
    }
  }

  const getIdPlaceholderText = () => {
    switch (country) {
      case "EC":
        return "formOfPayment.placeholderPassport"
      case "CL":
        return "formOfPayment.placeholderNIT"
      case "PA":
      case "BB":
      case "TT":
        return "formOfPayment.placeholderRUC"
      default:
        return "formOfPayment.placeholderIDNumber"
    }
  }

  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginTop: "20px", marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.name"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ALPHANUMERIC_SPACE_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.name && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderBankName",
                })}
                label={formatMessage({
                  id: "formOfPayment.bankName",
                })}
                helperText={
                  formErrors.bank?.name &&
                  errorsMessages[formErrors.bank?.name?.type]
                }
                required
                value={value}
                hasErrors={formErrors.bank?.name}
                id="bankName"
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.owner"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ONLY_LETTERS_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.owner && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountHolder",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountHolder",
                })}
                helperText={
                  formErrors.bank?.owner &&
                  errorsMessages[formErrors.bank?.owner?.type]
                }
                required
                id="bankOwner"
                value={value}
                hasErrors={formErrors.bank?.owner}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.accountType"
            defaultValue=""
            rules={{ required: true, maxLength: 30 }}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectRegular
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountType",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountType",
                })}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                required
                labelClassName={{
                  fontSize: "19px",
                  color: "#333333",
                  fontWeight: 500,
                  lineHeight: "1.6rem",
                  fontFamily: "SuisseIntl,Gilroy,sans-serif",
                }}
                id="bankAccountType"
                hasErrors={!!formErrors.bank?.accountType}
                helperText={
                  formErrors.bank?.accountType
                    ? formatMessage({
                        id: "generalLayoutPage.required",
                      })
                    : ""
                }
                data-private
              >
                <OptionCo value={1} key="1">
                  {formatMessage({
                    id: "formOfPayment.savingsAccount",
                  })}
                </OptionCo>
                <OptionCo value={2} key="2">
                  {formatMessage({
                    id: "formOfPayment.checkingAccount",
                  })}
                </OptionCo>
              </SelectRegular>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.accountNumber"
            rules={{
              required: true,
              maxLength: 20,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 20,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.accountNumber && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderAccountNumber",
                })}
                label={formatMessage({
                  id: "formOfPayment.accountNumber",
                })}
                helperText={
                  formErrors.bank?.accountNumber &&
                  errorsMessages[formErrors.bank?.accountNumber?.type]
                }
                required
                id="bankAccountNumber"
                value={value}
                hasErrors={formErrors.bank?.accountNumber}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: "28px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.swiftcode"
            rules={{
              required: true,
              maxLength: 30,
              pattern: REGEX.ALPHANUMERIC,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 30,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.swiftcode && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: "formOfPayment.placeholderSwiftcode",
                })}
                label={formatMessage({
                  id: "formOfPayment.swiftcode",
                })}
                helperText={
                  formErrors.bank?.swiftcode &&
                  errorsMessages[formErrors.bank?.swiftcode?.type]
                }
                required
                id="bankSwiftcode"
                value={value}
                hasErrors={formErrors.bank?.swiftcode}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="bank.idNumber"
            rules={{
              required: true,
              maxLength: 30,
              pattern: REGEX.ID_NUMBER,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 30,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.idNumber && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey["400"],
                            fontSize: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: getIdPlaceholderText(),
                })}
                label={formatMessage({
                  id: getIdText(),
                })}
                helperText={
                  formErrors.bank?.idNumber &&
                  errorsMessages[formErrors.bank?.idNumber?.type]
                }
                required
                id="bankIdNumber"
                value={value}
                hasErrors={formErrors.bank?.idNumber}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      {(country === "PE" || country === "NI") && (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          style={{ marginBottom: "28px" }}
        >
          {country === "PE" && (
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Controller
                control={control}
                name="bank.cci"
                rules={{
                  required: true,
                  maxLength: 30,
                  pattern: REGEX.ALPHANUMERIC,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    inputProps={{
                      maxLength: 30,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value && !formErrors.bank?.cci && (
                            <CheckIcon
                              style={{
                                color: theme.palette.grey["400"],
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={formatMessage({
                      id: "formOfPayment.placeholderCCI",
                    })}
                    label={formatMessage({
                      id: "formOfPayment.CCI",
                    })}
                    helperText={
                      formErrors.bank?.cci &&
                      errorsMessages[formErrors.bank?.cci?.type]
                    }
                    required
                    id="bankCci"
                    value={value}
                    hasErrors={formErrors.bank?.cci}
                    handleChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  )
}

FormOfPaymentGeneric.propTypes = {
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  country: PropTypes.string.isRequired,
}
