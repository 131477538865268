import React from "react"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import { useIntl } from "react-intl"
import styles from "./styles"

export function ExceptionMessage() {
  const { formatMessage } = useIntl()
  const bulletText = [
    formatMessage({ id: "exceptionsPage.bullet1" }),
    formatMessage({ id: "exceptionsPage.bullet2" }),
    formatMessage({ id: "exceptionsPage.bullet3" }),
    formatMessage({ id: "exceptionsPage.bullet4" }),
    formatMessage({ id: "exceptionsPage.bullet5" }),
    formatMessage({ id: "exceptionsPage.bullet6" }),
    formatMessage({ id: "exceptionsPage.bullet7" }),
    formatMessage({ id: "exceptionsPage.bullet8" }),
  ]
  return (
    <>
      <Typography id="exceptions-title" sx={styles.title}>
        {formatMessage({ id: "exceptionsPage.title" })}
      </Typography>

      <Typography id="exceptions-subtitle" sx={styles.subtitle}>
        {formatMessage({ id: "exceptionsPage.subtitle" })}
      </Typography>

      <ul
        id="exceptions-situations"
        style={{ marginTop: "32px", paddingLeft: "16px" }}
      >
        {bulletText.map((bt) => (
          <li>
            <Typography variant="body1" sx={styles.bulletText}>
              {bt}
            </Typography>
          </li>
        ))}
      </ul>
    </>
  )
}
