import React from "react"
import Option from "@bit/vibenitez.copa-components-library.option"
import constants from "../../../../utils/constants"

const {
  VOLUNTARY_CHANGE,
  INVOLUNTARY_CHANGE,
  DEATH,
  IMMIGRATIONISSUES,
  TERMINAL_ILLNESS,
} = constants.REASON_OPTIONS

const refundReasonTextById = {
  [VOLUNTARY_CHANGE]: "additionalInfoPage.refundReasonVoluntaryChange",
  [INVOLUNTARY_CHANGE]: "additionalInfoPage.refundReasonInvoluntaryChange",
  [DEATH]: "additionalInfoPage.refundReasonDeath",
  [TERMINAL_ILLNESS]: "additionalInfoPage.refundReasonTerminalillness",
  [IMMIGRATIONISSUES]: "additionalInfoPage.refundReasonImmigrationIssues",
}

const getRefundReasonOptions = (options, formatMessage) =>
  options.map((option) => (
    <Option data-testid={`${option}-test-id`} value={option}>
      {formatMessage({
        id: refundReasonTextById[option],
      })}
    </Option>
  ))

export default getRefundReasonOptions
