import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Controller, useForm, useFormState } from "react-hook-form"
import { Box, Grid } from "@mui/material"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import Button from "@bit/vibenitez.copa-components-library.button"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import theme from "@bit/vibenitez.copa-components-library.theme/index"
import AlertToast from "@bit/vibenitez.copa-components-library.alert-toast"
import { useSearchParams } from "react-router-dom"
import Regex from "../../../utils/regex"
import styles from "./styles"
import VALIDATIONS from "../../../utils/validations"
import { useStoreRefund } from "../../../hooks/usePersistData"
import { cleanUpSpecialChars, getCodeType } from "../../../utils/functions"
import Interstitial from "../../../components/Interstitial/interstitial"

export function CheckStatusForm() {
  const { formatMessage } = useIntl()
  const [showRefundNotFoundError, setShowRefundNotFoundError] = useState(false)
  const [searchParams] = useSearchParams()

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    setError,
  } = useForm({
    mode: "all",
    defaultValues: {
      pnrCode: searchParams.get("case_number"),
    },
  })

  const { isValid } = useFormState({
    control,
  })

  const {
    request,
    isLoading: isRefundLoading,
    refundError,
    refundNotFound,
  } = useStoreRefund({ skip: true })

  const setInputsErrors = () => {
    setError("pnrCode", {
      type: "case-not-found",
    })

    setError("lastname", {
      type: "case-not-found",
    })
  }

  useEffect(() => {
    if (refundError) {
      setShowRefundNotFoundError(refundNotFound)
      setInputsErrors()
    }
  }, [refundError])

  const closeRefundNotFoundError = () => {
    setShowRefundNotFoundError(false)
  }

  const errorsMessages = {
    required: formatMessage({ id: "callCenterSpecialLink.requiredMessage" }),
    minLength: formatMessage({
      id: "callCenterSpecialLink.invalidFormatMessage",
    }),
    maxLength: formatMessage({
      id: "callCenterSpecialLink.invalidFormatMessage",
    }),
    pattern: formatMessage({
      id: "callCenterSpecialLink.invalidFormatMessage",
    }),
    validate: formatMessage({
      id: "callCenterSpecialLink.invalidFormatMessage",
    }),
  }

  const handleClick = (data) => {
    const codeType = getCodeType(data.pnrCode)

    const requestBody = {
      [codeType]: data.pnrCode,
      lastName:
        codeType == "eTicket" ? undefined : cleanUpSpecialChars(data.lastname),
    }
    request(requestBody)
  }

  if (isRefundLoading) {
    return (
      <Interstitial
        loadingText={formatMessage({ id: "intersitial.loading" })}
      />
    )
  }

  return (
    <>
      <AlertToast
        variant="error"
        open={showRefundNotFoundError}
        close={closeRefundNotFoundError}
        message={formatMessage({ id: "intersitial.alertError" })}
        WCAG={formatMessage({ id: "intersitialWCAG.alertError" })}
      />
      <Box
        id="pnr-retrieve-form-container"
        sx={styles.pnrRetrieveContainer}
        aria-label={formatMessage({ id: "checkStatusPageWCAG.container" })}
      >
        <form>
          <Grid
            container
            alignItems="flex-end"
            sx={{ columnGap: { md: "32px" } }}
          >
            <Grid item xs={12} md={5} sx={styles.firstInput}>
              <Controller
                control={control}
                id="pnrCode"
                name="pnrCode"
                rules={VALIDATIONS.PNR}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    regx={Regex.ALPHANUMERIC}
                    inputProps={{ maxLength: 14 }}
                    /* eslint-disable-next-line react/jsx-no-duplicate-props */
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value && !formErrors.pnrCode?.type && (
                            <CheckIcon
                              style={{
                                color: theme.palette.grey["400"],
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    hasErrors={formErrors.pnrCode}
                    required
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                    label={formatMessage({
                      id: "checkStatusPage.ticketLabel",
                    })}
                    labelClassName={{
                      whiteSpace: "unset",
                    }}
                    helperText={
                      formErrors.pnrCode
                        ? errorsMessages[formErrors.pnrCode?.type]
                        : formatMessage({
                            id: "checkStatusPage.ticketHelperText",
                          })
                    }
                    helperTextError={formatMessage({
                      id: "callCenterSpecialLink.invalidFormatMessage",
                    })}
                    customFontFamily={styles.inputRegularLabelCustomFont}
                    id="pnrCode"
                    placeholder={formatMessage({
                      id: "checkStatusPage.ticketPlaceholder",
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={styles.secondInput}>
              <Controller
                control={control}
                id="lastname"
                name="lastname"
                rules={VALIDATIONS.NAME_LASTNAME}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    required
                    hasErrors={formErrors.lastname}
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                    label={formatMessage({
                      id: "checkStatusPage.lastnameLabel",
                    })}
                    helperText={
                      formErrors.lastname &&
                      errorsMessages[formErrors.lastname?.type]
                    }
                    customFontFamily={styles.inputRegularLabelCustomFont}
                    id="lastname"
                    placeholder={formatMessage({
                      id: "checkStatusPage.lastnamePlaceholder",
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value && !formErrors.lastname?.type && (
                            <CheckIcon
                              style={{
                                color: theme.palette.grey["400"],
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.7} lg={3}>
              <Button
                id="pnr-retrieve-submit"
                disabled={!isValid}
                sx={styles.button}
                onClick={handleSubmit(handleClick)}
              >
                {formatMessage({
                  id: "checkStatusPage.cta",
                })}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  )
}
