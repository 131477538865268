import React from "react"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import { useIntl } from "react-intl"

export function ErrorMessage() {
  const { formatMessage } = useIntl()
  return (
    <section>
      <Typography
        variant="h2"
        sx={{ color: "primary.dark", marginBottom: "24px" }}
      >
        {formatMessage({ id: "generalLayoutPage.title1" })}
      </Typography>
      <ul style={{ paddingLeft: "1.5em" }}>
        <li style={{ marginBottom: "1em" }}>
          <Typography>
            {formatMessage({ id: "generalLayoutPage.bullet1" })}
          </Typography>
        </li>
        <li style={{ marginBottom: "1em" }}>
          <Typography>
            {formatMessage({ id: "generalLayoutPage.bullet2" })}
          </Typography>
        </li>
        <li style={{ marginBottom: "1em" }}>
          <Typography>
            {formatMessage({ id: "generalLayoutPage.bullet3" })}
          </Typography>
        </li>
        <li>
          <Typography>
            {formatMessage({ id: "generalLayoutPage.bullet4" })}
          </Typography>
        </li>
      </ul>
    </section>
  )
}
