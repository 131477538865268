import React from "react"
import { useIntl } from "react-intl"
import Button from "@bit/vibenitez.copa-components-library.button"
import { Header } from "../../components/Header"
import ResponsiveContainer from "../../components/ResponsiveContainer"
import styles from "./styles"
import { ExceptionMessage } from "./ExceptionMessage/ExceptionMessage"
import { goToCOPA } from "../../utils/functions"
import { useAppSelectGeneralInfo } from "../../hooks/store-hooks"
import PNRHeading from "../../components/PNRHeading"

export function ExceptionPage() {
  const generalContext = useAppSelectGeneralInfo()
  const { formatMessage } = useIntl()

  return (
    <>
      <Header
        title={formatMessage({ id: "additionalInfoPage.header" })}
        goBackSteps={2}
      />
      <PNRHeading />
      <ResponsiveContainer id="exceptions-message-container" marginTop="55px">
        <ExceptionMessage />
        <Button
          data-cy="button-exception-page"
          variant="outlinePrimaryMain"
          sx={styles.button}
          onClick={() => {
            goToCOPA(generalContext.language)
          }}
        >
          {formatMessage({ id: "exceptionsPage.cta" })}
        </Button>
      </ResponsiveContainer>
    </>
  )
}
