import React from "react"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import useStyles from "./styles"

export function Heading({ titleComponent, textComponent, ...props }) {
  return (
    <Box as="section" id="heading-container" sx={useStyles.heading} {...props}>
      {titleComponent}
      {textComponent}
    </Box>
  )
}

Heading.propTypes = {
  titleComponent: PropTypes.node.isRequired,
  textComponent: PropTypes.node.isRequired,
}
