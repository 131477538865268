import Typography from "@bit/vibenitez.copa-components-library.typography"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { Header } from "../../components/Header"
import PNRHeading from "../../components/PNRHeading"
import ResponsiveContainer from "../../components/ResponsiveContainer"
import { PassengersList } from "./components/PassengersList"
import { RefundRequestForm } from "./components/RefundRequestForm"
import { useAppSelectGeneralInfo } from "../../hooks/store-hooks"
import { NonRefundableFaresModal } from "./components/Modals/NonRefundableFaresModal/NonRefundableFaresModal"

export function CreateRequestFormPage() {
  const { formatMessage } = useIntl()
  const generalContext = useAppSelectGeneralInfo()
  const { isAgency, triphubData, isManualFlow } = generalContext

  const [showModal, setShowModal] = useState(
    triphubData?.trip?.fareFamily?.isNonRefundable
  )
  const [isNonRefundableFare, setIsNonRefundableFare] = useState(false)

  const passengers =
    triphubData?.trip?.flights?.[0]?.passengers ||
    generalContext.passengers ||
    []

  return (
    <>
      <Header title={formatMessage({ id: "additionalInfoPage.header" })} />
      <PNRHeading />
      <NonRefundableFaresModal
        showModal={showModal}
        setShowModal={setShowModal}
        setIsNonRefundableFare={setIsNonRefundableFare}
        isAgency={isAgency}
      />
      <ResponsiveContainer marginTop="55px" marginBottom="108px">
        <Typography variant="h2" sx={{ color: "primary.main" }}>
          {formatMessage({ id: "additionalInfoPage.pageSection" })}
        </Typography>
        <PassengersList passengersList={passengers} />
        <RefundRequestForm
          isAgency={isAgency}
          passengersList={passengers}
          triphubData={triphubData}
          isManualFlow={!!isManualFlow}
          isNonRefundableFare={isNonRefundableFare}
        />
      </ResponsiveContainer>
    </>
  )
}
