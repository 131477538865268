export default {
  "inputErrorMessage.pnrNotFound": "Não encontramos sua reserva",
  "createRequestPage.fateInfo":
    "https://www.copaair.com/pt-gs/informacoes-quando-se-viaja/familias-de-tarifas/",

  //  CREATE REQUEST PAGE
  "createRequestPage.title": "O que você quer ressarcir?",
  "createRequestPage.refundTicket.title": "Reembolso de bilhetes",
  "createRequestPage.refundTicket.description":
    "Se os seus planos de viagem mudaram e você precisa solicitar o reembolso do seu bilhete, aqui você pode solicitar o cancelamento do seu itinerário e obter o reembolso do seu bilhete.",
  "createRequestPage.refundTicket.cta": "Solicite um reembolso",
  "createRequestPage.refundPaidServices.title":
    "Reembolso para serviços adicionais",
  "createRequestPage.refundPaidServices.description":
    "As taxas de bagagem, upgrades e assentos comprados não são" +
    " reembolsáveis. Se o serviço não foi cumprido devido à responsabilidade da companhia aérea, você pode solicitar um reembolso.",
  "createRequestPage.refundPaidServices.cta": "Solicite um reembolso",
  "createRequestPage.otherOptions.title": "Outras opções",
  "createRequestPage.otherOptions.subtitle":
    "Você já foi cobrado duas vezes pela mesma transação?",
  "createRequestPage.otherOptions.description":
    "Cobranças duplicadas ou incorretas para passagens aéreas e outros" +
    " serviços relacionados à sua viagem.",

  //  CREATE REQUEST PAGE WCAG
  "createRequestPage.wcag.pageDescription":
    "Através desta página, você poderá selecionar o tipo de reembolso que deseja realizar.",
  "createRequestPage.wcag.refundRequestBtn":
    "Pressione para solicitar um reembolso de bilhetes",
  "createRequestPage.wcag.refundPaidServiceBtn":
    "Pressione para solicitar um reembolso de serviços adicionais. " +
    "Uma nova página será aberta para solicitar esse reembolso.",
  "createRequestPage.wcag.refundDuplicateService":
    "Pressione para solicitar um reembolso de Cobranças duplicadas ou incorretas para passagens aéreas e outros" +
    " serviços relacionados à sua viagem. Uma nova página será aberta para solicitar esse reembolso",
  "createRequestPage.refundPaidServices.notHonoredURL":
    "https://help.copaair.com/hc/pt-br/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=___dc.servicios_adicionales_pagados_no_honrados__&ticket_form_id=360004175854",
  "createRequestPage.otherOptions.doubleChargeURL":
    "https://help.copaair.com/hc/pt-br/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=__dc.cobro_duplicado_o_incorrecto__&ticket_form_id=360004175854",
  //  CHECK STATUS PAGE WCAG
  "checkStatusPageWCAG.container":
    "Para consultar o seu pedido de reembolso, necessita do seu código de reserva, número" +
    " do bilhete ou número do seu processo. Digite-os nos campos abaixo e pressione aplicativo de pesquisa",

  //  CHECK STATUS PAGE
  "checkStatusPage.header": "Revisar o status de solicitação",
  "checkStatusPage.title": "Revisar solicitação de reembolso",
  "checkStatusPage.subtitle": "Insira o número do caso de sua solicitação.",
  "checkStatusPage.ticketLabel":
    "Número do caso, código de reserva ou número do bilhete eletrônico",
  "checkStatusPage.ticketPlaceholder": "RFD-WEB1234567",
  "checkStatusPage.ticketHelperText":
    "O número do caso deve ter 13 ou 14 dígitos e começar com RFD",
  "checkStatusPage.lastnameLabel": "Sobrenome",
  "checkStatusPage.lastnamePlaceholder": "Insira o sobrenome",
  "checkStatusPage.cta": "Encontrar solicitação",
  "checkStatusPage.footerTitle": "Onde encontro o número do meu caso?",
  "checkStatusPage.footerBullet":
    "Após a conclusão do seu pedido de reembolso, enviamos-lhe um e-mail. Neste e-mail você pode encontrar o número do seu caso, é composto por 13 ou 14 dígitos e começa com RFD (por exemplo, RFD-WEB1234567).",
  "checkStatusPage.alert":
    "Essa funcionalidade está disponível apenas para solicitações feitas após no dia 25 de agosto de 2022.",
  "pageHeader.description":
    "Opções de reembolso e cancelamento para passageiros da Copa Airlines",
  "errorAgency.title":
    "Para solicitar um reembolso, entre em contato com a agência de viagens ou empresa com a qual você fez essa reserva.",
  "errorAgency.subTitle":
    "Esta reserva foi adquirida por meio de um intermediário (por exemplo, agência de viagens, site de viagens). Para solicitar o reembolso do mesmo, você deve fazê-lo através deles. Caso a empresa terceirizada já tenha confirmado que não é possível processar o reembolso por meio dela, entre em contato com nossa ",
  "errorAgency.link": "Central de Reservas ",
  "errorAgency.continue": "para mais atenção.",
  "errorAgency.button": "Regresar a copa.com",
  "errorAgency.WCAGbutton": "Regresar a Copa.com",
  "errorAgency.WCAGContainer":
    "Não podemos processar seu reembolso, pois esta reserva foi comprada por meio de um intermediário (Exemplo: Agência de viagens, Página de viagens). Você deve entrar em contato com a empresa com a qual fez a compra para solicitar um reembolso.",
  "additionalInfoPage.header": "Solicitar um reembolso",
  "formOfPayment.title": "Informações bancárias para fazer um reembolso",
  "formOfPayment.bankName": "Nome do banco",
  "formOfPayment.placeholderBankName": "Digite o nome do banco",
  "formOfPayment.accountHolder": "Titular da conta",
  "formOfPayment.placeholderAccountHolder": "Insira o beneficiário",
  "formOfPayment.accountType": "Tipo de conta",
  "formOfPayment.placeholderAccountType": "Selecione o tipo de conta",
  "formOfPayment.savingsAccount": "Conta Poupança",
  "formOfPayment.checkingAccount": "Conta corrente",
  "formOfPayment.accountNumber": "Número da conta",
  "formOfPayment.placeholderAccountNumber": "Digite o número da conta",
  "formOfPayment.cpfCpnj": "CPF / CPNJ",
  "formOfPayment.placeholderCpfCpnj": "Digite o nome do banco",
  "formOfPayment.bankCode": "Código bancário",
  "formOfPayment.placeholderBankCode": "Digite o código do banco",
  "formOfPayment.agency": "Agência",
  "formOfPayment.placeholderAgency": "Digite o número da agência",
  "formOfPayment.interbankKey": "Chave interbancária",
  "formOfPayment.placeholderInterbankKey": "Digite a chave interbancária",
  "formOfPayment.swiftcode": "Numero do routing (ABA/Swiftcode)",
  "formOfPayment.placeholderSwiftcode": "Digite numero do routing",
  "formOfPayment.rfc": "RFC",
  "formOfPayment.placeholderRfc": "Digite RFC",
  "formOfPayment.cuitCuil": "CUIT / CUIL",
  "formOfPayment.placeholderCuitCuil": "Digite CUIT / CUIL",
  "formOfPayment.cbu": "CBU",
  "formOfPayment.placeholderCbu": "Digite CBU",
  "formOfPayment.bankingCertification": "Certificação bancária",
  "formOfPayment.placeholderBankingCertification": "Digite Certificação",
  "formOfPayment.IDNumber": "Número de identificação ou identificação",
  "formOfPayment.placeholderIDNumber": "Digite identificação",
  "formOfPayment.CCI": "Código de conta interbancária ou CCI",
  "formOfPayment.placeholderCCI": "Digite código",
  "formOfPayment.interbankAccountNumber": "Número da conta interbancária",
  "formOfPayment.placeholderInterbankAccountNumber": "Digite número da conta",
  "formOfPayment.NIT": "Número de identificação ou NIT",
  "formOfPayment.placeholderNIT": "Número de identificação ",
  "formOfPayment.phoneNumber": "Telefone",
  "formOfPayment.placeholderPhoneCode": "Código do país",
  "formOfPayment.placeholderPhoneNumber": "Digite número",
  "formOfPayment.address": "Direção",
  "formOfPayment.placeholderAddress": "Digite direção",
  "formOfPayment.RUC": "Número de identificação ou RUC",
  "formOfPayment.placeholderRUC": "Número de identificação ",
  "formOfPayment.transit": "Número de trânsito",
  "formOfPayment.placeholderTransit": "Digite número de trânsito",
  "formOfPayment.iban": "IBAN",
  "formOfPayment.placeholderIban": "Insira IBAN",
  "formOfPayment.branch": "Filial",
  "formOfPayment.placeholderBranch": "Insira Filial",
  "formOfPayment.passport": "Número de identificação ou passaporte",
  "formOfPayment.placeholderPassport": "Número de identificação ",
  "refundConfirmation.header": "Status do reembolso",
  "refundConfirmation.heading": "Código de reserva",
  "refundConfirmation.passengerDisclaimer":
    "Começaremos a avaliar sua inscrição nos próximos 21 dias.. Depois de processado, pode levar até duas declarações para que sua solicitação seja processada.",
  "refundConfirmation.title":
    "Pedido de reembolso enviado com sucesso. (Aprovação pendente)",
  "refundConfirmation.caseDateHeader": "Data de inscrição :",
  "refundConfirmation.caseDate": "15 de fevereiro de 2022",
  "refundConfirmation.caseStatus": "Situação atual:",
  "refundConfirmation.case": "Caso: ",
  "refundConfirmation.inProcess": "Recebido",
  "refundConfirmation.inReview": "Em revisão",
  "refundConfirmation.approved": "Aprovado",
  "refundConfirmation.closed": "Fechado",
  "refundConfirmation.button": "Solicitar um reembolso para outra reserva",
  "refundConfirmation.title2": "Que segue?",
  "refundConfirmation.subtitle2":
    "Você pode verificar o status da sua solicitação através do <cta>copa.com</cta>," +
    " na seção de reembolso, selecionando a opção “Verificar status”.",
  "refundConfirmation.subtitle3":
    "Sua solicitação pode ter qualquer um dos seguintes status:",
  "refundConfirmation.paragraph1":
    '<b>"Recebido"</b>, Status inicial com o qual a solicitação de caso é criada.',
  "refundConfirmation.paragraph2":
    '<b>"Em revisão"</b>,significa que está em processo de validação por nossa equipe de reembolso.',
  "refundConfirmation.paragraph3":
    '<b>"Aprovado"</b>, significa que confirmamos que a solicitação se qualifica para um reembolso' +
    " e a devolução do pagamento está em andamento com um tempo estimado de dois extratos de conta a serem feitos.",
  "refundConfirmation.paragraph4":
    '<b>"Fechado"</b>, o pedido não se qualifica para reembolso. Você pode entrar em contato com nossa  ' +
    "<cta>Central de Reservas</cta> se desejar fazer perguntas sobre sua solicitação. ",
  "refundConfirmation.buttonURL":
    "https://www.copaair.com/pt-pa/atendimento-ao-cliente/mudancas-e-reembolsos/",
  "refundStatus.title": "Status do aplicativo",
  "refundStatus.description":
    "Esta reserva já tem um ou mais pedidos de reembolso em aberto. Verifique o estado das candidaturas nesta página.",
  "generalLayoutPage.title1": "Não encontramos sua reserva",
  "generalLayoutPage.bullet1":
    "Certifique-se de estar usando um código de reserva da Copa Airlines, que é um código alfanumérico de 6" +
    " dígitos (por exemplo, ABC123)",
  "generalLayoutPage.bullet2":
    "Recomendamos localizar sua reserva usando o número do bilhete de um dos passageiros. (por exemplo, 2301234567890)",
  "generalLayoutPage.bullet3":
    "Se você comprou sua reserva por meio de uma agência de viagens, entre em contato com eles para solicitar um reembolso.",
  "generalLayoutPage.bullet4":
    'Se a sua reserva tiver mais de 1 ano, você deve selecionar a opção "Abrir um caso com informações do passageiro" na parte inferior da página.',
  "generalLayoutPage.subtitle1": "Insira suas informações de reserva",
  "generalLayoutPage.field1":
    "Código de reserva ou número do bilhete eletrônico",
  "generalLayoutPage.helperText1": "Insira o código de reserva",
  "generalLayoutPage.field2": "Sobrenome",
  "generalLayoutPage.helperText2": "Insira o sobrenome",
  "generalLayoutPage.errorAlertMessage": "Não recebemos esta reserva",
  "generalLayoutPage.button": "Encontrar reserva",
  "generalLayoutPage.title2": "O que mais posso fazer?",
  "generalLayoutPage.subtitle2": "Abra um caso com informações do passageiro",
  "generalLayoutPage.passengerDisclaimer":
    "Em alguns casos, suas informações de reserva podem não estar mais acessíveis ao sistema. Nesses casos, você precisará escalar um caso por meio dessa opção.",
  "generalLayoutPage.required": "Este campo é requerido",
  "generalLayoutPage.Invalid": "Formato inválido",
  "additionalInfoPage.heading": "Código de reserva",
  "additionalInfoPage.headingNonRevenue":
    "Código de reserva - Ticket Colaborador",
  "additionalInfoPage.pageSection": "Informações adicionais necessárias",
  "additionalInfoPage.titlePassengerSection":
    "A solicitação de reembolso será aplicada a todos os passageiros abaixo:",
  "additionalInfoPage.disclaimerPassenger":
    "Se você deseja solicitar um reembolso para determinados passageiros, precisará entrar em contato conosco na Central de Reservas para separá-los da reserva e poder voltar e fazer a solicitação.",
  "additionalInfoPage.titleRequesterSection": "Informações do solicitante",
  "additionalInfoPage.descriptionText":
    "Precisamos de suas informações de contato para prosseguir com a solicitação de reembolso. Essas informações serão usadas apenas para entrar em contato com você em relação à solicitação.",
  "additionalInfoPage.inputName": "Nome",
  "additionalInfoPage.helperTextName": "Insira o nome",
  "additionalInfoPage.inputLastname": "Sobrenome",
  "additionalInfoPage.helperTextLastname": "Inisra o sobrenome",
  "additionalInfoPage.inputEmail": "E-mail",
  "additionalInfoPage.helperTextEmail": "Insira o email",
  "additionalInfoPage.inputConfirmEmail": "Confirmar e-mail",
  "additionalInfoPage.confirmError": "Os e-mails não correspondem",
  "additionalInfoPage.helperTextConfirmEmail": "Reinsira o e-mail",
  "additionalInfoPage.phone1": "Número de telefone",
  "additionalInfoPage.phone2": "Número de telefone",
  "additionalInfoPage.samePhone": "Digite um número de telefone diferente",
  "additionalInfoPage.inputPhoneCode": "Código do país",
  "additionalInfoPage.inputPhone": "Número de telefone",
  "additionalInfoPage.helperTextPhone": "Insira um número",
  "additionalInfoPage.titleFOPSection": "Forma de pagamento original",
  "additionalInfoPage.FOPDescription":
    "Esta reserva foi comprada com o seguinte cartão de crédito. Se o pedido for aprovado, vamos usá-lo para fazer o reembolso.",
  "additionalInfoPage.FOPNumber":
    "Esta reserva foi comprada com cartão de crédito. Se o pedido for aprovado, vamos usá-lo para fazer o reembolso.",
  "additionalInfoPage.creditCardDisclaimer":
    "Caso este cartão tenha expirado ao efetuar o reembolso entraremos em contato com você para atualizar as informações.",
  "additionalInfoPage.titleRefundReason": "Motivo do seu reembolso",
  "additionalInfoPage.refundSubtitle":
    "Selecione o motivo da sua solicitação de reembolso. Podemos precisar que você forneça alguma documentação adicional. Selecione o motivo abaixo que melhor se aplica ao seu caso e siga as etapas.",
  "additionalInfoPage.inputRefund": "Motivo do seu pedido",
  "additionalInfoPage.helperTextRefund":
    "Selecione o motivo da sua solicitação",
  "additionalInfoPage.refundReasonVoluntaryChange":
    "Quero cancelar de acordo com as condições da tarifa",
  "additionalInfoPage.refundReasonInvoluntaryChange":
    "Cancelamento ou mudança de itinerário feito pela Copa Airlines",
  "additionalInfoPage.refundReasonNaturalDisaster": "Desastre natural",
  "additionalInfoPage.refundReasonDeath": "Morte",
  "additionalInfoPage.refundReasonTerminalillness": "Doença critica o terminal",
  "additionalInfoPage.refundReasonOther": "Outro",
  "additionalInfoPage.refundReasonImmigrationIssues": "Problemas de imigração",
  "additionalInfoPage.titleSelectedReason": "Motivo: ",
  "additionalInfoPage.descriptionFareConditions":
    "Sua solicitação será processada de acordo com as condições da família tarifária adquirida. ",
  "additionalInfoPage.descriptionFareConditionsURL":
    "Saiba mais sobre nossas famílias tarifárias.",
  "additionalInfoPage.descriptionDeath":
    "Para esses casos, é necessário anexar a cópia do atestado de óbito dos passageiros. Você pode anexar os documentos necessários abaixo.",
  "additionalInfoPage.description Natural Disaster":
    "Aplica-se nos casos em que um desastre natural (por exemplo, tempestade, terremoto, erupção de vulcão) afeta significativamente uma cidade ou região relacionada ao seu itinerário.",
  "additionalInfoPage.descriptionCopaAffectedMyTrip":
    "Aplica-se apenas se algum de seus voos foi modificado pela Copa devido a adiantamentos, atrasos, cancelamentos, operações irregulares. Caso contrário, sua solicitação será rejeitada.",
  "additionalInfoPage.descriptionMigratoryIssues":
    "Aplica-se se você não pôde fazer a viagem porque não atendeu aos requisitos de imigração do país de destino.",
  "additionalInfoPage.descriptionTerminalDisease":
    "Para esses casos, é necessário anexar uma Carta (em papel timbrado) de um médico licenciado confirmando que a viagem não foi recomendada dentro da validade da passagem (um ano da data de emissão da passagem) devido a doença.",
  "additionalInfoPage.descriptionOther":
    "Por favor, explique a situação com mais detalhes.",
  "additionalInfoPage.requesterIdUploadDocumentsTitle": "Anexar documentos",
  "additionalInfoPage.uploadDocumentsTitle": "Anexar documentos",
  "additionalInfoPage.uploadDocumentsFormats":
    "Documentos permitidos: .pdf, .jpg, .png",
  "additionalInfoPage.uploadDocumentsSize": "Tamanho máximo: 4MB",
  "additionalInfoPage.uploadDocumentsHelper1":
    "Arraste os arquivos aqui ou clique para fazer upload",
  "additionalInfoPage.uploadDocumentsHelper2":
    "Solte aqui para fazer upload dos arquivos",
  "additionalInfoPage.uploadDocumentsErrorFormat":
    "Este formato de arquivo não é permitido.",
  "additionalInfoPage.uploadDocumentsErrorLimit":
    "Você não pode adicionar mais arquivos.",
  "additionalInfoPage.uploadDocumentsErrorSize":
    "O tamanho do arquivo excede o limite permitido.",
  "additionalInfoPage.uploadDocumentsLoading": "Carregando",
  "additionalInfoPage.uploadDocumentsUploadError": "Erro ao carregar",
  "additionalInfoPage.uploadDocumentsGeneralError":
    "Alguns arquivos não puderam ser carregados. Tente novamente",
  "additionalInfoPage.uploadDocumentsErrorRequired":
    "Você deve anexar um documento.",
  "additionalInfoPage.checkbox1":
    "Declaro que tenho autoridade para solicitar esse reembolso. Compreendo e concordo que a aprovação do meu reembolso está sujeita aos regulamentos das regras tarifárias.",
  "additionalInfoPage.checkbox2":
    "Autorizo ​​de forma livre, expressa e inequívoca a Copa Airlines a coletar, usar e processar as informações aqui inseridas para processar minha solicitação de reembolso. Também autorizo ​​a transferência de minhas informações para empresas afiliadas e terceiros autorizados conforme necessário para concluir o reembolso. Declaro, reconheço e aceito as Políticas de Privacidade da empresa.",
  "additionalInfoPage.button": "Continuar",
  "additionalInfoPage.passengerDisclaimer1":
    "Se você deseja solicitar um reembolso para determinados passageiros, precisará entrar em contato conosco na ",
  "additionalInfoPage.passengerDisclaimerURL": "Central de Reservas",
  "additionalInfoPage.passengerDisclaimer2":
    "  para separá-los da reserva e poder voltar e fazer a solicitação.",
  "additionalInfoPage.relationship": "Relação com passageiros",
  "additionalInfoPage.relationshipPlaceholder": "Escolher",
  "additionalInfoPage.relationshipOther": "Descreva sua relação",
  "additionalInfoPage.relationshipOtherPlaceholder": "Descreva sua relação",
  "additionalInfoPage.relationshipOption1":
    "Família direta (pais, filhos ou cônjuge)",
  "additionalInfoPage.relationshipOption2": "Eu sou um dos passageiros",
  "additionalInfoPage.relationshipOption3": "Outro",
  "warningModalLayout.header":
    "Ao continuar com a solicitação, seu itinerário será cancelado. Você quer continuar?",
  "warningModalLayout.passengerDisclaimer":
    "Após a confirmação do seu pedido de reembolso, todos os voos da sua reserva serão imediatamente cancelados para todos os passageiros. Se mudar de ideia e desejar reativar sua reserva, não podemos garantir a disponibilidade de seus voos atuais. A reativação dependerá da disponibilidade naquele momento.",
  "warningModalLayout.passengerDisclaimerNonRevenue":
    "Você tem um ticket colaborador. O cancelamento dos seus voos resultará no reembolso sendo emitido para a Copa Airlines e todos os seus voos serão cancelados.",
  "warningModalLayout.title": "Solicitante de reembolso",
  "warningModalLayout.fullName": "Nome completo",
  "warningModalLayout.email": "E-mail",
  "warningModalLayout.phone": "Número de telefone",
  "warningModalLayout.reason": "Motivo do seu reembolso",
  "warningModalLayout.passengersTitle": "Passageiros solicitando reembolso",
  "warningModalLayout.passengeName": "Nome",
  "warningModalLayout.dateOfBirth": "Data de nascimento",
  "warningModalLayout.eTicket": "Número do bilhete",
  "warningModalLayout.button1": "Volte",
  "warningModalLayout.button2": "Reembolso pedido",

  // ADD PASSENGERS PAGE
  "addPassengersPage.title": "Solicitar um reembolso",
  "addPassengersPage.subtitle": "Adicionar passageiros",
  "addPassengersPage.descriptionText":
    "Por favor, insira as informações de todos os passageiros na reserva para solicitar um reembolso. A solicitação será feita para todos os passageiros na reserva.",
  "addPassengersPage.paxTitle": "Passageiro",
  "addPassengersPage.inputReservation": "Código de reserva",
  "addPassengersPage.placeholderReservation": "Insira o código de reserva",
  "addPassengersPage.inputName": "Nome",
  "addPassengersPage.placeholderName": "Insira o nome",
  "addPassengersPage.inputLastname": "Sobrenome",
  "addPassengersPage.placeholderLastname": "Inisra o sobrenome",
  "addPassengersPage.addPaxButton": "Adicionar passageiro",
  "addPassengersPage.inputTicketCode": "Número do bilhete eletrônico",
  "addPassengersPage.placeholderTicketCode": "Insira o número do bilhete",
  "addPassengersPage.helperTicketCode":
    "O número do bilhete eletrônico começa com 230",
  "addPassengersPage.emdErrorScenario":
    "Formato Inválido. Use o número do bilhete eletrônico da sua compra.",
  "addPassengersPage.errorTicketCode":
    "Formato inválido. O bilhete eletrônico deve ser composto por 13 números e começar com 230",
  "addPassengersPage.cta": "Continuar",
  "addPassengersPage.remove": "Remover",
  "addPassengersPage.alertError":
    "Já existe um pedido de reembolso para este número de bilhete. Este é o número do seu caso {caseNumber}, você pode verificar o status ",
  "addPassengersPage.alertErrorLink": "aqui.",
  "addPassengersPage.caseAlreadyOpenLabelError":
    "Já existe um pedido de reembolso para este número de bilhete. ",

  // CALL CENTER SPECIAL LINK
  "callCenterSpecialLink.title": "Encontrar reserva",
  "callCenterSpecialLink.description":
    "Insira o código da reserva ou o número do bilhete eletrônico e o sobrenome para fazer a solicitação de reembolso.",
  "callCenterSpecialLink.subtitle": "Insira suas informações de reserva",
  "callCenterSpecialLink.requiredMessage": "Este campo é requerido",
  "callCenterSpecialLink.invalidFormatMessage": "Formato inválido",
  "callCenterSpecialLink.button": "Encontrar reserva",
  "callCenterSpecialLink.moreOptionTitle": "O que mais posso fazer?",
  "callCenterSpecialLink.moreOptionSubtitle":
    "Abra um caso com informações do passageiro",
  "callCenterSpecialLink.moreOptionBody":
    "Em alguns casos, suas informações de reserva podem não estar mais acessíveis ao sistema. Nesses casos, você precisará escalar um caso por meio dessa opção. ",

  //  EXCEPTIONS PAGE
  "exceptionsPage.title":
    "Desculpe, sua reserva não cumpre os requisitos para abrir uma solicitação de reembolso",
  "exceptionsPage.subtitle": "Alguns motivos podem ser:",
  "exceptionsPage.bullet1": "A sua reserva está pendente de pagamento.",
  "exceptionsPage.bullet2": "A sua reserva é para grupos.",
  "exceptionsPage.bullet3": "Sua reserva é para resgates",
  "exceptionsPage.bullet4":
    "Seu itinerário tem voos operados pela outra companhia aérea.",
  "exceptionsPage.bullet5": "Sua reserva foi cancelada.",
  "exceptionsPage.bullet6": "Sua reserva foi reembolsada.",
  "exceptionsPage.bullet7": "Você já completou seu itinerário de viagem.",
  "exceptionsPage.bullet8":
    "A sua reserva foi cancelada devido a uma reclamação do banco.",
  "exceptionsPage.cta": "Voltar para copa.com",

  //  REASON SECTION PAGE
  "reasonSectionPage.title": "Motivo do seu reembolso",
  "reasonSectionPage.subtitle":
    "Selecione o motivo da sua solicitação de reembolso. Podemos precisar que você forneça " +
    "alguma documentação adicional. Selecione o motivo abaixo que melhor se aplica " +
    "ao seu caso e siga as etapas.",
  "reasonSectionPage.field1": "Motivo do seu pedido",
  "reasonSectionPage.placeholder":
    "Selecione o motivo da sua solicitação de reembolso. ",
  "reasonSectionPage.invalidFormatError": "Formato inválido",
  "reasonSectionPage.isRequiredError": "Este campo é requerido",
  "reasonSectionPage.refundReasonOption1":
    "Quero cancelar de acordo com as condições do meu bilhete",
  "reasonSectionPage.refundReasonOption2":
    "A Copa Airlines fez uma alteração involuntária no meu itinerário de voo.",
  "reasonSectionPage.refundReasonOption3": "Desastre natural",
  "reasonSectionPage.refundReasonOption4": "Morte",
  "reasonSectionPage.refundReasonOption5": "Outro",
  "reasonSectionPage.titleSelectedReason": "Motivo: ",
  "reasonSectionPage.deathDescription":
    "Para esses casos, é necessário anexar a certidão de óbito de um ou mais" +
    " passageiros, emitida pela autoridade competente do país. " +
    "Você pode anexar os documentos necessários abaixo.",
  "reasonSectionPage.naturalDisasterDescription":
    "Aplica-se nos casos em que um desastre natural (por exemplo, tempestade," +
    " terremoto, erupção de vulcão) afeta significativamente uma cidade ou" +
    " região relacionada ao seu itinerário.",
  "reasonSectionPage.affectedFlyOptionDescription":
    "Aplica-se apenas se algum de seus voos foi modificado pela Copa" +
    " devido a atrasos, cancelamentos ou operações irregulares. " +
    "Caso contrário, sua solicitação será rejeitada.",
  "reasonSectionPage.otherBoxDescription":
    "Por favor, explique a situação com mais detalhes.",
  "reasonSectionPage.descriptionLabel": "Explique a situação",
  "reasonSectionPage.descriptionPlaceholder": "Enviar informações",
  "reasonSectionPage.checkbox1":
    "Declaro que tenho autoridade para solicitar esse reembolso. Compreendo e concordo que a aprovação do meu reembolso está sujeita aos regulamentos das regras tarifárias.",
  "reasonSectionPage.checkbox2":
    "Autorizo ​​de forma livre, expressa e inequívoca a Copa Airlines a coletar, usar e processar as informações aqui inseridas para processar minha solicitação de reembolso. Também autorizo ​​a transferência de minhas informações para empresas afiliadas e terceiros autorizados conforme necessário para concluir o reembolso. Declaro, reconheço e aceito as Políticas de Privacidade da empresa.",
  "reasonSectionPage.button": "Continuar",

  // ERROR PAGE
  "errorPage.header": "Solicitar um reembolso",
  "errorPage.alert": "Desculpe!",
  "errorPage.title": "Desculpe! Ocorreu um erro.",
  "errorPage.subtitle":
    "Devido a dificuldades técnicas, não foi possível prosseguir com a criação de sua solicitação de reembolso. Por favor, comece de novo.",
  "errorPage.cta": "Tentar novamente",

  // INTERSITIAL
  "intersitial.loading": "Carregando",
  "intersitial.alertError":
    "Sentimos muito! Não encontramos sua solicitação. Por favor, tente novamente.",
  "intersitialWCAG.alertError":
    "Não encontramos seu caso com as informações fornecidas. Tente novamente alterando os dados.",

  // PAYMENT METHOD
  "paymentMethod.countryPurchase": "País de compra",
  "paymentMethod.countryPurchasePlaceholder": "Selecione o pais",
  "paymentMethod.PaymentMethod": "Forma de pagamento",
  "paymentMethod.PaymentMethodPlaceholder": "Selecione o forma de pagamento",
  "paymentMethod.TransferCash": "Transferência bancária/Dinheiro",
  "paymentMethod.CreditCard": "Cartão de crédito",
  "paymentMethod.Other": "Outro",

  // COUNTRIES
  "countries.BR": "Brasil",
  "countries.MX": "México",
  "countries.AR": "Argentina",
  "countries.CO": "Colômbia",
  "countries.CA": "Canadá",
  "countries.CL": "Chile",
  "countries.PA": "Panamá",
  "countries.EU": "Europa",
  "countries.CU": "Cuba",
  "countries.PE": "Peru",
  "countries.NI": "Nicarágua",
  "countries.EC": "Equador",
  "countries.HT": "Haiti",
  "countries.GY": "Guiana",
  "countries.BB": "Barbados",
  "countries.TT": "Trindade e Tobago",
  "countries.OTHER": "Outro",

  // 404 PAGE NOT FOUND

  "pageNotFound.button": "Voltar para copa.com",
  "pageNotFound.Title": "Não foi possível encontrar esta página",
  "pageNotFound.Subtitle":
    "Desculpe, a página que você tentou visitar não existe em nosso site.",
  "pageNotFound.CTA": "Ir para começar",

  // 404 PAGE NOT FOUND WCAG

  "pageNotFoundWCAG.container":
    "Sentimos muito. A página que você tentou acessar não existe. Volte a copa.com para tentar novamente.",

  //  REASON SECTION PAGE WCAG
  "reasonSectionPageWCAG.refundSelect":
    "No menu suspenso, use as teclas de seta para cima e para baixo para selecionar o" +
    " motivo da solicitação de reembolso.",
  "reasonSectionPageWCAG.checkbox1":
    "Pressione para indicar que você declara que tem o poder de solicitar esse reembolso. Que você entende e aceita que a" +
    " aprovação do reembolso está sujeita às regras de sua tarifa e à política de reembolso da copa airlines.",
  "reasonSectionPageWCAG.checkbox2":
    "Clique para indicar que você concorda que as informações inseridas neste " +
    "formulário serão usadas apenas para fins de solicitação de reembolsos.",
  "reasonSectionPageWCAG.buttonCta":
    "Botão. Pressione ENTER para continuar com a solicitação de reembolso.",
  "reasonSectionPageWCAG.textArea":
    "Use esta caixa de texto para descrever os motivos pelos quais você deseja fazer o" +
    " reembolso. Você pode escrever até um limite de 250 caracteres.",

  // EXCEPTIONS PAGE WCAG
  "exceptionsPageWCAG.container":
    "Não podemos processar seu reembolso, pois esta reserva foi comprada por meio de um intermediário " +
    "(Exemplo: Agência de viagens, Página de viagens). Você deve entrar em contato com a empresa com a qual fez a compra para solicitar um reembolso.",

  // ADD PASSENGERS PAGE WCAG
  "addPassengersPageWCAG.generalDescription":
    "Página para preencher as informações dos passageiros que vão solicitar o reembolso. O reembolso será processado para todos os passageiros da reserva. Este pedido só é válido para reservas de um máximo de 9 passageiros.",
  "addPassengersPageWCAG.inputName":
    "Escreva o nome do passageiro número [X], apenas letras e espaços em branco são permitidos.",
  "addPassengersPageWCAG.reservationCode":
    "Você pode adicionar o código de reserva para ser reembolsado. Este código de reserva tem 6 caracteres.",
  "addPassengersPageWCAG.inputLastname":
    "Escreva o sobrenome do passageiro número [X], somente letras e espaços em branco são permitidos.",
  "addPassengersPageWCAG.inputETicket":
    "Escreva o bilhete eletrônico do passageiro número [X], apenas 13 números são permitidos e deve começar com 230.",
  "addPassengersPageWCAG.addPaxButton":
    "Botão Adicionar mais passageiros. Pressione enter para adicionar outro passageiro à solicitação.",
  "addPassengersPageWCAG.removePaxButton":
    "Botão para excluir passageiro. Pressione enter para remover o passageiro da solicitação",
  "addPassengersPageWCAG.cta":
    "Botão para continuar. Pressione enter para continuar com a solicitação.",
  "addPassengersPageWCAG.alertError":
    "Já existe um número de caso para este bilhete eletrônico. O número do seu caso é" +
    " {caseNumber}. Vá para a página de status de verificação para verificar o status de sua inscrição.",

  // WCAG Text
  "additionalInfoPageWCAG.inputName":
    "Digite seu nome, apenas letras e espaços em branco são permitidos.",
  "additionalInfoPageWCAG.inputLastname":
    "Digite seu nome da familia ou 'sobrenome', apenas letras e espaços em branco são permitidos",
  "additionalInfoPageWCAG.inputEmail":
    "Escreva o email onde pretende receber a confirmação do pedido e onde entraremos em contacto consigo caso seja necessário.",
  "additionalInfoPageWCAG.inputConfirmEmail":
    "Confirme o email onde pretende receber a confirmação do pedido e onde entraremos em contacto consigo caso seja necessário.",
  "additionalInfoPageWCAG.inputPhoneCode1":
    "Suspenso, selecione a opção do código do seu país.",
  "additionalInfoPageWCAG.inputPhone1":
    "Escreva seu número de telefone. Este é um campo numérico.",
  "additionalInfoPageWCAG.inputPhoneCode2":
    "No menu suspenso, selecione a opção para o código do seu país de um segundo número.",
  "additionalInfoPageWCAG.inputPhone2":
    "Digite um segundo número de telefone. Este é um campo numérico.",
  "additionalInfoPageWCAG.generalDescription":
    "Página para solicitar o reembolso da sua reserva. Para concluir a inscrição, preencha as informações adicionais que solicitamos. Alguns campos são obrigatórios e outros são opcionais. Dependendo do motivo selecionado para o reembolso, você deve anexar determinados documentos. Por favor, certifique-se de que as informações estão corretas, pois assim que a inscrição for concluída, você não poderá alterá-la. O pedido de reembolso é feito para todos os passageiros dentro da reserva, caso pretenda solicitar o reembolso apenas para determinados passageiros, deverá primeiro ligar para a nossa central de reservas para separar os passageiros para outra reserva.",
  "additionalInfoPageWCAG.paxContainer":
    "Esta é a lista de todos os passageiros que estão solicitando reembolso: passageiro número um [Nome e Sobrenome, Data de Nascimento, Bilhete #]; passageiro número dois [nome e sobrenome, data de nascimento, bilhete nº]; passageiro número três [Nome e Sobrenome, Data de Nascimento, bilhete #], passageiro número quatro [Nome e Sobrenome, Data de Nascimento, bilhete #], etc.",
  "additionalInfoPageWCAG.formOfPaymentContainer":
    "O método de pagamento original usado para comprar esta reserva era um cartão de [tipo] terminado em [últimos 4 dígitos]. Este será o cartão para o qual será feito o reembolso se o pedido for aceite.",
  "additionalInfoPageWCAG.reasonOfTheRefund":
    "No menu suspenso, use as teclas de seta para cima e para baixo para selecionar o motivo da solicitação de reembolso.",
  "additionalInfoPageWCAG.legalCheckBox1":
    "Pressione para indicar que você declara que tem o poder de solicitar esse reembolso. Que você entende e aceita que a aprovação do reembolso está sujeita às regras de sua tarifa e à política de reembolso da copa airlines.",
  "additionalInfoPageWCAG.legalCheckBox2":
    "Clique para indicar que você concorda que as informações inseridas neste formulário serão usadas apenas para fins de solicitação de reembolsos.",
  "additionalInfoPageWCAG.uploadDocuments":
    "Você pode anexar seus arquivos para apoiar seu reembolso aqui. Você pode adicionar até {numberOfDocs} arquivos que não devem pesar mais de 4 MB. Só permite anexar documentos em formato .pdf; .jpg e .png. Depois de anexá-los, você pode remover qualquer documento pressionando o X ao lado de cada um deles.",
  "additionalInfoPageWCAG.uploadDocumentsDelete":
    "Clique aqui para remover este documento.",
  "additionalInfoPageWCAG.uploadDocumentsReload":
    "Parece que houve um erro com o arquivo. Clique aqui para recarregar.",
  "additionalInfoPageWCAG.uploadDocumentsFormatError":
    "O formato que você anexou não está correto. Anexe documentos em formato .pdf; .jpg ou .png",
  "additionalInfoPageWCAG.uploadDocumentsLimitError":
    "Mensagem de erro - Limite de upload Você só pode enviar até {numberOfDocs} arquivos. Exclua um dos arquivos adicionados para anexar o novo.",
  "additionalInfoPageWCAG.uploadDocumentsSizeError":
    "Mensagem de erro - Limite de tamanho O arquivo que você tentou adicionar é muito grande. Somente arquivos de até 4 MB são permitidos",
  "additionalInfoPageWCAG.uploadDocumentsRequiredError":
    "Você deve anexar um arquivo. Anexe um arquivo para continuar",
  "additionalInfoPageWCAG.uploadDocumentsDocumentError":
    "Alguns documentos que você anexou não têm o formato permitido. Exclua-os usando o X ao lado do arquivo e adicione um novo com o formato .pdf, .jpg ou .png",
  "additionalInfoPageWCAG.cta":
    "Botão. Pressione ENTER para continuar com a solicitação de reembolso.",
  "additionalInfoPageWCAG.relationship":
    "Se você não for o proprietário da reserva. Por favor, descreva sua relação com os passageiros. Este é um campo de múltipla escolha.",
  "additionalInfoPageWCAG.relationshipOther":
    "Se você escolheu a outra opção, descreva sua relação com o passageiro. Este é um campo de texto.",
  "warningModalWCAG.modal":
    "Seção de confirmação de solicitação de reembolso. Você encontrará mais informações abaixo.",
  "warningModalWCAG.container":
    "Resumo da sua solicitação de reembolso. Se você prosseguir com a solicitação de reembolso, todo o itinerário será cancelado. Esta solicitação de reembolso foi feita por [nome] devido a [Motivo da solicitação], para os seguintes passageiros: passageiro número um [nome], passageiro número dois [nome], etc.",
  "warningModalWCAG.ctaBack":
    "Botão para retornar à página de informações adicionais. Pressione enter para retornar à página anterior.",
  "warningModalWCAG.ctaConfirm":
    "Botão de solicitação de reembolso. Pressione o botão para confirmar a solicitação de reembolso e cancelar seu itinerário.",
  // ERROR PAGE WCAG
  "errorPageWCAG.container":
    "Devido a dificuldades técnicas, não foi possível prosseguir com a criação de sua solicitação de reembolso. Por favor, comece de novo.",
  "errorPageWCAG.cta": "Botão de tentar novamente",
  //MODAL NPAFOPCASH
  "modalNRAndAFOPCash.title": "Esta reserva foi paga em dinheiro",
  "modalNRAndAFOPCash.text":
    "Recomendamos que você solicite o reembolso em um de nossos escritórios de vendas para que seja processado imediatamente. Através deste formulário, o reembolso pode levar até dois meses para ser efetivado.",
  "modalNRAndAFOPCash.secundaryButton": "Continuar com a solicitação",
  "modalNRAndAFOPCash.primaryButton": "Cancelar",
  "modalNRAndAFOPCash.primaryButtonWCAG":
    "Pressione sim para querer cancelar e voltar ao início do fluxo",
  "modalNRAndAFOPCash.secundaryButtonWCAG":
    "Pressione para continuar com a solicitação de reembolso",
  //ROUTES
  "links.changesAndRefunds":
    "/pt-gs/atendimento-ao-cliente/mudancas-e-reembolsos/",
  // Modal Non-Refundable Fares
  "modalNonRefundableFares.title": "Este bilhete não é reembolsável",
  "modalNonRefundableFares.descriptionStart":
    "A família tarifária que você comprou não é reembolsável. No entanto," +
    " podem ser aplicadas exceções em certas circunstâncias. Aqui estão as exceções:",
  "modalNonRefundableFares.firstBullet": "Morte (é necessário um certificado)",
  "modalNonRefundableFares.secondBullet":
    "Cancelamentos ou alterações feitas pela Copa Airlines que afetem o seu itinerário",
  "modalNonRefundableFares.descriptionEnd":
    "Se você acredita que o seu caso pode se enquadrar em uma dessas exceções, você pode prosseguir com o processo",
  "modalNonRefundableFares.continueButton": "Solicitar exceção",
  "modalNonRefundableFares.continueButtonWCAG":
    "Pressione para continuar com exceções",
  "modalNonRefundableFares.cancelButton": "Voltar ao início",
  "modalNonRefundableFares.cancelButtonWCAG":
    "Pressione sim para querer cancelar e voltar ao início do fluxo",
}
