import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useAppDispatch } from "./store-hooks"
import { useGetRefund } from "./useAPICall"
import { getCodeType, sendGACustomEvent } from "../utils/functions"

export const useStoreRefund = (params) => {
  const {
    requestCode,
    lastname,
    reload = true,
    goBackSteps,
    source,
    skip,
  } = params
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const codeType = getCodeType(requestCode)
  const requestBody = {
    ...params,
    [codeType]: requestCode,
    lastname: codeType == "eTicket" ? undefined : lastname,
  }

  const {
    responseData: refundData,
    error: refundError,
    request,
    isLoading,
  } = useGetRefund(requestBody)

  const refundNotFound = [404, 400].includes(refundError?.response?.status)

  useEffect(() => {
    if (refundError && !refundNotFound) {
      console.error(refundError)
      navigate("/error")
    }
  }, [refundError, refundNotFound])

  function sendGA() {
    if (skip) {
      sendGACustomEvent("Check status", {
        source: "checkstatus",
      })
    } else {
      sendGACustomEvent("Case created already", {
        source,
        requestCodeType: getCodeType(requestCode),
      })
    }
  }

  useEffect(() => {
    if (refundData) {
      dispatch({
        passengers: refundData.tickets,
        caseNumber: refundData.caseNumber,
        requestCode: refundData.reservation?.pnr,
        status: {
          date: refundData.requestDate,
          name: refundData.status,
        },
      })
      const to = goBackSteps ? `&goBackSteps=${goBackSteps}` : ""

      if (reload) {
        sendGA()
        navigate(
          `/request-status?lastname=${
            lastname || refundData.tickets?.[0]?.lastName
          }&case_number=${refundData.caseNumber}${to}`
        )
      }
    }
  }, [refundData])

  return { refundData, refundError, refundNotFound, request, isLoading }
}
