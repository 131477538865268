import theme from "@bit/vibenitez.copa-components-library.theme/index"

const styles = {
  header: {
    fontWeight: { xs: 700 },
    lineHeight: {
      xs: "24px",
      md: "40px",
    },
    fontSize: {
      xs: "18px",
      sm: "32px",
    },
  },
  container: {
    padding: {
      xs: "0px 16px 0px 16px",
      sm: "0px 104px 0px 104px",
      md: "0px 169px 0px 169px",
      lg: "0px 212px 0px 212px",
    },
    marginTop: {
      xs: "32px",
      sm: "80px",
      lg: "88px",
    },
  },
  title: {
    fontFamily: "Gilroy",
    fontWeight: 700,
    fontSize: { xs: "20px", sm: "28px", md: "32px" },
    lineHeight: { sm: "32px", md: "40px" },
    color: theme.palette.primary.dark,
  },
  subtitle: {
    marginTop: "8px",
    fontSize: { sm: "16px", md: "20px" },
    fontWeight: 400,
    lineHeight: "32px",
    color: theme.palette.grey["600"],
    marginBottom: "32px",
  },
  alert: {
    marginBottom: "32px",
    height: { md: "40px" },
  },
  formContainer: {
    marginTop: { lg: "56px" },
    padding: {
      xs: "34px 22px 0px 22px",
      sm: "44px 32px",
      md: "32px 42px 10px 44px",
      lg: "54px 32px 0px 36px",
    },
  },
  footer: {
    marginTop: {
      xs: "30px",
      sm: "84px",
      md: "77px",
    },
    marginBottom: "40px",
  },
  footerTitle: {
    fontFamily: "Gilroy",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "32px",
    color: theme.palette.grey["700"],
  },
}

export default styles
