import React, { useEffect } from "react"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import { Box, Grid } from "@mui/material"
import Button from "@bit/vibenitez.copa-components-library.button"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import theme from "@bit/vibenitez.copa-components-library.theme/index"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import { useForm, Controller, useFormState } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import styles from "./styles"
import VALIDATIONS from "../../../../utils/validations"
import { createErrorsMessages } from "../../../../utils/errorsMessages"
import {
  useAppDispatch,
  useAppSelectGeneralInfo,
} from "../../../../hooks/store-hooks"

export function PNRRetrieveForm({
  containerStyle,
  buttonText,
  hasError,
  isAgency,
}) {
  const { formatMessage } = useIntl()
  const generalContext = useAppSelectGeneralInfo()
  const dispatch = useAppDispatch()
  const { requestCode, userLastName } = generalContext

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    setValue,
    setError,
  } = useForm({
    mode: "all",
  })

  const { isValid } = useFormState({
    control,
  })

  useEffect(() => {
    if (hasError) {
      setError("pnrCode", {
        type: "pnr-not-found",
      })

      setError("lastname", {
        type: "pnr-not-found",
      })
    }
    setValue("pnrCode", requestCode)
    setValue("lastname", userLastName)
  }, [requestCode, userLastName, hasError])

  const native = useNavigate()

  const handleRequestSubmit = async (data) => {
    dispatch({
      requestCode: data.pnrCode,
      userLastName: data.lastname,
    })

    const redirectPage = isAgency ? "/agency/create-request" : "/create-request"

    native(
      `${redirectPage}?request_code=${data.pnrCode}&last_name=${data.lastname}`
    )
  }

  const errorsMessages = createErrorsMessages(formatMessage)

  return (
    <Box
      component="form"
      id="pnr-retrieve-form-container"
      onSubmit={handleSubmit(handleRequestSubmit)}
      sx={{ ...styles.pnrRetrieveContainer, ...containerStyle }}
    >
      <Box sx={styles.header}>
        <Typography id="pnr-retrieve-form-title" sx={styles.title}>
          {formatMessage({ id: "generalLayoutPage.subtitle1" })}
        </Typography>
      </Box>

      <Box is="form" sx={styles.formContainer}>
        <Grid container alignItems="flex-end">
          <Grid item xs={12} md={4.2} spacing={{ lg: 2 }}>
            <Box sx={styles.firstInput}>
              <Controller
                control={control}
                id="pnrCode"
                name="pnrCode"
                rules={VALIDATIONS.PNR}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    inputProps={{ maxLength: 13 }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value && !formErrors.pnrCode?.type && (
                            <CheckIcon
                              style={{
                                color: theme.palette.grey["400"],
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    hasErrors={formErrors.pnrCode}
                    required
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                    label={formatMessage({ id: "generalLayoutPage.field1" })}
                    helperText={
                      formErrors.pnrCode &&
                      errorsMessages[formErrors.pnrCode?.type]
                    }
                    labelClassName={styles.inputRegularLabelCustomFont}
                    id="pnrCode"
                    placeholder={formatMessage({
                      id: "generalLayoutPage.helperText1",
                    })}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4.2}>
            <Box sx={styles.secondInput}>
              <Controller
                control={control}
                id="lastname"
                name="lastname"
                rules={VALIDATIONS.NAME_LASTNAME}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    required
                    hasErrors={formErrors.lastname}
                    value={value}
                    handleChange={onChange}
                    label={formatMessage({ id: "generalLayoutPage.field2" })}
                    helperText={
                      formErrors.lastname &&
                      formErrors.lastname.type !== "pnr-not-found"
                        ? errorsMessages[formErrors.lastname?.type]
                        : ""
                    }
                    labelClassName={styles.inputRegularLabelCustomFont}
                    id="lastname"
                    placeholder={formatMessage({
                      id: "generalLayoutPage.helperText2",
                    })}
                    onBlur={onBlur}
                    inputProps={{ maxLength: 30 }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value && !formErrors.lastname?.type && (
                            <CheckIcon
                              style={{
                                color: theme.palette.grey["400"],
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3.6}>
            <Button
              type="submit"
              disabled={!isValid}
              id="pnr-retrieve-submit"
              sx={styles.button}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

PNRRetrieveForm.propTypes = {
  containerStyle: PropTypes.node,
  buttonText: PropTypes.node.isRequired,
  isAgency: PropTypes.node,
  hasError: PropTypes.node,
}

PNRRetrieveForm.defaultProps = {
  containerStyle: {},
  isAgency: false,
  hasError: false,
}
