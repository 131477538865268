import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  refundFormChecks: {
    marginTop: "48px",
    [theme.breakpoints.down("md")]: {
      marginTop: "64px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  refundFormChecksItem: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "26px",
  },
  reasonOfTheRefund: {
    marginTop: "64px",
    marginBottom: "56px",
  },
}))

export default useStyles
