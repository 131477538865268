import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Modal from "@bit/vibenitez.copa-components-library.modal"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import theme from "@bit/vibenitez.copa-components-library.theme"
import ModalHeaderIcon from "../Modals/ModalHeaderIcon"
import ExclamationMark from "../../../../assets/Icons/svg/ExclamationMark.svg"
import modalStyles from "./styles"
import { onCancelModal } from "../Modals/onCancelModal"

export function ModalAFOPCash({ showModal, setShowModal, isAgency }) {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <Modal
      open={showModal}
      handleClose={(_, reason) => {
        if (reason !== "backdropClick") {
          setShowModal(false)
        }
      }}
      disableEscapeKeyDown
      aria-label={formatMessage({
        id: "warningModalWCAG.modal",
      })}
      secondaryButtonProps={{
        text: formatMessage({
          id: "modalNRAndAFOPCash.secundaryButton",
        }),
        id: "Modal_Button_Submit",
        disable: false,
        "aria-label": formatMessage({
          id: "modalNRAndAFOPCash.secundaryButtonWCAG",
        }),
        onClick: () => {
          setShowModal(false)
        },
      }}
      primaryButtonProps={{
        text: formatMessage({
          id: "modalNRAndAFOPCash.primaryButton",
        }),
        id: "Button_Modal_Back",
        "aria-label":
          formatMessage({
            id: "modalNRAndAFOPCash.primaryButtonWCAG",
          }) || "",
        onClick: () => {
          onCancelModal({
            isAgency,
            navigate,
            formatMessage,
          })
        },
      }}
      dismissButtonProps={{
        "aria-label": "Close",
        display: "none",
      }}
    >
      <Box sx={modalStyles}>
        <ModalHeaderIcon icon={ExclamationMark} />
        <Box as="section">
          <Typography variant="h2" sx={{ color: theme.palette.primary.dark }}>
            {formatMessage({ id: "modalNRAndAFOPCash.title" })}
          </Typography>
          <Typography variant="body2">
            {formatMessage({ id: "modalNRAndAFOPCash.text" })}
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

ModalAFOPCash.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  isAgency: PropTypes.bool.isRequired,
}
