import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  chip: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
    minWidth: "154px",
    height: "36px",
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: "36px",
    "& svg": {
      fill: theme.palette.grey["500"],
    },
    "&:hover, &:focus-within": {
      borderColor: theme.palette.error.main,
      "& p": {
        color: theme.palette.error.main,
      },
      "& svg": {
        fill: theme.palette.error.main,
      },
    },
    "&:active": {
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.main,
      "& p": {
        color: `${theme.palette.common.white} !important`,
      },
      "& svg": {
        fill: `${theme.palette.common.white} !important`,
      },
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    "& p": {
      color: theme.palette.error.main,
    },
    "& svg": {
      fill: theme.palette.error.main,
    },
  },
  icon: {
    fill: theme.palette.grey["700"],
    width: "15px",
    height: "15px",
    marginLeft: "11px",
    animation: `$rotating 1s linear infinite`,
  },
  "@keyframes rotating": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}))

export default useStyles
