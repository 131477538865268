/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { Grid } from "@mui/material"
import { useForm, Controller, useFormState } from "react-hook-form"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import theme from "@bit/vibenitez.copa-components-library.theme"
import SelectRegular from "@bit/vibenitez.copa-components-library.select-regular"
import Option from "@bit/vibenitez.copa-components-library.option"
import Button from "@bit/vibenitez.copa-components-library.button"
import useStyles from "./styles"
import { RefundRequestModal } from "../RefundRequestModal"
import CONSTANTS from "../../../../utils/constants"
import VALIDATIONS from "../../../../utils/validations"
import { DocumentUploader } from "../DocumentUploader"
import { createErrorsMessages } from "../../../../utils/errorsMessages"
import ReasonsToRefund from "../ReasonsToRefund"
import PaymentMethod from "../../../../components/PaymentMethod"
import AFOPForms from "../../../../components/AFOPForms"
import ModalAFOPCash from "../ModalAFOPCash"
import useIsNRPayedMisc from "../../../../hooks/useIsNRPayedMisc"

function byDocType(doc) {
  return this == doc.type
}

const isNonRevenuePayedWithCash = (nonRevenueEligibility, isNonRevenue) =>
  isNonRevenue &&
  parseInt(nonRevenueEligibility, 10) ===
    CONSTANTS.NON_REVENUE_ELIGIBILITY.SHOW_CASH_MODAL

// eslint-disable-next-line react/prop-types
export function RefundRequestForm({
  passengersList,
  triphubData,
  isManualFlow,
  isAgency,
  isNonRefundableFare,
}) {
  const nonRevenueEligibility = triphubData?.trip?.refund?.nonRevenueEligibility
  const isNonRevenue = triphubData?.trip?.revenue?.isNonRevenue

  const [showModal, setShowModal] = useState(
    isNonRevenuePayedWithCash(nonRevenueEligibility, isNonRevenue)
  )
  const styles = useStyles()
  const { formatMessage } = useIntl()
  const errorsMessages = createErrorsMessages(formatMessage)
  const {
    getValues,
    watch,
    setValue,
    clearErrors,
    setError,
    control,
    formState: { errors: formErrors },
  } = useForm({
    mode: "all",
  })

  const { isValid } = useFormState({
    control,
  })

  const realtionOption = watch("requester.relationShipWithPax")

  const [isModalOpened, setIsModalOpened] = useState(false)

  const [country, setCountry] = useState("")

  const [paymentMethod, setPaymentMethod] = useState("")

  const [currentDocs, setCurrentDocs] = useState([])

  const optionReason = watch("reasonToRefund")

  const [isAFOP, setIsAFOP] = useState(false)

  const isNonRevenueOnAutomaticFlow = isNonRevenue && !isManualFlow

  const shouldHideBankInfo = useIsNRPayedMisc()

  const missIllnessDocs =
    optionReason == CONSTANTS.REASON_OPTIONS.TERMINAL_ILLNESS &&
    currentDocs.filter(
      byDocType.bind(CONSTANTS.DOCUMENT_TYPES.TERMINAL_ILLNESS)
    ).length <= 0

  const missDeathDocs =
    optionReason == CONSTANTS.REASON_OPTIONS.DEATH &&
    currentDocs.filter(byDocType.bind(CONSTANTS.DOCUMENT_TYPES.DEATH_DOCUMENTS))
      .length <= 0

  const missIdDocs =
    (isAFOP || isManualFlow) &&
    currentDocs.filter(byDocType.bind(CONSTANTS.DOCUMENT_TYPES.ID_DOCUMENT))
      .length <= 0

  const hasCountryBankSelection =
    isAFOP ||
    (isManualFlow &&
      paymentMethod ==
        formatMessage({
          id: "paymentMethod.TransferCash",
        }))

  const missBankingDocs =
    hasCountryBankSelection &&
    country === "CO" &&
    currentDocs.filter(
      byDocType.bind(CONSTANTS.DOCUMENT_TYPES.BANKING_CERTIFICATE)
    ).length <= 0

  const shouldDisableContinueBtnOnNR = !isValid

  const shouldDisableContinueBtnOnDocs =
    !isValid ||
    missDeathDocs ||
    missIdDocs ||
    missBankingDocs ||
    missIllnessDocs

  const shouldDisableContinueBtn = isNonRevenue
    ? shouldDisableContinueBtnOnNR
    : shouldDisableContinueBtnOnDocs

  const handleDocumentsChange = (file, actionType, documentType) => {
    if (file) {
      if (actionType == "DELETE") {
        setCurrentDocs((docs) =>
          docs.filter((doc) => doc.documentUID != file.documentUID)
        )
      } else {
        setCurrentDocs((docs) => [
          ...docs,
          { ...file, type: documentType, uniqueName: file.documentUID },
        ])
      }
    }
  }

  const cleanDocs = (types = []) => {
    setCurrentDocs((docs) => docs.filter((doc) => !types.includes(doc.type)))
  }

  const cleanBankInfo = () => {
    const bank = getValues("bank")
    if (bank) {
      const bankProperties = Object.keys(bank)
      bankProperties.forEach((prop) => {
        setValue(`bank.${prop}`, typeof bank[prop] == "object" ? {} : "")
      })
      clearErrors("bank")
      cleanDocs([CONSTANTS.DOCUMENT_TYPES.BANKING_CERTIFICATE])
    }
  }

  const [noNumber, setNoNumber] = useState(false)

  useEffect(() => {
    const refund = triphubData?.trip?.refund
    const payment = triphubData?.trip?.paymentInformation

    if (triphubData) {
      if (refund) {
        setIsAFOP(!refund.cardDetails && !refund.anyCreditCard)
        setNoNumber(!refund.cardDetails)
      }
      if (payment) {
        setCountry(payment.countryWherePaid)
      }
    }
  }, [triphubData])

  const renderCountryMethodPayment = () => (
    <section className={styles.AFOPFormContainer}>
      <Typography variant="h3">
        {formatMessage({
          id: "formOfPayment.title",
        })}
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginTop: "20px", marginBottom: "8px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="countryPurchase"
            defaultValue=""
            rules={{ required: true, maxLength: 30 }}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectRegular
                placeholder={formatMessage({
                  id: "paymentMethod.countryPurchasePlaceholder",
                })}
                label={formatMessage({
                  id: "paymentMethod.countryPurchase",
                })}
                onChange={(e) => {
                  setCountry(e.target.value)
                  onChange(e)
                  cleanBankInfo()
                }}
                onBlur={onBlur}
                value={value}
                required
                id="countryPurchase"
                hasErrors={!!formErrors.countryPurchase}
                helperText={
                  formErrors.countryPurchase
                    ? formatMessage({
                        id: "generalLayoutPage.required",
                      })
                    : ""
                }
                data-private
              >
                <Option value="BR" key="1">
                  {formatMessage({
                    id: "countries.BR",
                  })}
                </Option>
                <Option value="MX" key="2">
                  {formatMessage({
                    id: "countries.MX",
                  })}
                </Option>
                <Option value="AR" key="3">
                  {formatMessage({
                    id: "countries.AR",
                  })}
                </Option>
                <Option value="CO" key="4">
                  {formatMessage({
                    id: "countries.CO",
                  })}
                </Option>
                <Option value="CA" key="5">
                  {formatMessage({
                    id: "countries.CA",
                  })}
                </Option>
                <Option value="CL" key="6">
                  {formatMessage({
                    id: "countries.CL",
                  })}
                </Option>
                <Option value="PA" key="7">
                  {formatMessage({
                    id: "countries.PA",
                  })}
                </Option>
                <Option value="EU" key="8">
                  {formatMessage({
                    id: "countries.EU",
                  })}
                </Option>
                <Option value="CU" key="9">
                  {formatMessage({
                    id: "countries.CU",
                  })}
                </Option>
                <Option value="PE" key="10">
                  {formatMessage({
                    id: "countries.PE",
                  })}
                </Option>
                <Option value="NI" key="11">
                  {formatMessage({
                    id: "countries.NI",
                  })}
                </Option>
                <Option value="EC" key="12">
                  {formatMessage({
                    id: "countries.EC",
                  })}
                </Option>
                <Option value="HT" key="13">
                  {formatMessage({
                    id: "countries.HT",
                  })}
                </Option>
                <Option value="GY" key="14">
                  {formatMessage({
                    id: "countries.GY",
                  })}
                </Option>
                <Option value="BB" key="15">
                  {formatMessage({
                    id: "countries.BB",
                  })}
                </Option>
                <Option value="TT" key="16">
                  {formatMessage({
                    id: "countries.TT",
                  })}
                </Option>
                <Option value="OTHER" key="17">
                  {formatMessage({
                    id: "countries.OTHER",
                  })}
                </Option>
              </SelectRegular>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <Controller
            control={control}
            name="paymentMethod"
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectRegular
                placeholder={formatMessage({
                  id: "paymentMethod.PaymentMethodPlaceholder",
                })}
                label={formatMessage({
                  id: "paymentMethod.PaymentMethod",
                })}
                onChange={(e) => {
                  setPaymentMethod(e.target.value)
                  onChange(e)
                  cleanBankInfo()
                }}
                onBlur={onBlur}
                value={value}
                required
                id="bankAccountType"
                hasErrors={!!formErrors.paymentMethod}
                helperText={
                  formErrors.paymentMethod
                    ? formatMessage({
                        id: "generalLayoutPage.required",
                      })
                    : ""
                }
                data-private
              >
                <Option
                  value={formatMessage({
                    id: "paymentMethod.TransferCash",
                  })}
                  key="1"
                >
                  {formatMessage({
                    id: "paymentMethod.TransferCash",
                  })}
                </Option>
                <Option
                  value={formatMessage({
                    id: "paymentMethod.CreditCard",
                  })}
                  key="2"
                >
                  {formatMessage({
                    id: "paymentMethod.CreditCard",
                  })}
                </Option>
                <Option
                  value={formatMessage({
                    id: "paymentMethod.Other",
                  })}
                  key="3"
                >
                  {formatMessage({
                    id: "paymentMethod.Other",
                  })}
                </Option>
              </SelectRegular>
            )}
          />
        </Grid>
      </Grid>
      {country !== "" &&
      paymentMethod ===
        formatMessage({
          id: "paymentMethod.TransferCash",
        }) ? (
        <AFOPForms
          country={country}
          control={control}
          formErrors={formErrors}
          onHandleDocs={handleDocumentsChange}
          isNonRevenueOnAutomaticFlow={isNonRevenueOnAutomaticFlow}
        />
      ) : null}
    </section>
  )
  const validateEmailConfirmation = (type, email) => {
    const hasConfirmationError =
      formErrors.userEmailConfirmation?.type == "confirmation"

    switch (type) {
      case "userEmail": {
        const isEquals = email == getValues("userEmailConfirmation")
        if (hasConfirmationError && isEquals) {
          clearErrors("userEmailConfirmation")
        } else if (
          !hasConfirmationError &&
          !isEquals &&
          getValues("userEmailConfirmation")
        ) {
          setError("userEmailConfirmation", { type: "confirmation" })
        }
        return true
      }
      case "userEmailConfirmation": {
        return getValues("userEmail") ? email == getValues("userEmail") : true
      }
      default: {
        return true
      }
    }
  }

  const validateSecondPhoneNumber = (type, phone) => {
    let ph1
    let ph2
    const hasSecondPhoneCodeSameError =
      formErrors.userSecondPhoneCode?.type == "same"
    const hasSecondPhoneSameError = formErrors.userSecondPhone?.type == "same"

    function updatePhoneErrorStatus(phOne, phTwo) {
      if (
        phOne == phTwo &&
        getValues("userSecondPhoneCode") &&
        getValues("userSecondPhone") &&
        !hasSecondPhoneCodeSameError &&
        !hasSecondPhoneSameError
      ) {
        setError("userSecondPhoneCode", { type: "same" })
        setError("userSecondPhone", { type: "same" })
      } else if (
        phOne != phTwo &&
        (hasSecondPhoneCodeSameError || hasSecondPhoneSameError)
      ) {
        clearErrors("userSecondPhoneCode")
        clearErrors("userSecondPhone")
      }
    }

    switch (type) {
      case "userSecondPhoneCode": {
        ph1 = `${getValues("userFirstPhoneCode")?.replace(
          /\+/g,
          ""
        )}${getValues("userFirstPhone")}`
        ph2 = `${String(phone).replace(/\+/g, "")}${getValues(
          "userSecondPhone"
        )}`
        const isDiff = ph1 != ph2
        if (isDiff && hasSecondPhoneSameError) {
          clearErrors("userSecondPhone")
        }
        return isDiff
      }
      case "userSecondPhone": {
        ph1 = `${getValues("userFirstPhoneCode")?.replace(
          /\+/g,
          ""
        )}${getValues("userFirstPhone")}`
        ph2 = `${getValues("userSecondPhoneCode")?.replace(/\+/g, "")}${phone}`
        const isDiff = ph1 != ph2
        if (isDiff && hasSecondPhoneCodeSameError) {
          clearErrors("userSecondPhoneCode")
        }
        return isDiff
      }
      case "userFirstPhoneCode": {
        ph1 = `${String(phone).replace(/\+/g, "")}${getValues(
          "userFirstPhone"
        )}`
        ph2 = `${getValues("userSecondPhoneCode")?.replace(
          /\+/g,
          ""
        )}${getValues("userSecondPhone")}`
        updatePhoneErrorStatus(ph1, ph2)
        return true
      }
      case "userFirstPhone": {
        ph1 = `${getValues("userFirstPhoneCode")?.replace(/\+/g, "")}${phone}`
        ph2 = `${getValues("userSecondPhoneCode")?.replace(
          /\+/g,
          ""
        )}${getValues("userSecondPhone")}`
        updatePhoneErrorStatus(ph1, ph2)
        return true
      }
      default: {
        return true
      }
    }
  }

  const handleModalToggle = () => setIsModalOpened((state) => !state)

  return (
    <>
      <ModalAFOPCash
        showModal={showModal}
        setShowModal={setShowModal}
        isAgency={isAgency}
      />
      <form className={styles.formSectionContainer}>
        <Typography variant="h3">
          {formatMessage({ id: "additionalInfoPage.titleRequesterSection" })}
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          {formatMessage({ id: "additionalInfoPage.descriptionText" })}
        </Typography>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          style={{ marginTop: "22px", marginBottom: "28px" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={6}
            sx={{
              marginBottom: {
                xs: 1.5,
                md: 0,
              },
            }}
          >
            <Controller
              control={control}
              id="userName"
              name="userName"
              rules={VALIDATIONS.NAME_LASTNAME}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputRegular
                  label={formatMessage({
                    id: "additionalInfoPage.inputName",
                  })}
                  placeholder={formatMessage({
                    id: "additionalInfoPage.helperTextName",
                  })}
                  helperText={
                    formErrors.userName &&
                    errorsMessages[formErrors.userName?.type]
                  }
                  inputProps={{
                    maxLength: 30,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    "aria-label": formatMessage({
                      id: "additionalInfoPageWCAG.inputName",
                    }),
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !formErrors.userName?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey["400"],
                              fontSize: "18px",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  hasErrors={formErrors.userName}
                  id="userName"
                  required
                  value={value}
                  handleChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Controller
              control={control}
              name="userLastname"
              rules={VALIDATIONS.NAME_LASTNAME}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputRegular
                  label={formatMessage({
                    id: "additionalInfoPage.inputLastname",
                  })}
                  placeholder={formatMessage({
                    id: "additionalInfoPage.helperTextLastname",
                  })}
                  helperText={
                    formErrors.userLastname &&
                    errorsMessages[formErrors.userLastname?.type]
                  }
                  inputProps={{
                    maxLength: 30,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    "aria-label": formatMessage({
                      id: "additionalInfoPageWCAG.inputLastname",
                    }),
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !formErrors.userLastname?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey["400"],
                              fontSize: "18px",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  hasErrors={formErrors.userLastname}
                  id="userLastname"
                  required
                  value={value}
                  handleChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          style={{ marginBottom: "28px" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={6}
            sx={{
              marginBottom: {
                xs: 1.5,
                md: 0,
              },
            }}
          >
            <Controller
              control={control}
              name="userEmail"
              rules={{
                ...VALIDATIONS.EMAIL,
                validate: {
                  confirmation: validateEmailConfirmation.bind(
                    null,
                    "userEmail"
                  ),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputRegular
                  label={formatMessage({
                    id: "additionalInfoPage.inputEmail",
                  })}
                  placeholder={formatMessage({
                    id: "additionalInfoPage.helperTextEmail",
                  })}
                  InputProps={{
                    "aria-label": formatMessage({
                      id: "additionalInfoPageWCAG.inputEmail",
                    }),
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !formErrors.userEmail?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey["400"],
                              fontSize: "18px",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    formErrors.userEmail &&
                    errorsMessages[formErrors.userEmail?.type]
                  }
                  hasErrors={formErrors.userEmail}
                  id="userEmail"
                  required
                  value={value}
                  handleChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Controller
              control={control}
              name="userEmailConfirmation"
              rules={{
                ...VALIDATIONS.EMAIL,
                validate: {
                  confirmation: validateEmailConfirmation.bind(
                    null,
                    "userEmailConfirmation"
                  ),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputRegular
                  label={formatMessage({
                    id: "additionalInfoPage.inputConfirmEmail",
                  })}
                  placeholder={formatMessage({
                    id: "additionalInfoPage.helperTextConfirmEmail",
                  })}
                  InputProps={{
                    "aria-label": formatMessage({
                      id: "additionalInfoPageWCAG.inputConfirmEmail",
                    }),
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !formErrors.userEmailConfirmation?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey["400"],
                              fontSize: "18px",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    formErrors.userEmailConfirmation &&
                    errorsMessages[formErrors.userEmailConfirmation?.type]
                  }
                  hasErrors={formErrors.userEmailConfirmation}
                  id="userEmailConfirmation"
                  required
                  value={value}
                  handleChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          sx={{
            marginBottom: isAFOP || isManualFlow ? "28px" : "64px",
          }}
        >
          <Grid
            container
            item
            md={6}
            lg={6}
            spacing={2}
            alignItems="end"
            sx={{
              marginBottom: {
                xs: 1.5,
                md: 0,
              },
            }}
          >
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              sx={{
                "& .MuiFormHelperText-root": { whiteSpace: "nowrap" },
                "& .MuiFormLabel-root": { overflow: "unset" },
              }}
            >
              <Controller
                control={control}
                name="userFirstPhoneCode"
                rules={{
                  ...VALIDATIONS.PHONE_CODE,
                  validate: {
                    same: validateSecondPhoneNumber.bind(
                      null,
                      "userFirstPhoneCode"
                    ),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    label={formatMessage({
                      id: "additionalInfoPage.phone1",
                    })}
                    placeholder={formatMessage({
                      id: "additionalInfoPage.inputPhoneCode",
                    })}
                    helperText={
                      (formErrors.userFirstPhoneCode ||
                        formErrors.userFirstPhone) &&
                      (errorsMessages[formErrors.userFirstPhoneCode?.type] ||
                        errorsMessages[formErrors.userFirstPhone?.type])
                    }
                    inputProps={{
                      maxLength: 4,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      "aria-label": formatMessage({
                        id: "additionalInfoPageWCAG.inputPhoneCode1",
                      }),
                    }}
                    hasErrors={
                      formErrors.userFirstPhoneCode || formErrors.userFirstPhone
                    }
                    id="userFirstPhoneCode"
                    required
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8} xl={8}>
              <Controller
                control={control}
                name="userFirstPhone"
                id="userFirstPhone"
                rules={{
                  ...VALIDATIONS.PHONE,
                  validate: {
                    same: validateSecondPhoneNumber.bind(
                      null,
                      "userFirstPhone"
                    ),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    placeholder={formatMessage({
                      id: "additionalInfoPage.helperTextPhone",
                    })}
                    inputProps={{
                      maxLength: 16,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      "aria-label": formatMessage({
                        id: "additionalInfoPageWCAG.inputPhone1",
                      }),
                      sx: {
                        marginTop: "24px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {value &&
                            !formErrors.userFirstPhone?.type &&
                            !formErrors.userFirstPhoneCode?.type && (
                              <CheckIcon
                                style={{
                                  color: theme.palette.grey["400"],
                                  fontSize: "18px",
                                }}
                              />
                            )}
                        </InputAdornment>
                      ),
                    }}
                    hasErrors={formErrors.userFirstPhone}
                    id="userFirstPhone"
                    required
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item md={6} lg={6} spacing={2} alignItems="end">
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              sx={{
                "& .MuiFormHelperText-root": { whiteSpace: "nowrap" },
                "& .MuiFormLabel-root": { overflow: "unset" },
              }}
            >
              <Controller
                control={control}
                name="userSecondPhoneCode"
                rules={{
                  ...VALIDATIONS.PHONE_CODE_NOT_REQUIRED,
                  validate: {
                    same: validateSecondPhoneNumber.bind(
                      null,
                      "userSecondPhoneCode"
                    ),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    label={formatMessage({
                      id: "additionalInfoPage.phone2",
                    })}
                    placeholder={formatMessage({
                      id: "additionalInfoPage.inputPhoneCode",
                    })}
                    helperText={
                      (formErrors.userSecondPhoneCode ||
                        formErrors.userSecondPhone) &&
                      (errorsMessages[formErrors.userSecondPhoneCode?.type] ||
                        errorsMessages[formErrors.userSecondPhone?.type])
                    }
                    inputProps={{
                      maxLength: 4,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      "aria-label": formatMessage({
                        id: "additionalInfoPageWCAG.inputPhoneCode2",
                      }),
                    }}
                    hasErrors={
                      formErrors.userSecondPhoneCode ||
                      formErrors.userSecondPhone
                    }
                    id="userSecondPhoneCode"
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8} xl={8}>
              <Controller
                control={control}
                name="userSecondPhone"
                rules={{
                  ...VALIDATIONS.PHONE_NOT_REQUIRED,
                  validate: {
                    same: validateSecondPhoneNumber.bind(
                      null,
                      "userSecondPhone"
                    ),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    placeholder={formatMessage({
                      id: "additionalInfoPage.helperTextPhone",
                    })}
                    inputProps={{
                      maxLength: 16,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      "aria-label": formatMessage({
                        id: "additionalInfoPageWCAG.inputPhone2",
                      }),
                      sx: {
                        marginTop: "24px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {value &&
                            !formErrors.userSecondPhone?.type &&
                            !formErrors.userSecondPhoneCode?.type && (
                              <CheckIcon
                                style={{
                                  color: theme.palette.grey["400"],
                                  fontSize: "18px",
                                }}
                              />
                            )}
                        </InputAdornment>
                      ),
                    }}
                    id="userSecondPhone"
                    hasErrors={formErrors.userSecondPhone}
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {(isAFOP || isManualFlow) && (
          <>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={3}
              style={{ marginBottom: "56px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                lg={6}
                md={6}
                sx={{
                  marginBottom: {
                    xs: 1.5,
                    md: 0,
                  },
                }}
              >
                <Controller
                  control={control}
                  name="requester.relationShipWithPax"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <SelectRegular
                      placeholder={formatMessage({
                        id: "additionalInfoPage.relationshipPlaceholder",
                      })}
                      label={formatMessage({
                        id: "additionalInfoPage.relationship",
                      })}
                      helperText={
                        formErrors.requester?.relationShipWithPax &&
                        errorsMessages[
                          formErrors.requester?.relationShipWithPax?.type
                        ]
                      }
                      hasErrors={formErrors.requester?.relationShipWithPax}
                      inputProps={{
                        "aria-label": formatMessage({
                          id: "additionalInfoPageWCAG.relationship",
                        }),
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      required
                      id="relationShipWithPax"
                      data-private
                    >
                      <Option
                        value={formatMessage({
                          id: "additionalInfoPage.relationshipOption2",
                        })}
                        key="Option_Relation_2"
                      >
                        {formatMessage({
                          id: "additionalInfoPage.relationshipOption2",
                        })}
                      </Option>
                      <Option
                        value={formatMessage({
                          id: "additionalInfoPage.relationshipOption1",
                        })}
                        key="Option_Relation_1"
                      >
                        {formatMessage({
                          id: "additionalInfoPage.relationshipOption1",
                        })}
                      </Option>
                      <Option
                        value={formatMessage({
                          id: "additionalInfoPage.relationshipOption3",
                        })}
                        key="Option_Relation_3"
                      >
                        {formatMessage({
                          id: "additionalInfoPage.relationshipOption3",
                        })}
                      </Option>
                    </SelectRegular>
                  )}
                />
              </Grid>
              {realtionOption ===
                formatMessage({
                  id: "additionalInfoPage.relationshipOption3",
                }) && (
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Controller
                    control={control}
                    name="userRelationshipOther"
                    rules={VALIDATIONS.OTHER_RELATIONSHIP}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <InputRegular
                        placeholder={formatMessage({
                          id: "additionalInfoPage.relationshipOtherPlaceholder",
                        })}
                        inputProps={{
                          maxLength: 30,
                          "aria-label": formatMessage({
                            id: "additionalInfoPageWCAG.relationshipOther",
                          }),
                        }}
                        label={formatMessage({
                          id: "additionalInfoPage.relationshipOther",
                        })}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {value &&
                                !formErrors.userRelationshipOther?.type && (
                                  <CheckIcon
                                    style={{
                                      color: theme.palette.grey["400"],
                                      fontSize: "18px",
                                    }}
                                  />
                                )}
                            </InputAdornment>
                          ),
                        }}
                        required
                        helperText={
                          formErrors.userRelationshipOther &&
                          errorsMessages[formErrors.userRelationshipOther?.type]
                        }
                        id="userRelationshipOther"
                        hasErrors={formErrors.userRelationshipOther}
                        value={value}
                        handleChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            {!isNonRevenueOnAutomaticFlow && (
              <DocumentUploader
                label={formatMessage({
                  id: "additionalInfoPage.requesterIdUploadDocumentsTitle",
                })}
                required
                id="idDocuments"
                maxFilesCount={2}
                maxFileSize={4000000}
                enabledFormats={[".jpg", ".jpeg", ".pdf", ".png"]}
                handleDocumentsChange={(file, actionType) =>
                  handleDocumentsChange(
                    file,
                    actionType,
                    CONSTANTS.DOCUMENT_TYPES.ID_DOCUMENT
                  )
                }
                className={styles.idDocumentsUploader}
              />
            )}
          </>
        )}
        {isAFOP && !shouldHideBankInfo && (
          <section className={styles.AFOPFormContainer}>
            <Typography variant="h3">
              {formatMessage({
                id: "formOfPayment.title",
              })}
            </Typography>
            <AFOPForms
              country={country}
              control={control}
              formErrors={formErrors}
              onHandleDocs={handleDocumentsChange}
              isNonRevenueOnAutomaticFlow={isNonRevenueOnAutomaticFlow}
            />
          </section>
        )}
        {isManualFlow && renderCountryMethodPayment()}
        {!isAFOP && !isManualFlow && (
          <PaymentMethod
            noNumber={noNumber}
            cardDetails={triphubData?.trip?.refund?.cardDetails}
          />
        )}
        {!isNonRevenueOnAutomaticFlow && (
          <ReasonsToRefund
            control={control}
            formErrors={formErrors}
            handleDocs={handleDocumentsChange}
            cleanOptionReasonDocs={() =>
              cleanDocs([
                CONSTANTS.DOCUMENT_TYPES.TERMINAL_ILLNESS,
                CONSTANTS.DOCUMENT_TYPES.DEATH_DOCUMENTS,
              ])
            }
            optionReason={optionReason}
            isNonRefundableFare={isNonRefundableFare}
          />
        )}

        <Grid container flex justifyContent="end">
          <Button
            id="Button_Create_Refund_Form"
            sx={{
              width: {
                md: "486px",
                sm: "560px",
                xs: "100%",
              },
              margin: {
                md: "0 1.5em 0 0",
                xs: "0 0 0 0",
              },
            }}
            aria-label={formatMessage({
              id: "additionalInfoPageWCAG.cta",
            })}
            disabled={shouldDisableContinueBtn}
            onClick={handleModalToggle}
          >
            {formatMessage({
              id: "additionalInfoPage.button",
            })}
          </Button>
        </Grid>
      </form>
      <RefundRequestModal
        open={isModalOpened}
        handleClose={handleModalToggle}
        isAgency={isAgency}
        passengers={passengersList}
        triphubData={triphubData}
        getValues={watch}
        isManualFlow={isManualFlow}
        isAFOP={isAFOP}
        documents={[...currentDocs]}
      />
    </>
  )
}
