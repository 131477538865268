import { Box } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

export function ResponsiveContainer({
  id,
  children,
  marginTop,
  marginBottom,
  ...props
}) {
  const styles = {
    padding: {
      xs: "0px 16px 0px 16px",
      sm: "0px 104px 0px 104px",
      md: "0px 169px 0px 169px",
      lg: "0px 212px 0px 212px",
    },
    marginTop,
    marginBottom,
  }

  return (
    <Box id={id} sx={styles} {...props}>
      {children}
    </Box>
  )
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      xs: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
    }),
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      xs: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
    }),
  ]),
}

ResponsiveContainer.defaultProps = {
  id: "",
  marginTop: {},
  marginBottom: {},
}
