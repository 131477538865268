import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Hyperlink } from "../Hyperlink/Hyperlink"
import { sendGACustomEvent } from "../../utils/functions"

export function HyperlinkWithGA({ action, ...props }) {
  const link = useRef()
  const sendGA = () => {
    sendGACustomEvent(action)
  }
  useEffect(() => {
    const currentLink = link.current || {}
    currentLink.addEventListener("click", sendGA)
    return () => {
      currentLink.removeEventListener("click", sendGA)
    }
  }, [])
  return <Hyperlink {...props} ref={link} />
}

HyperlinkWithGA.propTypes = {
  action: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  className: PropTypes.string,
}

HyperlinkWithGA.defaultProps = {
  className: "",
}
