export default {
  ONLY_NUMBERS: /^([0-9])*$/,
  PHONE_CODE: /^(\+?\d{1,3}|\d{1,3}| )$/,
  IS_NUMERIC: /^[0-9]*$/,
  E_TICKET: /^(230217|230218|230219)/,
  ONLY_LETTERS: /^[A-Za-z\s]+$/,
  ID_NUMBER: /^[-A-Za-z0-9]+$/,
  ONLY_LETTERS_ACCENTS:
    /^(?!.*\s{2,})(?!^ )[A-Za-zÑñÁáÉéÍíÓóÚúÀàÈèÌìÒòÙùÄäËëÏïÖöÜüÂâÊêÎîÔôÛûĀāĒēĪīŌōŪūÃãÕõÇçĘęĮįÆæŒœÅåĖėØø\s]+[A-Za-zÑñÁáÉéÍíÓóÚúÀàÈèÌìÒòÙùÄäËëÏïÖöÜüÂâÊêÎîÔôÛûĀāĒēĪīŌōŪūÃãÕõÇçĘęĮįÆæŒœÅåĖėØø]$/,
  ALPHANUMERIC: /^[A-Za-z0-9]+$/,
  ALPHANUMERIC_WITH_SPACE: /^[A-Za-z0-9\s]+$/,
  ALPHANUMERIC_SPACE_ACCENTS:
    /^(?!.*\s{2,})(?!^ )[0-9A-Za-zÑñÁáÉéÍíÓóÚúÀàÈèÌìÒòÙùÄäËëÏïÖöÜüÂâÊêÎîÔôÛûĀāĒēĪīŌōŪūÃãÕõÇçĘęĮįÆæŒœÅåĖėØø\s]+[0-9A-Za-zÑñÁáÉéÍíÓóÚúÀàÈèÌìÒòÙùÄäËëÏïÖöÜüÂâÊêÎîÔôÛûĀāĒēĪīŌōŪūÃãÕõÇçĘęĮįÆæŒœÅåĖėØø]$/,
  VALID_PNR_TICKET: /^[- A-Za-z0-9]+$/,
  VALID_CASE_NUMBER: /^RFD-[A-Za-z]{2,3}[0-9]+$/,
  ISVALIDEMAIL:
    // eslint-disable-next-line no-control-regex
    /^(([^<>()[\]/.,;:?¿¡·~|°°##¬\s@!$%&=*+`^'ñáéíóúü´´"]+(\.[^<>()[\].,;:\s@"]+)*))@(([^<>(){}[\].,;:\s@!$%&=*-,+`^'ñáéíóúü´´"]+\.)+[^<>(){}[\]/.,;:?¿¡·_~|°°##¬\d\s@!$%&=*---+`^'ñ´´"]{2,})$/,

  /**
   * OPEN_FIELD allows:
   *
   * Letters and Numbers
   * Special Character: - . , ; : " ¨ ´ - / ()
   * Brazilian, European and Spanish ascents
   * Spaces and Jump Lines
   *
   * */
  OPEN_FIELD:
    /^[A-Za-z\d.,;:"¨´\-/()ÑñÁáÉéÍíÓóÚúÀàÈèÌìÒòÙùÄäËëÏïÖöÜüÂâÊêÎîÔôÛûĀāĒēĪīŌōŪūÃãÕõÇçĘęĮįÆæŒœÅåĖėØø\s]+$/,
  ALPHANUMERIC_WITHOUT_SPECIALS_AND_SPACES_FIELD_REGEX: /^[a-zA-Z0-9-Z]+$/,
  ALPHANUMERIC_WITHOUT_SPECIALS_FIELD_REGEX: /^[a-zA-Z]+$/,
}
