import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "32px !important",
    lineHeight: "40px !important",
    color: theme.palette.primary.main,
    marginTop: "62px !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px !important",
      fontSize: "28px !important",
    },
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "SuisseIntl",
    marginTop: "24px",
    maxWidth: "510px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "32px",
    },
  },
  container: {
    height: "100vh",
    margin: "auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  ctaContainer: {
    marginTop: "81px !important",
    width: "100%",
    "& button": {
      padding: "16px, 24px, 16px, 24px",
      borderRadius: "56px",
      width: "341px",
      height: "56px",
      [theme.breakpoints.down("md")]: {
        width: "234px",
        height: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "375px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "74px !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "58px !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "66px !important",
    },
  },
}))

export default useStyles
