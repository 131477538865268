import constants from "./constants"
import REGEX from "./regex"
// import constants from "./constants"

export const goToCOPA = (lang) => {
  window.location.href = getLinkToCopa(lang)
}

export const getLinkToCopa = (lang) => `https://www.copaair.com/${lang}-gs/`

export const persistParameter = (key, value) => {
  localStorage.setItem(key, value)
}

export const getPersistedParameter = (key) => localStorage.getItem(key)

export const getCodeType = (code) => {
  if (String(code).match(REGEX.E_TICKET)) return "eTicket"
  if (String(code).match(REGEX.VALID_CASE_NUMBER)) return "caseNumber"
  if (String(code).match(REGEX.VALID_PNR_TICKET)) return "pnr"
  return "None"
}

export const cleanUpSpecialChars = (text) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z]/g, "")

export const sendAnalyticsEvent = (event, params) =>
  window.dataLayer?.push({
    event,
    ...params,
  })

export const sendGAPageView = (path, language, pnr) =>
  sendAnalyticsEvent(constants.ANALYTICS_EVENTS.PAGE_VIEW, {
    path,
    language,
    pnr,
  })

export const sendGACustomEvent = (action, params = {}) =>
  sendAnalyticsEvent(constants.ANALYTICS_EVENTS.CUSTOM_EVENT, {
    action,
    category: "Single Refund Form",
    ...params,
  })
