import React from "react"
import { Container, Dialog, Grid, SvgIcon } from "@mui/material"
import PropTypes from "prop-types"
import theme from "@bit/vibenitez.copa-components-library.theme"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import { ReactComponent as CopaLogo } from "../../assets/images/copaairlines-logo-blue.svg"
import AnimationDisplayer from "../AnimationDisplayer/AnimationDisplayer"
import Spinner from "../Spinner/Spinner"
import * as AnimationData from "./planeloading.json"

import useStyles from "./styles"

function Interstitial({ loadingText, wcagDescription }) {
  const classes = useStyles()
  return (
    <Dialog
      data-cy="interstitial-backdrop"
      className={classes.root}
      open
      fullScreen
    >
      <Container
        data-cy="interstitial-container"
        className={classes.interstitial}
        container
        justify="center"
      >
        <div
          data-cy="interstitial-animationDisplayer"
          className={classes.animationContainer}
        >
          <AnimationDisplayer
            config={AnimationData.default}
            wcagDescription={wcagDescription}
          />
        </div>
        <Grid
          data-cy="interstitial-loadingContainer"
          justify="center"
          sx={{
            flexDirection: "column",
            marginBottom: "233px",
            marginTop: "115px",
          }}
        >
          <Grid
            item
            xs={12}
            container
            justify="center"
            sx={{
              justifyContent: "center",
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <Spinner color={theme.palette.grey["500"]} />
          </Grid>
          {loadingText && (
            <Grid
              item
              xs={12}
              container
              justify="center"
              sx={{
                justifyContent: "center",
              }}
            >
              <Typography
                data-cy="interstitial-loadingText"
                variant="body2"
                className={classes.loading}
              >
                {loadingText}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container justify="center" className={classes.logo}>
          <SvgIcon
            viewBox="0 0 177 30"
            sx={{
              width: "188.8px",
              height: "32px",
            }}
          >
            <CopaLogo />
          </SvgIcon>
        </Grid>
      </Container>
    </Dialog>
  )
}

Interstitial.propTypes = {
  loadingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  wcagDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
}

Interstitial.defaultProps = {
  loadingText: "",
  wcagDescription: "",
}

export default Interstitial
