import React from "react"
// import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { SeoWrapper } from "../SeoWrapper"

export function EnhancedRoute({
  component,
  ariaText,
  ariaTextProps,
  idContainer,
  pageTitle,
  metaTypeSEO,
  keywords,
  ...rest
}) {
  const { formatMessage } = useIntl()

  return (
    <SeoWrapper
      ariaText={ariaText && formatMessage({ id: ariaText })}
      id={idContainer}
      title="Copa Airlines"
      keywords={[]}
      metaTypeSEO={formatMessage({ id: "pageHeader.description" })}
      {...rest}
    >
      {React.cloneElement(component)}
    </SeoWrapper>
  )
}

EnhancedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  ariaText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ariaTextProps: PropTypes.objectOf(PropTypes.any),
  idContainer: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  metaTypeSEO: PropTypes.string.isRequired,
}

EnhancedRoute.defaultProps = {
  ariaTextProps: {},
}
