import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import useStyles from "./styles"
import { ArrowLeft } from "../../assets/Icons/ArrowLeft"

export function Header({ title, goBackSteps }) {
  const styles = useStyles()

  return (
    <header id="header-container" className={styles.header}>
      <Link to={-1 * goBackSteps} className={styles.headerBackButton}>
        <ArrowLeft id="back-icon" />
      </Link>

      <section className={styles.headerTextContainer}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: { xs: 700 },
            lineHeight: { xs: "24px", md: "40px" },
            fontSize: {
              xs: "18px",
              sm: "20px",
              md: "32px",
            },
          }}
        >
          {title}
        </Typography>
      </section>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  goBackSteps: PropTypes.number,
}

Header.defaultProps = {
  goBackSteps: 1,
}
