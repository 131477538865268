import React from "react"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import Alert from "@bit/vibenitez.copa-components-library.alert"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import theme from "@bit/vibenitez.copa-components-library.theme/index"
import { sendGACustomEvent } from "../../../../utils/functions"

export function AlertMessage({
  message,
  show,
  actionlink,
  actionText,
  wcagDescription,
}) {
  const navigate = useNavigate()
  function handleLinkClick() {
    sendGACustomEvent("ETKT error check status")
    navigate(actionlink)
  }

  return (
    <Alert
      sx={{
        marginBottom: { sm: "36px", xs: "32px" },
        marginTop: { lg: "-28px", md: "-12px", xs: "-4px" },
      }}
      show={show}
      variant="error"
      aria-label={wcagDescription}
      aria-live="assertive"
    >
      {message}
      {actionlink && actionText && (
        <button
          type="button"
          style={{
            cursor: "pointer",
            background: "transparent",
            border: "none",
            padding: 0,
          }}
          onClick={handleLinkClick}
        >
          <Typography
            sx={{
              color: theme.palette.common.white,
              fontSize: "14px",
              fontWeight: 500,
              textDecoration: "underline",
            }}
          >
            {actionText}
          </Typography>
        </button>
      )}
    </Alert>
  )
}

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  actionlink: PropTypes.string,
  actionText: PropTypes.string,
  wcagDescription: PropTypes.string,
}

AlertMessage.defaultProps = {
  actionlink: "",
  actionText: "",
  wcagDescription: "",
}
