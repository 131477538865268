import React from "react"

export function SpinningLoader({ ...props }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id=" Icon/Spinning Loader">
        <path
          id="Vector"
          d="M6.18759 12.2051C6.18759 8.68908 9.13317 5.82955 12.7536 5.82955C14.3823 5.82955 15.8731 6.41165 17.0205 7.37085C17.6068 6.76219 18.0235 6.38616 18.5474 5.84548C17.003 4.51769 14.9784 3.70508 12.7536 3.70508C7.91906 3.70508 4 7.51 4 12.2051C4 16.8991 7.91906 20.7051 12.7536 20.7051C15.7484 20.7051 18.3921 19.2424 19.9716 17.0128C19.9803 16.9989 20 16.9713 20 16.9713L18.2707 15.6467C18.2707 15.6467 18.2532 15.6733 18.2445 15.686C17.0708 17.426 15.0506 18.5806 12.7536 18.5806C9.13317 18.5806 6.18759 15.72 6.18759 12.2051Z"
        />
      </g>
    </svg>
  )
}
