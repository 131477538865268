/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react"
import PropTypes from "prop-types"
import ErrorPage from "../../pages/Error"

class ErrorWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return <ErrorPage />
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }
}

ErrorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorWrapper
