import { makeStyles } from "@mui/styles"

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  submitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "auto",
    columnGap: "24px",
    "& button": {
      gridColumn: " 2 / 3",
      margin: "82px 0 90px",
      width: "99%",
    },
    [theme.breakpoints.down("lg")]: {
      "& button": {
        margin: "68px 0 90px",
      },
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      "& button": {
        gridColumn: " 1 / 2",
        margin: "62px 0 90px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        margin: "54px 0 90px",
      },
    },
  },
  nextContainer: {
    "& button": {
      borderRadius: "4px",
      marginTop: "44px",
      [theme.breakpoints.down("lg")]: {
        marginTop: "36px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: "28px",
      },
    },
  },
}))

export default useStyles
