import React from "react"
import PropTypes from "prop-types"
import {useIntl} from "react-intl"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import CreditCardDetail from "../../pages/CreateRequestForm/components/CreditCardDetail"
import {CreditCardDisclaimer} from "../../pages/CreateRequestForm/components/CreditCardDisclaimer"
import useStyles from "./styles"

function PaymentMethod({noNumber, cardDetails}) {
    const styles = useStyles()
    const {formatMessage} = useIntl()
    const marginStyle = {marginTop: "16px"}
    if (noNumber) {
        marginStyle.marginBottom = "34px"
    }

    return (
        <section className={styles.renderCreditCardContainer}>
            <Typography variant="h3">
                {formatMessage({
                    id: "additionalInfoPage.titleFOPSection",
                })}
            </Typography>
            <Typography style={marginStyle}>
                {formatMessage({
                    id: "additionalInfoPage.FOPDescription",
                })}
            </Typography>
            {!noNumber && <CreditCardDetail creditCardDetails={cardDetails}/>}
            <CreditCardDisclaimer
                message={formatMessage({
                    id: "additionalInfoPage.creditCardDisclaimer",
                })}
            />
        </section>
    )
}

PaymentMethod.propTypes = {
    noNumber: PropTypes.bool.isRequired,
    cardDetails: PropTypes.string,
}

PaymentMethod.defaultProps = {
    cardDetails: "",
}

export default PaymentMethod
