import React, { useEffect, useRef } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

export function SeoWrapper({
  children,
  ariaText,
  metaTypeSEO,
  id,
  language,
  title,
  keywords,
  meta,
}) {
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.focus()
  }, [])

  return (
    <>
      <Helmet
        htmlAttributes={{
          language,
        }}
        title={title}
        // titleTemplate="&s | Copa Airlines"
        meta={[
          {
            name: `description`,
            content: metaTypeSEO,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaTypeSEO,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : []
          )
          .concat(meta)}
      />
      <div id={id} aria-label={ariaText} ref={containerRef} role="contentinfo">
        {children}
      </div>
    </>
  )
}

SeoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  ariaText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  title: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.shape),
  keywords: PropTypes.arrayOf(PropTypes.string),
  metaTypeSEO: PropTypes.string,
}

SeoWrapper.defaultProps = {
  language: `en`,
  meta: [],
  keywords: ["flights", "copaAir"],
  metaTypeSEO: null,
}
