import React from "react"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import headerStyles from "./styles"

export function ModalHeaderIcon({ icon }) {
  return (
    <Box as="header" sx={headerStyles}>
      <div>
        <img src={icon} alt="" />
      </div>
    </Box>
  )
}

ModalHeaderIcon.propTypes = {
  icon: PropTypes.string.isRequired,
}
