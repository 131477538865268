import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = {
  title: {
    display: "flex",
    alignItems: "center",
    marginTop: {
      xs: "32px",
    },
    marginBottom: {
      xs: "32px",
    },
  },
  titleFont: {
    color: theme.palette.primary.dark,
    fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
    fontFamily: "Gilroy, sans-serif",
    fontWeight: 700,
    lineHeight: { xs: "24px", sm: "32px" },
  },
  caseStatus: {
    border: "1px solid #DBDAD8",
    borderRadius: "20px",
    padding: {
      xs: "20px",
      md: "40px",
    },
    paddingBottom: {
      md: "24px",
    },
  },
  passengersContainer: {
    marginTop: {
      xs: "56px",
    },
  },
  passengers: {
    wordBreak: "break-all",
    marginBottom: {
      xs: "32px",
    },
  },
  passengersXs: {
    flexShrink: {
      xs: "unset",
    },
    maxWidth: {
      xs: "none",
    },
    flexBasis: {
      xs: "unset",
    },
    paddingRight: {
      xs: "16px",
      md: "16px",
    },
    marginBottom: {
      xs: "32px",
    },
  },
  description: {
    marginTop: {
      xs: "-24px",
    },
    marginBottom: {
      xs: "49px",
      sm: "32px",
    },
  },
}

export default useStyles
