import { sendGACustomEvent } from "../../utils/functions"
import REGEX from "../../utils/regex"

export const isAgencyPNR = ({ tripHubResponse, isAgency, navigate }) => {
  if (!tripHubResponse || isAgency) return
  const isAbleToRefund = tripHubResponse.trip?.refund?.isAbleToRefund // It Has remarks
  const indirectChannel = tripHubResponse.trip?.isIndirectChannel // It is from agency
  const hasToRedirect =
    indirectChannel && typeof isAbleToRefund == "boolean" && !isAbleToRefund

  if (tripHubResponse && hasToRedirect) {
    sendGACustomEvent("Agency request")
    navigate("/request-to-agency")
  }
}

export const isRequestedByETicket = ({
  tripHubResponse,
  setWasRequested,
  dispatch,
  requestCode,
}) => {
  if (REGEX.E_TICKET.test(requestCode) && tripHubResponse?.trip?.pnr) {
    setWasRequested(true)
    dispatch({
      requestCode: tripHubResponse.trip.pnr,
    })
  }
}
