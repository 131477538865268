import { useEffect, useState } from "react"
import { getTripInfo } from "../services/triphub/triphub.service"
import {
  createRefund,
  getRefund,
  uploadFiles,
} from "../services/SRF/srf.service"
import { cleanUpSpecialChars } from "../utils/functions"

export const useAPICall = (apiCallFunc, loadingInitialState = false) => {
  const [error, setError] = useState(null)
  const [responseData, setResponseData] = useState(null)
  const [isLoading, setIsLoading] = useState(loadingInitialState)

  const request = async (...args) => {
    setIsLoading(true)
    try {
      const result = await apiCallFunc(...args)
      setResponseData(result.data)
      setError(null)
    } catch (errorObj) {
      setError(errorObj || "Unexpected Error!")
      setResponseData(null)
    } finally {
      setIsLoading(false)
    }
  }

  return { request, error, responseData, isLoading }
}

export const useGetTripHubInfo = (params) => {
  const { requestCode, userLastName, skip, loadingInitialState } = params
  const resp = useAPICall(getTripInfo, loadingInitialState)

  useEffect(() => {
    if (!skip && requestCode && userLastName) {
      resp.request(requestCode, userLastName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCode, userLastName, skip])

  return resp
}

export const useGetRefund = (params) => {
  const resp = useAPICall(getRefund)
  const { caseNumber, eTicket, pnr, lastname, skip } = params
  useEffect(() => {
    if (!skip && (caseNumber || eTicket || pnr)) {
      resp.request({
        caseNumber,
        eTicket,
        pnr,
        lastName: lastname ? cleanUpSpecialChars(lastname) : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseNumber, eTicket, pnr, lastname, skip])
  return resp
}

export const useUploadDocument = () => useAPICall(uploadFiles)
export const useCreateRefund = () => useAPICall(createRefund)
