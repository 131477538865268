import axios from "axios"

const url = process.env.REACT_APP_SRF_API_URL

const instance = axios.create({
  withCredentials: true,
})

const headers = {
  "Accept-Language": "en-US",
  "Content-Type": "application/json",
}

export const getTripInfo = (pnrOrTicket, surname) =>
  instance.post(
    `${url}triphub/load-trip`,
    { pnrOrTicket, surname, getAdditionalInfo: false },
    { headers }
  )
