import theme from "@bit/vibenitez.copa-components-library.theme"

const modalStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  "& h2": {
    marginBottom: "24px",
    textAlign: "center",
    color: theme.palette.primary.dark,
  },
  "& .first-bullet": {
    marginBottom: "8px",
  },
  "& .description-end": {
    marginBottom: { sm: "24px", md: 0 },
  },
  "& ul": {
    paddingLeft: "16px",
  },
}

export default modalStyles
