import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import { CssBaseline, ThemeProvider } from "@mui/material"
import "./index.css"
import { App } from "./App"
import { theme } from "./theme/theme"
import { GeneralContextProvider } from "./contexts/GeneralContextProvider"

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <GeneralContextProvider>
          <CssBaseline />
          <App />
        </GeneralContextProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)
