import React from "react"

export default function AlertIcon({ ...props }) {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Alert Icon</title>
      <circle cx="45" cy="45" r="45" fill="#EFF5FF" />
      <path
        d="M42.1609 17.6186C43.7005 14.952 47.5495 14.952 49.0891 17.6187L71.9305 57.1812C73.4701 59.8478 71.5456 63.1812 68.4664 63.1812H22.7836C19.7044 63.1812 17.7799 59.8478 19.3195 57.1812L42.1609 17.6186Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.7343 65.6811H24.5156C18.7421 65.6811 15.1336 59.4312 18.0204 54.4311L39.1298 17.8687C42.0165 12.8687 49.2334 12.8686 52.1201 17.8686L73.2295 54.4311C76.1163 59.4311 72.5078 65.6811 66.7343 65.6811ZM66.7343 63.1811H24.5156C20.6666 63.1811 18.261 59.0145 20.1855 55.6811L41.2948 19.1187C43.2193 15.7853 48.0306 15.7853 49.9551 19.1186L71.0644 55.6811C72.9889 59.0145 70.5833 63.1811 66.7343 63.1811Z"
        fill="#0032A0"
      />
      <path
        d="M47.5 53.4937C47.5 54.5292 46.6605 55.3687 45.625 55.3687C44.5895 55.3687 43.75 54.5292 43.75 53.4937C43.75 52.4581 44.5895 51.6187 45.625 51.6187C46.6605 51.6187 47.5 52.4581 47.5 53.4937Z"
        fill="#00A3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.375 53.4937C49.375 55.5647 47.6961 57.2437 45.625 57.2437C43.5539 57.2437 41.875 55.5647 41.875 53.4937C41.875 51.4226 43.5539 49.7437 45.625 49.7437C47.6961 49.7437 49.375 51.4226 49.375 53.4937ZM45.625 55.3687C46.6605 55.3687 47.5 54.5292 47.5 53.4937C47.5 52.4581 46.6605 51.6187 45.625 51.6187C44.5895 51.6187 43.75 52.4581 43.75 53.4937C43.75 54.5292 44.5895 55.3687 45.625 55.3687Z"
        fill="#0032A0"
      />
      <path
        d="M42.686 32.2381C42.5853 30.5461 43.9303 29.1187 45.6253 29.1187C47.3203 29.1187 48.6654 30.5461 48.5646 32.2381L47.8324 44.539C47.7629 45.707 46.7954 46.6187 45.6253 46.6187C44.4552 46.6187 43.4877 45.707 43.4182 44.539L42.686 32.2381Z"
        fill="#00A3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7502 32.2938C41.6174 30.0631 43.3906 28.1812 45.6253 28.1812C47.86 28.1812 49.6333 30.0631 49.5005 32.2938L48.7683 44.5947C48.6693 46.258 47.2915 47.5562 45.6253 47.5562C43.9591 47.5562 42.5814 46.258 42.4824 44.5947L41.7502 32.2938ZM45.6253 30.0562C44.47 30.0562 43.5532 31.0291 43.6219 32.1824L44.354 44.4833C44.3941 45.1561 44.9514 45.6812 45.6253 45.6812C46.2993 45.6812 46.8566 45.1561 46.8966 44.4833L47.6288 32.1824C47.6974 31.0291 46.7807 30.0562 45.6253 30.0562Z"
        fill="#0032A0"
      />
    </svg>
  )
}
