import React from "react"
import PropTypes from "prop-types"

import useStyles from "./styles"

export const Hyperlink = React.forwardRef(
  ({ linkUrl = "", linkText = "", className = "", ...props }, ref) => {
    const styles = useStyles()
    const defaultClass = className || styles.hyperlink
    return (
      <a {...props} className={defaultClass} href={linkUrl} ref={ref}>
        {linkText}
      </a>
    )
  }
)

Hyperlink.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Hyperlink.defaultProps = {
  className: "",
}
