import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = {
  headingTitle: {
    color: theme.palette.grey["600"],
    fontFamily: "SuisseIntl",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
  },
  headingText: {
    color: theme.palette.grey["800"],
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },
}

export default useStyles
