const styles = {
  title: {
    fontFamily: { xs: "SuisseIntl", sm: "Gilroy" },
    fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
    color: "#002880",
    fontWeight: { xs: 500, sm: 700 },
    lineHeight: { xs: "24px", sm: "32px", lg: "40px" },
    letterSpacing: {
      xs: "-0.63px",
      sm: "-0.3px",
      md: "-0.4px",
      letterSpacing: "-0.5px",
    },
  },
  subtitle: {
    marginTop: { xs: "24px" },
    fontFamily: "Gilroy",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  bulletText: {
    fontWeight: 400,
    lineHeight: "24px",
    color: "#666666",
  },
}

export default styles
