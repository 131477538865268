import { makeStyles } from "@mui/styles"
import theme from "@bit/vibenitez.copa-components-library.theme"

const useStyles = makeStyles(() => ({
  passengerCard: {
    maxWidth: "238px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "216px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "272px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "182px",
    },
    wordBreak: "break-all",
    minHeight: "104px",
    border: "1px solid #CCCCCC",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em 1em 0 0",
    padding: "16px",
    borderRadius: "5px",
  },
}))

export default useStyles
