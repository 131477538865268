import { Box } from "@mui/material"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import React from "react"
import PropTypes from "prop-types"
import { ArrowRight } from "../../../../assets/Icons/ArrowRight"
import styles from "./styles"

export function OtherRefundOption({ subtitle, description, wcag, href }) {
  return (
    <Box
      component="a"
      role="button"
      href={href}
      target="_blank"
      aria-label={wcag}
      sx={styles.container}
    >
      <div>
        <Typography variant="body1" sx={styles.subtitle}>
          {subtitle}
        </Typography>

        <Typography variant="h3" sx={styles.description}>
          {description}
        </Typography>
      </div>
      <Box component="div" sx={styles.icon}>
        <ArrowRight />
      </Box>
    </Box>
  )
}

OtherRefundOption.propTypes = {
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  wcag: PropTypes.string,
  href: PropTypes.string,
}

OtherRefundOption.defaultProps = {
  wcag: "",
  href: "",
}
