import { makeStyles } from "@mui/styles"
import palette from "@bit/vibenitez.copa-components-library.theme/palette"

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10000,
    backgroundColor: "palette.common.white",
  },
  interstitial: {
    maxWidth: 588,
    [theme.breakpoints.down("xs")]: {
      padding: "32px 16px",
    },
    display: "flex",
    justifyContent: "center",
    padding: "32px 0",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
  },
  logo: {
    justifyContent: "center",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "red",
    marginBottom: 233,
  },
  loading: {
    color: palette.grey["600"],
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
    marginLeft: "45%",
  },
  imageBanner: {
    marginRight: 40,
    [theme.breakpoints.down("md")]: {
      marginRight: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      display: "flex",
      justifyContent: "center",
      marginBottom: 16,
    },
  },
  imageBannerContent: {
    width: 128,
    [theme.breakpoints.down("md")]: {
      width: 88,
      height: 88,
    },
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 80,
    },
  },
  animationContainer: {
    maxWidth: "569px",
    margin: "0 auto",
    padding: "0 69px",
    [theme.breakpoints.down("md")]: {
      padding: "0 120px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
}))

export default useStyles
