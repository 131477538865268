import { makeStyles } from "@mui/styles"

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  title: {
    overflowWrap: "break-word",
    color: theme.palette.primary.main,
    marginTop: "47px !important",
    marginBottom: "60px !important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "52px !important",
      marginBottom: "44px !important",
      maxWidth: "50%",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "36px !important",
    },
  },
  fieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "auto",
    columnGap: "24px",
    rowGap: "28px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
}))

export default useStyles
