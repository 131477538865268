import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  hyperlink: {
    textDecoration: "none",
    color: "rgb(0, 95, 254)",
    fontFamily: "Gilroy, sans-serif",
    fontWeight: "600",
  },
}))

export default useStyles
