const styles = {
  pnrRetrieveContainer: {
    border: "1px solid #DBDAD8",
    borderRadius: "20px",
    padding: {
      xs: "34px 22px 34px 22px",
      sm: "44px 32px 34px 32px",
      md: "32px 42px 40px 44px",
      lg: "36px 36px 44px 36px",
    },
  },
  header: {
    display: {
      xs: "none",
      sm: "none",
      md: "block",
      lg: "block",
    },
  },
  formContainer: {
    marginTop: {
      md: "28px",
    },
    marginBottom: {
      xs: "28px",
      sm: "38px",
      md: "50px",
    },
  },
  title: {
    fontFamily: "Gilroy",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "0px",
    textAlign: "left",
    marginBottom: "30px",
  },
  firstInput: {
    width: { xs: "100%", lg: "298px" },
    marginBottom: {
      xs: "46px",
      md: "0px",
    },
    paddingRight: {
      md: "22.9px",
      lg: "0px",
    },
  },
  secondInput: {
    width: { xs: "100%", lg: "298px" },
    marginBottom: {
      xs: "62px",
      md: "0px",
    },
    paddingRight: {
      md: "23.7px",
      lg: "0px",
    },
  },
  inputRegularLabelCustomFont: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    whiteSpace: "unset",
  },
  button: {
    height: "40px",
    width: { xs: "100%", lg: "294px" },
    borderRadius: "40px",
    marginBottom: "20px",
    // styleName: Label/ButtonLabel/Small,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "center",
  },
}

export default styles
