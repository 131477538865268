import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useIntl, FormattedMessage } from "react-intl"
import { Grid } from "@mui/material"
import Button from "@bit/vibenitez.copa-components-library.button"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import { useSearchParams } from "react-router-dom"
import theme from "@bit/vibenitez.copa-components-library.theme"
import ResponsiveContainer from "../../components/ResponsiveContainer"
import { Header } from "../../components/Header"
import Interstitial from "../../components/Interstitial/interstitial"
import CaseStatus from "./components/CaseStatus"
import useStyle from "./style"
import { useAppSelectGeneralInfo } from "../../hooks/store-hooks"
import { useStoreRefund } from "../../hooks/usePersistData"
import PNRHeading from "../../components/PNRHeading"
import { Hyperlink } from "../../components/Hyperlink/Hyperlink"
import constants from "../../utils/constants"
import { getLinkToCopa, sendGACustomEvent } from "../../utils/functions"
import { HyperlinkWithGA } from "../../components/HyperlinkWithGA"
import { identifyUser } from "../../utils/logs"

export function RequestConfirmationPage({ confirmation }) {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const containerRef = useRef()
  const goBackSteps = searchParams.get("goBackSteps")
  const caseNumberParam = searchParams.get("case_number")
  const lastNameParam = searchParams.get("lastname")
  const generalContext = useAppSelectGeneralInfo()

  const { passengers, caseNumber, status, requestCode, language } =
    generalContext

  const { isLoading: isRefundLoading } = useStoreRefund({
    skip: caseNumber,
    requestCode: caseNumberParam,
    lastname: lastNameParam,
    reload: false,
  })

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const code = requestCode || caseNumberParam
    if (code) {
      identifyUser(code, lastNameParam)
    }
  }, [requestCode, caseNumberParam, lastNameParam])

  if (isRefundLoading) {
    return (
      <Interstitial
        loadingText={formatMessage({ id: "intersitial.loading" })}
      />
    )
  }

  const convertToBoldText = (text) => (
    <b
      style={{
        fontFamily: "SuisseIntl",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0px",
        textAlign: "left",
      }}
    >
      {text}
    </b>
  )

  const convertToHyperlink = (
    linkUrl,
    linkText,
    withGA = false,
    action = ""
  ) => {
    const hyperlinkProps = {
      style: {
        color: theme.palette.primary.light,
        fontFamily: "SuisseIntl",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textDecorationColor: theme.palette.primary.light,
        textDecoration: "underline",
      },
      linkUrl,
      target: "_blank",
      linkText,
    }
    if (withGA) {
      return <HyperlinkWithGA {...hyperlinkProps} action={action} />
    }
    return <Hyperlink {...hyperlinkProps} />
  }

  return (
    <div ref={containerRef}>
      <Header
        title={formatMessage({ id: "refundConfirmation.header" })}
        goBackSteps={goBackSteps ? Number(goBackSteps) : undefined}
      />
      <PNRHeading />

      <ResponsiveContainer marginTop="56px">
        <CaseStatus
          confirmation={confirmation}
          passengers={passengers}
          refundCase={{ caseNumber, status }}
        />

        <Grid container justifyContent="end">
          <Grid item xs={12} md={4.6} justifyContent="end">
            <Button
              sx={useStyle.button}
              onClick={() => {
                sendGACustomEvent("Request another refund")
                window.location.href = formatMessage({
                  id: "refundConfirmation.buttonURL",
                })
              }}
            >
              {formatMessage({ id: "refundConfirmation.button" })}
            </Button>
          </Grid>
        </Grid>

        <section>
          <Typography
            variant="h3"
            sx={{ marginBottom: "32px", fontSize: "20px", fontWeight: 700 }}
          >
            {formatMessage({ id: "refundConfirmation.title2" })}
          </Typography>
          <Typography>
            <FormattedMessage
              id="refundConfirmation.subtitle2"
              values={{
                cta: convertToHyperlink.bind(null, getLinkToCopa(language)),
              }}
            />
          </Typography>
          <Typography>
            <FormattedMessage id="refundConfirmation.subtitle3" />
          </Typography>

          <ul style={{ margin: 0, paddingLeft: "24px" }}>
            <li>
              <Typography style={{ paddingTop: "8px" }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph1"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
            <li>
              <Typography style={{ paddingTop: "8px" }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph2"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
            <li>
              <Typography style={{ paddingTop: "8px" }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph3"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
            <li>
              <Typography style={{ paddingTop: "8px", marginBottom: "106px" }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph4"
                  values={{
                    b: convertToBoldText,
                    cta: convertToHyperlink.bind(
                      null,
                      constants.copa_url[language],
                      formatMessage({
                        id: "additionalInfoPage.passengerDisclaimerURL",
                      }),
                      /*withGA=*/ true,
                      "Reservation center click"
                    ),
                  }}
                />
              </Typography>
            </li>
          </ul>
        </section>
      </ResponsiveContainer>
    </div>
  )
}

RequestConfirmationPage.propTypes = {
  confirmation: PropTypes.bool,
}

RequestConfirmationPage.defaultProps = {
  confirmation: false,
}
