export const styles = {
  container: {
    border: "1px solid #CCCCCB",
    borderRadius: "16px",
    minHeight: { sm: "420px", md: "524px" },
    padding: { xs: "24px", sm: "40px", md: "56px" },
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    color: "primary.main",
    fontWeight: { xs: 700 },
    marginTop: { xs: "32px", md: "56px" },
    fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
    lineHeight: { xs: "24px", sm: "32px", lg: "40px" },
  },
  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  ctaBtn: {
    height: "40px",
    minWidth: "138px",
    borderRadius: "40px",
    padding: "10px 16px 10px 16px",
    marginTop: "50px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  icon: {
    width: {
      xs: "96px",
      sm: "112px",
      md: "128px",
    },

    height: {
      xs: "96px",
      sm: "112px",
      md: "128px",
    },
  },
}
