import React from "react"
import { useIntl } from "react-intl"
import { Box } from "@mui/material"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import Button from "@bit/vibenitez.copa-components-library.button"
import ResponsiveContainer from "../../components/ResponsiveContainer"
import { HeaderBlue } from "../../components/HeaderBlue"
import { getLinkToCopa, goToCOPA } from "../../utils/functions"
import { useAppSelectGeneralInfo } from "../../hooks/store-hooks"
import useStyles from "./style"

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="196"
      height="196"
      fill="none"
      viewBox="0 0 196 196"
    >
      <circle cx="98" cy="98" r="98" fill="#EFF5FF" />
      <rect
        width="182.483"
        height="66.741"
        x="6.759"
        y="66.741"
        fill="#fff"
        rx="11"
      />
      <path
        fill="#00A3E0"
        d="M133.456 87.675a3.041 3.041 0 0 1 2.598.469l.262.218 11.969 10.942 9.654-2.53c1.799-.464 3.636.399 4.429 1.986l.145.327.115.345c.503 1.881-.546 3.806-2.374 4.485l-.351.113-30.278 7.96a2.926 2.926 0 0 1-3.03-1.06l-.178-.262-3.586-6.082c-.616-.995-.15-2.279.905-2.688l.235-.074.644-.172a2.96 2.96 0 0 1 2.234.323l.281.187 2.625 2.034 7.842-2.059-5.958-10.126c-.872-1.49-.261-3.345 1.22-4.105l.288-.129.309-.102Z"
      />
      <path
        fill="#0032A0"
        d="M54.748 103.7h-2.942v-6.51h-5.451v6.51h-7.138l9.099-17.766h-5.804L33.374 103.7v5.019h12.981v4.667h5.451v-4.667h2.942V103.7Zm12.905 10.235c7.02 0 10.981-5.804 10.981-14.275 0-8.47-3.96-14.275-10.98-14.275-6.981 0-10.982 5.804-10.982 14.275s4 14.275 10.981 14.275Zm0-5.294c-3.686 0-5.569-3.177-5.569-8.98 0-5.805 1.883-9.02 5.57-9.02 3.686 0 5.608 3.215 5.608 9.02 0 5.803-1.922 8.98-5.609 8.98ZM102.2 103.7h-2.942v-6.51h-5.451v6.51h-7.138l9.099-17.766h-5.804L80.826 103.7v5.019h12.981v4.667h5.451v-4.667h2.942V103.7Z"
      />
      <path
        fill="#0032A0"
        fillRule="evenodd"
        d="M27.035 37.172a2.534 2.534 0 1 0 0 5.07h10.982v19.43H29.57a2.534 2.534 0 0 0 0 5.07h147c6.999 0 12.672 5.673 12.672 12.672v41.396c0 6.999-5.673 12.673-12.672 12.673H19.431c-6.999 0-12.672-5.674-12.672-12.673V77.302c0-5.833 4.728-10.56 10.56-10.56a2.534 2.534 0 1 0 0-5.07c-8.632 0-15.63 6.998-15.63 15.63v43.508c0 9.799 7.944 17.742 17.742 17.742h157.138c9.798 0 17.741-7.943 17.741-17.742V79.414c0-9.799-7.943-17.742-17.741-17.742h-22.81v-19.43h10.982a2.535 2.535 0 1 0 0-5.07h-26.189a2.535 2.535 0 1 0 0 5.07h10.138v19.43h-38.862v-21.12a2.535 2.535 0 0 0-5.069 0v21.12H43.086v-19.43h10.138a2.534 2.534 0 1 0 0-5.07h-26.19Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export function PageNotFound() {
  const { formatMessage } = useIntl()
  const styles = useStyles()
  const generalContext = useAppSelectGeneralInfo()
  const url = getLinkToCopa(generalContext.language)

  return (
    <>
      <HeaderBlue
        title={formatMessage({ id: "pageNotFound.button" })}
        urlCopa={url}
      />
      <ResponsiveContainer className={styles.container}>
        <ErrorIcon />
        <Typography variant="h2" className={styles.title} tabIndex="0">
          {formatMessage({ id: "pageNotFound.Title" })}
        </Typography>
        <Box className={styles.subtitle}>
          {formatMessage({ id: "pageNotFound.Subtitle" })}
        </Box>
        <Box className={styles.ctaContainer}>
          <Button
            onClick={() => {
              goToCOPA(generalContext.language)
            }}
          >
            {formatMessage({ id: "pageNotFound.CTA" })}
          </Button>
        </Box>
      </ResponsiveContainer>
    </>
  )
}
