/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Divider, Grid, Box } from "@mui/material"
import { Controller } from "react-hook-form"
import { useIntl } from "react-intl"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import InputRegular from "@bit/vibenitez.copa-components-library.input-regular"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import Button from "@bit/vibenitez.copa-components-library.button"
import theme from "@bit/vibenitez.copa-components-library.theme/index"
import InputAdornment from "@mui/material/InputAdornment"
import CheckIcon from "@mui/icons-material/Check"
import { useNavigate } from "react-router-dom"
import VALIDATIONS from "../../../../utils/validations"
import {
  createErrorsMessages,
  createErrorsMessagesTicketCode,
} from "../../../../utils/errorsMessages"
import { AlertMessage } from "../AlertMessage"
import useStyles from "./styles"
import REGEX from "../../../../utils/regex"
import { useGetRefund } from "../../../../hooks/useAPICall"
import Spinner from "../../../../components/Spinner/Spinner"
import { sendGACustomEvent } from "../../../../utils/functions"

export function Passenger({ passengerNumber, form, index, remove, fields }) {
  const { formatMessage } = useIntl()
  const styles = useStyles()
  const { control, formErrors, watch, clearErrors } = form

  const defaultTitle = `${formatMessage({
    id: "addPassengersPage.paxTitle",
  })} ${index + 1}`

  const [passengerName, setPassengerName] = useState("")

  const [passengerLastName, setPassengerLastName] = useState("")

  const [passengerFullName, setPassengerFullName] = useState()

  const [showTicketError, setShowTicketError] = useState(false)

  const updatePassengerName = (e) => {
    setPassengerName(e.target.value)
  }

  const updatePassengerLastName = (e) => {
    if (passengerName === defaultTitle) {
      setPassengerName("")
      setPassengerLastName(e.target.value)
    } else {
      setPassengerLastName(e.target.value)
    }
  }

  useEffect(() => {
    if (!passengerName && !passengerLastName) {
      setPassengerFullName(defaultTitle)
    } else {
      setPassengerFullName(`${passengerName} ${passengerLastName}`)
    }
  }, [defaultTitle, passengerName, passengerLastName])

  const errorsMessages = createErrorsMessages(formatMessage)

  const errorsMessagesTicketCode = createErrorsMessagesTicketCode(formatMessage)

  const {
    responseData: refundData,
    request,
    error: refundError,
    isLoading,
  } = useGetRefund({
    skip: true,
  })

  const eTicketAlreadyUsed = (eTicket) => {
    if (eTicket.length === 13 && String(eTicket).match(REGEX.E_TICKET)) {
      request({ eTicket })
    }
  }

  const caseAlreadyOpen = () => !showTicketError

  const watchTicket = watch(`${passengerNumber}.eTicket`)

  useEffect(() => {
    eTicketAlreadyUsed(watchTicket)
  }, [watchTicket])

  const ticketInput = useRef(null)

  useEffect(() => {
    setShowTicketError(!!refundData)
    if (!refundData) {
      clearErrors(`${passengerNumber}.eTicket`)
    }
  }, [refundData])

  useEffect(() => {
    if (refundData) {
      sendGACustomEvent("ETKT with case")
    }
  }, [refundData])

  const navigate = useNavigate()

  useEffect(() => {
    const refundNotFound = [404, 400].includes(refundError?.response?.status)

    if (refundError && !refundNotFound) {
      console.error(refundError)
      navigate("/error")
    }
  }, [refundError])

  const showInputIcon = (value) => {
    let icon

    const isValidInput =
      value && !formErrors?.addPassenger?.[index]?.eTicket?.type

    if (isValidInput)
      icon = (
        <CheckIcon
          style={{
            color: theme.palette.grey["400"],
            fontSize: "18px",
          }}
        />
      )

    if (isValidInput && isLoading)
      icon = <Spinner color={theme.palette.grey["500"]} />

    return icon
  }

  return (
    <>
      <Grid container flex alignItems="start" justifyContent="space-between">
        <Typography variant="h3" className={styles.title}>
          {passengerFullName}
        </Typography>
        {index !== 0 && (
          <Button
            aria-label={formatMessage({
              id: "addPassengersPageWCAG.removePaxButton",
            })}
            style={{
              width: "105px",
              height: "24px",
              fontSize: "12px",
              borderRadius: "4px",
              marginTop: "50px",
            }}
            variant="outlineGray"
            onClick={() => remove(index)}
          >
            <CloseIcon
              style={{
                width: "14px",
                marginRight: "8px",
              }}
            />
            {formatMessage({ id: "addPassengersPage.remove" })}
          </Button>
        )}
        <AlertMessage
          show={showTicketError}
          message={formatMessage(
            {
              id: "addPassengersPage.alertError",
            },
            { caseNumber: refundData?.caseNumber }
          )}
          wcagDescription={formatMessage(
            {
              id: "addPassengersPageWCAG.alertError",
            },
            {
              caseNumber: refundData?.caseNumber,
            }
          )}
          actionlink={`/check-status?case_number=${refundData?.caseNumber}`}
          actionText={formatMessage({ id: "addPassengersPage.alertErrorLink" })}
        />
      </Grid>
      <Box className={styles.fieldContainer}>
        <Box>
          <Controller
            control={control}
            name={`${passengerNumber}.givenName`}
            rules={VALIDATIONS.NAME_LASTNAME}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                id={`${passengerNumber}.givenName`}
                label={formatMessage({
                  id: "addPassengersPage.inputName",
                })}
                placeholder={formatMessage({
                  id: "addPassengersPage.placeholderName",
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value &&
                        !formErrors?.addPassenger?.[index]?.givenName?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey["400"],
                              fontSize: "18px",
                            }}
                          />
                        )}
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 30,
                  "aria-label": formatMessage({
                    id: "addPassengersPageWCAG.inputName",
                  }),
                }}
                helperText={
                  formErrors?.addPassenger?.[index]?.givenName &&
                  errorsMessages[
                    formErrors?.addPassenger?.[index]?.givenName?.type
                  ]
                }
                required
                value={value}
                handleChange={onChange}
                onKeyUp={updatePassengerName}
                onBlur={onBlur}
                hasErrors={formErrors?.addPassenger?.[index]?.givenName}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name={`${passengerNumber}.lastName`}
            rules={VALIDATIONS.NAME_LASTNAME}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                id={`${passengerNumber}.lastName`}
                label={formatMessage({
                  id: "addPassengersPage.inputLastname",
                })}
                placeholder={formatMessage({
                  id: "addPassengersPage.placeholderLastname",
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value &&
                        !formErrors?.addPassenger?.[index]?.lastName?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey["400"],
                              fontSize: "18px",
                            }}
                          />
                        )}
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 30,
                  "aria-label": formatMessage({
                    id: "addPassengersPageWCAG.inputLastname",
                  }),
                }}
                helperText={
                  formErrors?.addPassenger?.[index]?.lastName &&
                  errorsMessages[
                    formErrors?.addPassenger?.[index]?.lastName?.type
                  ]
                }
                required
                value={value}
                handleChange={onChange}
                onKeyUp={updatePassengerLastName}
                onBlur={onBlur}
                hasErrors={formErrors?.addPassenger?.[index]?.lastName}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name={`${passengerNumber}.eTicket`}
            rules={{
              validate: {
                "case-already-open": caseAlreadyOpen,
                isValidETicket: (eticket) => REGEX.E_TICKET.test(eticket),
              },
              ...VALIDATIONS.E_TICKET,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                ref={ticketInput}
                id={`${passengerNumber}.eTicket`}
                label={formatMessage({
                  id: "addPassengersPage.inputTicketCode",
                })}
                placeholder={formatMessage({
                  id: "addPassengersPage.placeholderTicketCode",
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showInputIcon(value)}
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 13,
                  "aria-label": formatMessage({
                    id: "addPassengersPageWCAG.inputETicket",
                  }),
                }}
                helperText={
                  formErrors?.addPassenger?.[index]?.eTicket
                    ? errorsMessagesTicketCode[
                        formErrors?.addPassenger?.[index]?.eTicket?.type
                      ]
                    : formatMessage({
                        id: "addPassengersPage.helperTicketCode",
                      })
                }
                required
                value={value}
                handleChange={onChange}
                onBlur={onBlur}
                hasErrors={formErrors?.addPassenger?.[index]?.eTicket}
              />
            )}
          />
        </Box>
      </Box>
      {fields !== index + 1 && <Divider style={{ marginTop: "35px" }} />}
    </>
  )
}

Passenger.propTypes = {
  passengerNumber: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  form: PropTypes.shape({
    control: PropTypes.object.isRequired,
    formErrors: PropTypes.oneOfType(PropTypes.array, PropTypes.object),
    watch: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.number.isRequired,
}
