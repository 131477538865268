const modalStyles = {
  modalWrapper: {
    paddingTop: {
      sm: "16px",
      xs: "1px",
    },
  },
  header: {
    paddingLeft: { lg: "3px", sm: "0px", xs: "6px" },
    lineHeight: {
      lg: "40px !important",
      md: "32px !important",
    },
    fontSize: {
      lg: "32x !important",
      md: "28px !important",
    },
    color: "primary.dark",
  },
  headerDisclaimer: {
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: { lg: "2px", xs: "0px" },
    "& p": {
      fontWeight: "500",
      lineHeight: "20px",
      fontSize: "14px",
    },
    marginTop: {
      lg: "26px",
      sm: "42px",
      xs: "60px",
    },
  },
  contentWrapper: {
    marginTop: {
      sm: "48px",
      xs: "43px",
    },
  },
  requesterInfoWrapper: {
    display: "grid",
    rowGap: { lg: "24px", sm: "22px", xs: "16px" },
    columnGap: { lg: "48px", sm: "24px" },
    gridTemplateColumns: {
      lg: "repeat(3, 25%)",
      sm: "repeat(3, 1fr)",
      xs: "1fr",
    },
    gridTemplateRows: "auto",
    "& div:last-child": {
      gridColumn: { sm: "1/4", xs: "1/2" },
    },
  },
  requesterField: {
    width: "100%",
  },
  requesterFieldTitle: {
    fontSize: "12px",
    lineHeight: "16px ",
    fontWeight: { sm: "500", xs: "700" },
  },
  requesterFieldContent: {
    overflowWrap: "anywhere",
  },
  passengersWrapper: {
    "& td h4": {
      fontFamily: "SuisseIntl, sans-serif",
      lineHeight: "24px !important",
      fontSize: "16px !important",
      fontWeight: 500,
    },
    "& th": {
      fontWeight: 500,
      fontSize: "12px !important",
      lineHeight: "16px !important",
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
    "& td": {
      lineHeight: "24px !important",
    },
    "& tr:last-child td": {
      borderBottomColor: "transparent",
    },
    marginTop: "40px",
  },
  passengersTable: {
    minWidth: "80%",
    backgroundColor: "white",
    marginTop: "8px",
  },
  passengersTableHeader: {
    fontFamily: "Suisse Int'l",
  },
}

export default modalStyles
