import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Grid } from "@mui/material"
import Typography from "@bit/vibenitez.copa-components-library.typography"
import Button from "@bit/vibenitez.copa-components-library.button"
import { Header } from "../../components/Header"
import { HyperlinkWithGA } from "../../components/HyperlinkWithGA"
import constants from "../../utils/constants"
import { goToCOPA } from "../../utils/functions"

import useStyles from "./styles"
import { useAppSelectGeneralInfo } from "../../hooks/store-hooks"
import PNRHeading from "../../components/PNRHeading"

export function PNRErrorAgency() {
  const styles = useStyles()
  const generalContext = useAppSelectGeneralInfo()
  const { language } = generalContext
  const { formatMessage } = useIntl()
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(constants.copa_url[language])
  }, [])

  return (
    <>
      <Header
        title={formatMessage({ id: "additionalInfoPage.header" })}
        goBackSteps={2}
      />
      <PNRHeading />
      <section
        className={styles.container}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        aria-label={formatMessage({ id: "errorAgency.WCAGContainer" })}
      >
        <Typography variant="h2" sx={{ color: "#002880" }}>
          {formatMessage({ id: "errorAgency.title" })}
        </Typography>

        <section style={{ paddingTop: "16px", paddingBottom: "48px" }}>
          <Typography sx={{ fontSize: "16px", color: "#666666" }}>
            {formatMessage({ id: "errorAgency.subTitle" })}
            <HyperlinkWithGA
              style={{ color: "rgb(14, 104, 255)" }}
              linkUrl={url}
              target="_blank"
              linkText={formatMessage({ id: "errorAgency.link" })}
              action="Reservation center click"
            />
            {formatMessage({ id: "errorAgency.continue" })}
          </Typography>
        </section>
        <Grid container flex justifyContent="start">
          <Button
            data-cy="button-PNRErrorAgency"
            aria-label={formatMessage({ id: "errorAgency.WCAGbutton" })}
            variant="outlinePrimaryMain"
            style={{ width: "376px", height: "48px" }}
            onClick={() => {
              goToCOPA(generalContext.language)
            }}
          >
            {formatMessage({ id: "errorAgency.button" })}
          </Button>
        </Grid>
      </section>
    </>
  )
}
